<template>
<div>
    <button v-if="!showCustomizationPanel" class="absolute btn-toggle-editor" @click="showCustomizationPanel = !showCustomizationPanel"><i class="icon icon-pencil"></i></button>
    <div :class="`absolute style-editor ${position}`" v-show="showCustomizationPanel">
        <div class="flex column fill" :style="{opacity: opacity}">
            <div class="editor-header">
                <button @click="ChangePosition"><i class="icon icon-spinner"></i></button>
                <button @click="ChangeOpacity"><i class="icon icon-pencil"></i></button>
                <div class="flex-fill"></div>
                <button @click="showCustomizationPanel = false"><i class="icon icon-x"></i></button>
            </div>
            <div class="flex-fill">
                <textarea v-model="customCSS" class="fill"></textarea>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        value: null
    },
    data() {
        return {
            showCustomizationPanel: false,
            customCSS: null,
            position: 'left',
            opacity: 1,

        }
    },
    mounted() {
        this.customCSS = this.value;
        document.addEventListener('keyup', (e) => {
            if(e.code == 'Escape') this.showCustomizationPanel = !this.showCustomizationPanel;
            else if(e.code == 'AltRight')  this.ChangeOpacity();
            else if(e.code == 'ControlRight') this.ChangePosition();
        });
    },
    methods: {
        ChangePosition() {
            if(this.position == 'left') this.position = 'top';
            else if(this.position == 'top') this.position = 'right';
            else if(this.position == 'right') this.position = 'bottom';
            else if(this.position == 'bottom') this.position = 'left';
        },
        ChangeOpacity() {
            this.opacity = (this.opacity + 0.25)%1;
            if(this.opacity == 0) this.opacity = 1;
        }
    },
    watch: {
        customCSS: function(n, o) {
            this.$emit('input', n);
        }
    }
}
</script>

<style lang="scss">
.style-editor {
    z-index: 10000;
    overflow: hidden;
    position: absolute;
    &.left {
        top: 0;
        height: 100%;
        width: 33%;
    }
    &.top {
        top: 0;
        left: 0;
        height: 33%;
        width: 100%;
    }
    &.right {
        top: 0;
        right: 0;
        height: 100%;
        width: 33%;
    }
    &.bottom {
        bottom: 0;
        left: 0;
        height: 33%;
        width: 100%;
    }
    font-family: 'Courier New', Courier, monospace !important;
    textarea {
        font-family: 'Courier New', Courier, monospace !important;
    }
}
.editor-header {
    background-color: black;
    height: 40px;
    display: flex;
    padding: 5px;
    gap: 5px;

    button {
        background: none;
        border: 2px solid white;
        color: white;
        cursor: pointer;
        font-size: 12px;
    }

}
.btn-toggle-editor {
    left: 0;
    top: 0;
    z-index: 1000;
    background: white;
    border: none;
    width: 40px;
    height: 40px;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.2);
    border-radius: 0px 0% 50% 0px;
    cursor: pointer;

}
</style>