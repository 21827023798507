<template>
<div id="app" :class="`${platform} ${isTablet ? 'tablet' : ''}`">
	<div style="display: none; position: absolute; opacity: 0; pointer-events: none">{{version}}</div>
	<main-view v-if="type == 'ECOMMERCE'"></main-view>
	<virtual-menu-view v-if="type == 'VIRTUAL_MENU'"></virtual-menu-view>
	<franchise-view v-if="type == 'FRANCHISE'"></franchise-view>

	<div v-if="loading" class="flex column justify-center align-center" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; background-color: #f7f8fc; z-index: 10">
        
        <div v-if="loading === 'ERROR'">
            <div style="color: #CCC; font-size: 18px; font-weight: 500; margin: 20px; text-align: center">Oops! Ha ocurrido un error o el negocio no está disponible actualmente</div>
        </div>
        <div v-else>
            <div style="position: relative; width: 100px; height: 100px">
                <img :src="require('./assets/circle-notch.png')" alt="" width="100" class="spin absolute" style="left: 0; top: 0; right: 0; bottom: 0">
                <img :src="require('./assets/influye-logo.png')" alt="" width="100" class="absolute" style="left: 0; top: 0; right: 0; bottom: 0">
            </div>
            
            <a style="color: #CCC; font-size: 12px; font-weight: 500; margin-top: 10px" href="https://influyeapp.cl/" target="_blank">Powered by Influye</a>
        </div>
    </div>

	<customization-editor v-if="customizationMode && customCSS" v-model="customCSS"></customization-editor>
	
    <modal v-model="modalError" :sizeAuto="true" class="modal-error">
        <div style="padding: 20px" v-if="modalError">
            <div class="message" v-if="typeof modalError == 'string'" v-html="modalError"></div>
            <div v-if="Array.isArray(modalError)" class="flex column" style="margin-bottom: -10px">
                <div v-for="option in modalError" :key="typeof option == 'string' ? option : option.text" style="margin-bottom: 10px">
					<div v-if="typeof option == 'string'" class="message">{{option}}</div>
					<div v-else @click="option.function(); modalError = null;" class="btn btn-hover">{{option.text}}</div>
				</div>
            </div>
        </div>
    </modal>

</div>
</template>

<script>

var Axios = require('axios');
let axiosConfig = {
	baseURL: `${process.env.VUE_APP_BACKEND_URL}store/`
};
var url = new URL(window.location.href);
if(url.searchParams.get('is_test')) axiosConfig.headers = { Authorization: `is_test`};
window.axios = Axios.create(axiosConfig)

import CustomizationEditor from './components/common/CustomizationEditor.vue';

export default {
	name: 'App',
	components: {CustomizationEditor},
	data() {
		return {
			platform: 'desktop',
			isTablet: false,
			version: process.env.VERSION,
			type: null,
			loading: true,
			customizationMode: false,
			showCustomizationPanel: false,
			customCSS: '',
			customCSStag: null,
			modalError: null
		}
	},
	mounted() {
		//existen los datos en el html
		if(typeof data !== 'undefined')
		{
			if(data == null) return this.loading = 'ERROR';
			this.type = data.type;
			var connection = this.Util.ConnectionAddresses(data.server);
			var axiosConfig = {
				baseURL: `${connection.backend}store/`,
			};
			if(process.env.NODE_ENV == 'development') axiosConfig.headers = {'Test-Domain': data.domain};
			var url = new URL(window.location.href);
			if(url.searchParams.get('is_test')) axiosConfig.headers = { Authorization: `is_test`};
			window.axios = Axios.create(axiosConfig)
			this.Util.backend = connection.backend;
			this.Util.ws = connection.ws;
			this.loading = false;
		}
		else
		{
			axios.get(`type`).then(({data}) => {
				this.type = data.type;
				this.loading = false;
			}).catch(err => {
				this.loading = 'ERROR';
			});
		}

		var url = new URL(window.location.href);
        if(url.searchParams.get('customize')) this.customizationMode = true;

		this.platform = window.innerWidth < 768 ? 'mobile' : 'desktop';
		this.isTablet = window.innerWidth <= 1048;
		window.addEventListener('resize', (data) => {
			this.platform = window.innerWidth < 768 ? 'mobile' : 'desktop';
			this.isTablet = window.innerWidth <= 1048;
		});
		this.Util.SetCSS = (css) => {
			this.customCSS = css;
		};

		window.addEventListener('message', (event) => {
			if (event.data[0]=== 'pcall'){
				let data = event.data[1];
				this.Util.SetCSS(data);
			}
		}, false);
		
	},
	methods: {
        ShowError(err)
        {
			if(Array.isArray(err) && err.length) return this.modalError = err;
            if(!err) this.modalError = 'Oops! Ha ocurrido un error, inténtalo más tarde';
            if(typeof err === 'string') return this.modalError =  Util.Errors[err] ?  Util.Errors[err] : err;
            else if(err.response.status == 422) this.modalError = 'Los datos ingresados no son válidos';
            else if(err.response.status == 400) this.modalError = Util.Errors[err.response.data.message] ? Util.Errors[err.response.data.message] : err.response.data.message;
            else this.modalError = 'Oops! Ha ocurrido un error, inténtalo más tarde';
        },
	},
	watch: {
		customCSS: function(n, o) {
			if(!this.customCSStag) {
				this.customCSStag = document.createElement('style');
				this.customCSStag.type = 'text/css';
				document.head.appendChild(this.customCSStag);
			}
			this.customCSStag.textContent = n;

			//Check and install fonts
			let matches = n.matchAll(/GoogleFont: (.*)/g);

			if(matches)
			{
				matches.forEach(entry => {
					let fontFamily = entry[1];
					let fontUrl = `https://fonts.googleapis.com/css2?family=${fontFamily.replace(/ /g, '+')}:wght@400;500;700`;
					const linkElement = document.querySelector(`head link[rel="stylesheet"][href="${fontUrl}"]`);
					if(!linkElement)
					{
						var font = document.createElement('link');
						font.rel = 'stylesheet';
						font.href = fontUrl;
						document.head.appendChild(font);
					}
				});
			}

			//this.customCSStag.appendChild(document.createTextNode(n));
		}
	}
}
</script>

<style lang="scss">
@import './fonts/styles.css';
@import '~animate.css';
@import '~leaflet/dist/leaflet.css';
@import './customize.scss';

html, body {
	padding: 0px;
	margin: 0px;
	width: 100%;
	height: 100%;
	//font-family: 'Roboto', sans-serif;
	font-family: sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}
* {
	box-sizing: border-box;
}

#app {
  background-color: #f7f8fc;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  position: relative;
}

.fill {
	width: 100%;
	height: 100%;
}

.flex {
	display: flex;
}
.justify-center {
    justify-content: center;
}
.column {
	flex-direction: column;
}
.ta-center {
	text-align: center;
}
.ta-left {
	text-align: left;
}
.ta-right {
	text-align: right;
}

.flex-fill {
	flex: 1 1 auto;
}
.ofy {
	overflow-y: auto;
}
.relative {
	position: relative;
}
.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}
i.icon {
	padding-top: 2px;
}

/* Loading view */
.loading {
	position: absolute;
	top: 0;
	bottom: 0; 
	left: 0;
	right: 0;
	background-color: white;
}

input {
	background-color: transparent;
}

.txt-hover {
	cursor: pointer;
	transition: all 100ms linear;
}
.txt-hover:hover {
	opacity: 1;
	transform: translateX(5px);
}

.txt-hover-2 {
	cursor: pointer;
	& > div {
		transition: all 100ms linear;
		opacity: 0.6;
	}
}
.txt-hover-2:hover > div{
	opacity: 1;
}

.c2 {
	opacity: 0.6;
}
.s1 {
	font-size: 20px;
	font-weight: 700;
}
.s2 {
	font-size: 18px;
	font-weight: 500;
}
.s3 {
	font-size: 16px;
	font-weight: 400;
}

.mb10 {
	margin-bottom: 10px;
}

.btn {
    font-weight: 500;
    padding: 20px;
    user-select: none;
    text-align: center;
}
.btn i {
    //margin: 0px 10px;
    display: inline-block;
    transform: scale(1.5);
}
i.icon-hover {
	transform: scale(1);
}

.btn-hover {
	transition: all 0.1s linear;
}
.btn-hover:not(.disabled) {
	cursor: pointer;
}
.btn-hover:hover:not(.disabled) {
	transform: scale(1.03);
}
.btn.disabled {
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;
}

.counter {
    position: absolute;
    right: -5px;
    top: -5px;
    padding: 3px;
    border-radius: 2px;
    min-width: 20px;
    text-align: center;
}

.separator {
	margin: 20px 0px;
	border-bottom: 1px solid #AAA; /*c2*/
	opacity: 0.2;

	&.small {
		opacity: 0.05;
		margin: 10px 0px;
	}

}

.btn.input {
	padding: 0px;
	position: relative;
}
.btn.input input {
	width: 100%;
	height: 100%;
	padding: 15px;
}
.btn.input .label {
	position: absolute;
	left: 0;
	right: 0;
	top: 8px;
	margin-left: auto;
	margin-right: auto;
	pointer-events: none;
}
.btn.input .label.left {
	left: 20px;
	top: 14px;
	right: auto;
}
.btn.input input.w-label {
	padding: 25px 0px 15px;
}
.btn.input input.w-label.left {
	padding: 40px 20px 20px;
}
a, a:visited {
	color: inherit;
}
input {
	border: none;
	border-radius: inherit;
	color: inherit;
}
input::placeholder {
	text-transform: none !important;
	font-weight: normal !important;
	color: inherit;
	opacity: 0.6;
}
input:hover {
	outline: none;
	box-shadow: 0 0 0pt 2pt black;
}
.icon-hover {
	cursor: pointer;
	transition: all 100ms linear;
	font-size: 20px;
}
.icon-hover:hover {
	transform: scale(1.2);
}
.cy {
	color: #4c77ff;
}
.cn {
	color: #ff4c79;	
}
.bgy {
	color: #4c77ff;
}
.bgn {
	color: #ff4c79;	
}

.bold {
	font-weight: bold;
}

/*
.panel-select {
    position: absolute;
    background-color: white;
    z-index: 1;
    top: 60px;
    border-radius: 0px 0px 2px 2px;
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.2);
    overflow-y: auto;
    max-height: 300px;
}
.select-option {
    padding: 10px;
    border-bottom: 1px solid #DDD;
    user-select: none;
}
.select-option:last-child {
    border-bottom: none;
}
.select-option:not(.disabled) {
    cursor: pointer;
}
.select-option:not(.disabled):hover {
    background-color: whitesmoke;
}
.select-option.disabled {
    color: #BBB;
	cursor: not-allowed;
}
*/

.not-allowed {
	cursor: not-allowed;
}

/* width 
::-webkit-scrollbar {
  width: 15px;
}


::-webkit-scrollbar-track {
  background: #AAA;
}


::-webkit-scrollbar-thumb {
  background: #2c3e50;
}
*/

@media (max-width: 1048px) {
	.center-content {
		margin-left: 0px !important;
	}
	.left-menu {
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
	}
}

.items-container {
	display: grid;
	column-gap: 20px;
	row-gap: 20px;

    grid-gap: 16px;
    padding: 16px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-auto-columns: 400px;
    margin: -16px;
}
.mobile .items-container {
	grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
}

.item-image {
	width: 150px;
	height: 150px;
	min-width: 150px;
	min-height: 150px;
	background-size: cover;
	background-position: 50% 50%;
}

.cart-item-image {
	width: 50px;
	height: 50px;
	min-width: 50px;
	min-height: 50px;
	background-size: cover;
	border-radius: 5px;
	margin-right: 15px;
}	

@media (max-width: 767px) {
	.s4 {
		font-size: 12px;
	}
}

.swiper-pagination-bullet {
	height: 10px !important;
	width: 10px !important;
}
.swiper-pagination-bullet-active {
	background-color: white !important;
	box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.5);
}

</style>
