<template>
<modal v-model="modalMap" title="Dirección">
    <div v-if="modalMap" class="fill relative flex column" style="overflow-x: hidden">

        <div v-if="!modalMap.map" class="fill flex column" style="padding: 20px">

            <div class="btn input" style="margin-bottom: 10px">
                <div class="label left c2 s3">Dirección (calle y número)</div>
                <input v-model="modalMap.street" type="text" class="s2 w-label left" placeholder="Ejemplo: Cervantes 480">
            </div>

            <div class="btn input" style="margin-bottom: 10px">
                <div class="label left c2 s3">Comuna</div>
                <input v-model="modalMap.neighborhood" type="text" class="s2 w-label left" placeholder="Ejemplo: Concepción">
            </div>
            
            <div class="btn input" style="margin-bottom: 10px">
                <div class="label left c2 s3">Número Dpto y/o referencias</div>
                <input v-model="modalMap.comment" type="text" class="s2 w-label left" placeholder="Ejemplo: Dpto 302 Torre A">
            </div>

            <div class="flex-fill"></div>

            <div :class="`btn btn-hover flex column ${CheckTextAddress(modalMap) ? 'disabled' : 'invert'}`" @click="!CheckTextAddress(modalMap) ? ContinueTextAddress() : null">
                <div>Continuar</div>
                <div class="s3 c2" v-if="CheckTextAddress(modalMap)">{{CheckTextAddress(modalMap).reason}}</div>
            </div>  

        </div>

        <div v-if="modalMap.map" class="fill flex column">
            <map-component @OnMarkerChanged="MapLocationChanged($event, false)" ref="map" v-model="modalMap" style="z-index: 0"></map-component>

            <div @click="$set(modalMap, 'map', false)" class="absolute btn btn-hover" style="left: 20px; top: 20px; z-index: 100" title="Atrás"><i class="icon icon-chevron-right" style="transform: rotate(180deg)"></i></div>

            <div class="absolute flex justify-center" style="pointer-events: none; right: 10px; left: 10px; bottom: 130px; ">
                <div style="font-weight: bold; background-color: white; color: black; padding: 10px 20px; border-radius: 2px; text-align: center; box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3)">Confirma tu dirección exacta en el mapa o arrastra el pin</div>
            </div>

            <div class="absolute" v-if="modalMap.location" style="right: 20px; left: 20px; bottom: 20px">
                <div @click="ConfirmAddress()" :class="`btn btn-hover flex column ${!modalMap.location.enabled || modalMap.loading ? 'disabled' : 'invert'}`">
                    <div>Confirmar Ubicación</div>
                    <div class="s3 c2" v-if="!modalMap.location.enabled">No llegamos a esta dirección</div>
                    <div class="s3 c2" v-if="modalMap.location.enabled">{{Util.ShortAddress(modalMap.location.title)}}</div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import MapComponent from './MapComponent.vue';
export default {
    components: {MapComponent},
    data() {
        return {
            modalMap: null,
            time: null
        }
    },
    methods: {
        Open(currentAddress, local, time) {
            var data = currentAddress ? this.Util.Copy(currentAddress) : {};
            data.latitude = currentAddress ? currentAddress.lat : parseFloat(local.latitude);
            data.longitude = currentAddress ? currentAddress.lon : parseFloat(local.longitude);
            this.modalMap = data;
        },
        CheckTextAddress(address)
        {
            // if(errorForce) return {reason: errorForce};
            if(address.loading) return {reason: ''};
            if(!address.street || address.street.length < 3) return {reason: 'Ingresa una dirección válida'};
            //if(!address.number || !address.number.length) return {reason: 'Ingresa un número de casa/dpto válido'};
			if(!address.neighborhood || address.neighborhood.length < 3) return {reason: 'Ingresa una comuna válida'};

            return false;
        },
        
        ContinueTextAddress()
        {
            this.$set(this.modalMap, 'loading', true);
            var params = `${this.modalMap.street}, ${this.modalMap.neighborhood}`;
            params = params.replace(/\//g, ' ');

            let data = {
                search: params,
                time: this.time
            };

            //if(this.customerName) data.name = this.customerName;
            //if(this.customerPhone) data.phone = this.customerPhone;
            //if(this.customerEmail) data.email = this.customerEmail;

            axios.post(`/addresses`, data).then(res => {
                if(res.data.error && this.modalMap){
                    // this.modalMap = null;
                    //this.OpenModalCustomer();
                    //this.$nextTick(() => {
                        this.Error(res.data.message);
                    //});
                    // return this.Error(res.data.message);
                }

                if(res.data.length)
                {
                    if(this.modalMap)
                    {
                        this.$set(this.modalMap, 'loading', false);
                        this.$set(this.modalMap, 'map', true);
                        setTimeout(() => {
                            this.$set(this.modalMap, 'location', res.data[0]);
                            return this.MapLocationChanged(res.data[0], true);
                        }, 1);
                    }
                }
                else
                {
                    if(this.modalMap)
                    {
                        this.$set(this.modalMap, 'loading', false);
                    }
                }
            }).catch(err => {
                this.$set(this.modalMap, 'loading', false);
            });
        },
        MapLocationChanged(location, skipGeocoding)
        {
            var lat = location.lat ? location.lat : (location.latitude ? location.latitude : null);
            var lon = location.lon ? location.lon : (location.lng ? location.lng : (location.longitude ? location.longitude : null));
            
            this.$refs.map.FlyTo({latitude: lat, longitude: lon});
            if(skipGeocoding) return;

            this.$set(this.modalMap, 'loading', true);

            let data = {
                latitude: lat,
                longitude: lon,
                time: this.time
            };

            //if(this.customerName) data.name = this.customerName;
            //if(this.customerPhone) data.phone = this.customerPhone;
            //if(this.customerEmail) data.email = this.customerEmail;

            axios.post(`/address/coordinates`, data).then( ({data}) => {
                if(this.modalMap){
                    this.$set(this.modalMap, 'loading', false);
                    this.$set(this.modalMap, 'location', data);
                }
                if(data.error) this.Error(data.message);
            }).catch(err => {
                if(this.modalMap)
                {
                    this.$set(this.modalMap, 'loading', false);
                    this.$set(this.modalMap, 'location', null);
                }
            });
        },
        SelectAddress(address)
        {
            if(address.enabled)
            {
                this.modalMap = {location: address};
                
                if(this.mobile) this.searchAddress = '';
            }
        },
        ConfirmAddress()
        {
            if(this.modalMap && !this.modalMap.loading && this.modalMap.location && this.modalMap.location.enabled)
            {
                var address = {
                    street: this.modalMap.street,
                    number: this.modalMap.number,
                    neighborhood: this.modalMap.neighborhood,
                    comment: this.modalMap.comment,
                    address: `${this.modalMap.street}, ${this.modalMap.neighborhood} ${this.modalMap.comment ? '('+this.modalMap.comment+')' : ''}`,
                    lat: this.modalMap.location.lat,
                    lon: this.modalMap.location.lon,
                    enabled: !!this.modalMap.location.enabled,
                    cost: this.modalMap.location.enabled.cost,
                    title: this.modalMap.location.title,
                    timestamp: this.modalMap.location.enabled.timestamp,
                    discount: this.modalMap.location.enabled.discount
                };

                //cambio el flujo de pya
                //if(this.modalMap.deliveryOfferId) address.deliveryOfferId = this.modalMap.deliveryOfferId;
                //if(this.modalMap.estimateId) address.estimateId = this.modalMap.estimateId;
                
                this.modalMap = null;
                this.$emit('OnAddressChanged', address);
            }
        },
    }
}
</script>

<style>

</style>