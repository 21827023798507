<template>
<div></div>
</template>

<script>
export default {
    props: {
        dictItems: null,
        categories: null,
        total: null,
        myOrder: null
    },
    data() {
        return {
            accessToken: false,
            fbclid: null,
            fbclid_time: null,
        }
    },
    methods: {
        SetupGoogleAnalytics(googleAnalyticsID)
        {
            if(!googleAnalyticsID) return;
            var newScript = document.createElement("script");
            newScript.type = "text/javascript";
            newScript.setAttribute("async", "true");
            newScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id="+googleAnalyticsID);
            document.documentElement.firstChild.appendChild(newScript);

            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', googleAnalyticsID);

            window.gtag = gtag;
        },
        SetupFacebookPixel(facebookPixelID, accessToken)
        {
            if(!facebookPixelID) return;
            this.accessToken = accessToken;
            var newScript = document.createElement("script");
            newScript.type = "text/javascript";
            newScript.text = `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${facebookPixelID});
            `;

            if (accessToken) {
                this.Trigger('PageView', null);
            } else {
                newScript.text += `
                    fbq('track', 'PageView');
                `;
            }

            document.documentElement.firstChild.appendChild(newScript);
        },
        GtagItem(id, quantity)
        {
            var item = this.dictItems[id];
            var ret = {
                item_id: item.title,
                item_name: item.title,
                currency: 'CLP',
                item_list_id: item.section,
                item_list_name: item.section,
                price: item.price,
                quantity: quantity ? quantity : 1
            }

            var count = 1;
            if(item.categories)
            {
                for(var key in item.categories)
                {
                    if(this.categories[key])
                    {
                        ret[`item_category${count>1?count:''}`] = this.categories[key].title;
                        count++;
                        if(count > 5) break;
                    }
                }
            }
            return ret;
        },
        GtagItems(list)
        {
            var ret = [];
            list.forEach(item => {
                ret.push(this.GtagItem(item.id, item.quantity));
            });
            return ret;
        },
        FBPixelItem(id, quantity)
        {
            var item = this.dictItems[id];
            var ret = {
                content_ids: item.title,
                content_category: item.categories && item.categories.length ? this.categories[item.categories[0]].title : '',
                content_name: item.title,
                content_type: 'product',
                contents: [{id: item.title, quantity: quantity ? quantity : 1}],
                currency: 'CLP', 
                value: item.price * (item.quantity ? item.quantity : 1)
            }
            return ret;
        },
        //TODO: data agnostic from total, categories, myOrder, dictItems
        Trigger(event, data)
        {
            let eventData = null;

            switch (event) {
                case 'view_item':
                    eventData = {
                        event: 'ViewContent',
                        data: this.FBPixelItem(data.id),
                        user_data: {},
                    };
                    break;

                case 'add_to_cart':
                    eventData = {
                        event: 'AddToCart',
                        data: this.FBPixelItem(data.item.id, data.quantity),
                        user_data: {},
                    };
                    break;

                case 'search':
                    eventData = {
                        event: 'Search',
                        data: {
                            search_string: data
                        },
                        user_data: {},
                    };
                    break;

                case 'add_payment_info':
                    eventData = {
                        event: 'AddPaymentInfo',
                        data: {
                            currency: 'CLP',
                            value: this.total.total,
                            content_ids: data
                        },
                        user_data: {},
                    };
                    break;

                case 'begin_checkout':
                    eventData = {
                        event: 'InitiateCheckout',
                        data: {
                            currency: 'CLP',
                            value: data.total.total
                        },
                        user_data: {},
                    };
                    break;

                case 'purchase':
                    eventData = {
                        event: 'Purchase',
                        data: {
                            currency: 'CLP',
                            value: data.total.total
                        },
                        user_data: {},
                    };
                    break;

                case 'view_cart':
                    eventData = {
                        event: 'Lead',
                        data: {
                            currency: 'CLP',
                            value: this.total.total
                        },
                        user_data: {},
                    };
                    break;

                case 'add_shipping_info':
                    eventData = {
                        event: 'FindLocation',
                        data: {},
                        user_data: {},
                    };
                    break;

                case 'PageView':
                    eventData = {
                        event: 'PageView',
                        data: {},
                        user_data: {},
                    };
                    break;

                default:
                    return;
            }

            if(this.accessToken && eventData){
                if(this.fbclid) {
                    eventData.user_data.fbclid = this.fbclid;
                    eventData.user_data.fbclid_time = this.fbclid_time;
                } 

                axios.post(`/trigger/event/meta`, eventData)
                .then(({data}) => {
                })
            }
            
            //if(!this.gtag) return;
            if(event == 'view_item')
            {
                if(window.gtag)
                {
                    gtag('event', 'view_item', {
                        currency: 'CLP',
                        items: [this.GtagItem(data.id)],
                        value: data.price
                    });
                }
                if(window.fbq)
                {
                    fbq('track', 'ViewContent', this.FBPixelItem(data.id));
                }
            }
            else if(event == 'add_to_cart')
            {
                if(window.gtag)
                {
                    gtag('event', 'add_to_cart', {
                        currency: 'CLP',
                        items: [this.GtagItem(data.item.id, data.quantity)],
                        value: data.quantity*data.total
                    });
                }
                if(window.fbq)
                {
                    fbq('track', 'AddToCart', this.FBPixelItem(data.item.id, data.quantity));
                }
            }
            else if(event == 'remove_from_cart')
            {
                if(window.gtag)
                {
                    var item = this.GtagItem(parseInt(data.key), -data.quantity);
                    gtag('event', 'remove_from_cart', {
                        currency: 'CLP',
                        items: [item],
                        value: item.quantity*item.price
                    });
                }
            }
            else if(event == 'view_item_list')
            {
                if(window.gtag)
                {
                    var items = this.GtagItems(data.items);
                    gtag('event', 'view_item_list', {
                        item_list_id: data.title,
                        item_list_name: data.title,
                        items: items
                    });
                }
            }
            else if(event == 'search')
            {
                if(window.gtag)
                {
                gtag('event', 'search', {
                    search_term: data
                });
                }
                if(window.fbq)
                {
                    fbq('track', 'Search', {
                        search_string: data
                    });
                }
            }
            else if(event == 'add_payment_info')
            {
                if(window.gtag)
                {
                    gtag('event', 'add_payment_info', {
                        currency: 'CLP',
                        value: this.total.total,
                        payment_type: data,
                        items: this.GtagItems(Object.values(this.myOrder))
                    });
                }
                if(window.fbq)
                {
                    fbq('track', 'AddPaymentInfo', {
                        currency: 'CLP',
                        value: this.total.total,
                        content_ids: data
                    });
                }

            }
            else if(event == 'begin_checkout')
            {
                if(window.gtag)
                {
                    gtag('event', 'begin_checkout', {
                        currency: 'CLP',
                        value: this.total.total,
                        items: this.GtagItems(Object.values(this.myOrder))
                    });
                }
                if(window.fbq)
                {
                    fbq('track', 'InitiateCheckout', {
                        currency: 'CLP',
                        value: data.total.total
                    });
                }
            }
            else if(event == 'purchase')
            {
                if(window.gtag)
                {
                    gtag('event', 'purchase', {
                        currency: 'CLP',
                        transaction_id: data.id,
                        value: data.total.total,
                        items: this.GtagItems(Object.values(data.orders)),
                        shipping: data.total.delivery
                    });
                }
                if(window.fbq)
                {
                    fbq('track', 'Purchase', {
                        currency: 'CLP',
                        value: data.total.total
                    });
                }
            }
            else if(event == 'select_promotion')
            {
                if(window.gtag)
                {
                    gtag('event', 'select_promotion', {
                        promotion_name: data,
                    });
                }
            }
            else if(event == 'view_cart')
            {
                if(window.gtag)
                {
                    gtag('event', 'view_cart', {
                        currency: 'CLP',
                        value: this.total.total,
                        items: this.GtagItems(Object.values(this.myOrder))
                    });
                }
                if(window.fbq)
                {
                    fbq('track', 'Lead', {
                        currency: 'CLP',
                        value: this.total.total
                    });
                }
            }
            else if(event == 'add_shipping_info')
            {
                if(window.gtag)
                {
                    gtag('event', 'add_shipping_info', {
                        currency: 'CLP',
                        value: this.total.total,
                        items: this.GtagItems(Object.values(this.myOrder)),
                        shipping_tier: data == 'AUTO' ? 'Lo antes posible' : 'Programado'
                    });
                }
                if(window.fbq)
                {
                    fbq('track', 'FindLocation', {});
                }
            }
        },
    }
}
</script>

<style>

</style>