<template>
<div class="left-menu l-menu">
    <div class="ta-center l-local-logo" v-if="local.logotype">
        <img :src="Util.img(local.logotype, 'logos')" style="width: 100px; margin: 10px 0px" class="logo">
    </div>
    <div class="ta-center s1 l-local-name">{{local.name}}</div>

    <div v-if="activeOrdersCount > 0" class="btn btn-hover flex column l-active-orders" style="margin: 10px 0px 0px 0px" @click="$emit('OnOpenOrder')">
        <div class="l-active-orders-title">Ver {{activeOrdersCount > 1 ? 'Pedidos' : 'Pedido'}}</div>
        <div class="c2 s3 l-active-orders-description">Tienes {{activeOrdersCount > 1 ? `${activeOrdersCount} pedidos activos` : 'un pedido activo'}}</div>
    </div>

    <div class="separator l-separator l-separator-top"></div>
    <div class="flex-fill flex column l-scroll-container" style="overflow-y: auto; overflow-x: hidden; padding-bottom: 20px">
        <div class="s2 ta-left l-title l-title-menu">Menú</div>
        <div :class="`c2 s2 ta-left txt-hover l-entry l-menu-section-title l-menu-section-${section.title}`" style="margin-top: 10px" v-for="section in menu" :key="section.title" v-show="section.items.length" @click="$emit('OnClickSection', section)" :title="`Ir a la sección ${section.title} del menu`">{{section.title}}</div>
        
        <div v-if="itemsWithAnticipation.length" class="l-menu-section-reservation">
            <div class="separator small l-entry l-separator l-separator-menu"></div>
            <div class="c2 s2 flex align-center txt-hover" @click="$emit('OnToggleAnticipationFilter')">
                <i :class="`icon icon-square${anticipationFilter ? '-check cy' : ''}`"></i>
                <span style="margin-left: 5px" :class="anticipationFilter ? 'cy' : ''">Productos con reserva</span>
            </div>
        </div>

        <div class="flex column hide-virtual-menu">
            <div class="s2 ta-left l-title l-title-info" style="margin-top: 20px">Información</div>
            <div class="c2 s2 ta-left txt-hover l-entry " style="margin-top: 10px" @click="Util.Open(`https://maps.google.com/?q=${local.latitude},${local.longitude}`)" :title="`Abrir ubicación de ${local.name} en Google Maps`"><i class="icon icon-map"></i> Ubicación</div>
            <div v-if="showSchedule" class="c2 s2 ta-left txt-hover l-entry" style="margin-top: 10px" @click="$emit('OnClickSchedule')" :title="`Ver horarios disponibles de ${local.name}`"><i class="icon icon-clock"></i> Horarios</div>
            <div v-if="local.phone" class="c2 s2 ta-left txt-hover l-entry" style="margin-top: 10px" @click="OpenContact(local.phone)" :title="`Contactar a ${local.name}`"><i class="icon icon-phone"></i> Contacto</div>
            <div v-if="Util.ig(local.instagram)" class="c2 s2 ta-left txt-hover l-entry" style="margin-top: 10px; text-wrap: nowrap;"  @click="Util.Open('https://instagram.com/'+Util.ig(local.instagram))" :title="`Ver instagram de ${local.name}`"><i class="icon icon-instagram"></i> {{Util.ig(local.instagram)}}</div>
        </div>
    </div>
    <div class="separator l-separator l-separator-bottom"></div>
    <a href="https://influyeapp.cl" target="_blank" class="ta-left s3 txt-hover l-powered-by">Powered by Influye</a>
</div>
</template>

<script>
export default {
    props: {
        local: null,
        menu: null,
        activeOrdersCount: null,
        itemsWithAnticipation: null,
        anticipationFilter: null,
        showSchedule: true,
        ig: null,
    }
}
</script>

<style>

</style>