<template>
<div class="fill" ref="map" id="google-maps">
</div>
</template>

<script>
import Leaflet from 'leaflet';

export default {
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        view: {
            type: Object,
            default: () => ({lat: 0, lon: 0, zoom: 3})
        },
    },
    data() {
        return {
            map: null,
            marker: null,
        }
    },
    mounted() {
        this.map = Leaflet.map(this.$refs.map, {zoomControl: false}).setView([this.view.lat, this.view.lon], this.view.zoom);
        Leaflet.tileLayer(process.env.VUE_APP_TILE_PROVIDER, {
            attribution: process.env.VUE_APP_MAP_ATTRIBUTION,
            tileSize: 512,
            zoomOffset: -1
        }).addTo(this.map);

        var icon = L.icon({
            iconUrl: require('../../assets/marker_user.png'),
            iconSize: [50, 132],
            iconAnchor: [23, 64]
        });

        L.control.zoom({
            position: 'topright'
        }).addTo(this.map);

        this.marker = Leaflet.marker([this.value.latitude, this.value.longitude], {draggable: true, icon: icon}).addTo(this.map);
        //prevenir el menu contextual al mantener presionado en mobile
        this.marker._icon.addEventListener("contextmenu", function(event) {
            event.preventDefault();
        });
        
        this.marker.on('dragend', (e) => {
            this.$emit('OnMarkerChanged', this.marker.getLatLng());
            this.map.setView(this.marker.getLatLng(), this.map.getZoom());
        });

        this.map.on('click', (e) => {
            this.marker.setLatLng({lat: e.latlng.lat, lon: e.latlng.lng});
            this.$emit('OnMarkerChanged', this.marker.getLatLng());
            this.map.setView(this.marker.getLatLng(), this.map.getZoom());
        });
        
        this.map.setView([this.value.latitude, this.value.longitude], 15);
        
        
    },
    methods: {
        GeoLocation() {
            navigator.geolocation.getCurrentPosition((pos) => {
                if(pos && pos.coords)
                {
                    this.FlyTo(pos.coords);
                    this.$emit('OnMarkerChanged', {lat: pos.coords.latitude, lng: pos.coords.longitude});
                }
            });
        },
        FlyTo(point)
        {
            this.marker.setLatLng({lat: point.latitude, lon: point.longitude});
            this.map.flyTo(this.marker.getLatLng());
        }
    },
    beforeDestroy() {
        if(this.map)
        {
            this.map.remove();
            this.map = null;
        }
    },
}
</script>

<style>
.leaflet-right .leaflet-control {
    margin-right: 20px !important;
}
.leaflet-top .leaflet-control {
    margin-top: 20px !important;
}
</style>
