<template>
<transition leave-active-class="animated fadeOut faster">
<div v-if="loading" class="flex column justify-center align-center" style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; background-color: #f7f8fc; z-index: 10">
    
    <div v-if="loading === 'ERROR'">
        <div style="color: #CCC; font-size: 18px; font-weight: 500; margin: 20px; text-align: center">Oops! Ha ocurrido un error o el negocio no está disponible actualmente</div>
    </div>
    <div v-else>
        <div style="position: relative; width: 100px; height: 100px">
            <img :src="require('../../assets/circle-notch.png')" alt="" width="100" class="spin absolute" style="left: 0; top: 0; right: 0; bottom: 0">
            <img :src="require('../../assets/influye-logo.png')" alt="" width="100" class="absolute" style="left: 0; top: 0; right: 0; bottom: 0">
        </div>
        
        <a style="color: #CCC; font-size: 12px; font-weight: 500; margin-top: 10px" href="https://influyeapp.cl/" target="_blank">Powered by Influye</a>
    </div>
</div>
</transition>
</template>

<script>
export default {
    props: {
        loading: false,
    }
}
</script>

<style>

</style>