<template>
<div class="status-container">
    <div class="status-container" v-if="status == 'PENDING'" @click="PlayBell">
        <img :src="require('../../assets/order_pending.png')" alt="" height="225" style="opacity: 0">
        <img id="delivery-bike" :src="require('../../assets/order_pending_guy.png')" class="status-pending-guy">
        <img :src="require('../../assets/order_pending_tray.png')" ref="bell" class="order-pending-tray">
        <img :src="require('../../assets/order_pending_globe.png')" class="order-pending-globe" style="animation-delay: -0.75s">
    </div>
    <div class="status-container" v-if="status == 'WAITING'">
        <img :src="require('../../assets/order_time.png')" alt="" height="225" style="opacity: 0">
        <img :src="require('../../assets/order_bag.png')" class="status-waiting-bag">
        <img :src="require('../../assets/clock_1.png')" class="status-waiting-clock">
        <img :src="require('../../assets/clock_2.png')" class="status-waiting-clock-handle">
        <img :src="require('../../assets/clock_3.png')" class="status-waiting-clock-handle-2">
    </div>
    <div class="status-container" v-if="status == 'WAITING_PAYMENT'">
        <img :src="require('../../assets/order_payment.png')" alt="" height="225" style="opacity: 0">
        <img :src="require('../../assets/order_wallet.png')" class="status-payment-wallet">
        <img :src="require('../../assets/order_bill.png')" class="status-payment-bill">
        <img :src="require('../../assets/order_coin.png')" class="status-payment-coin-1">
        <img :src="require('../../assets/order_coin.png')" class="status-payment-coin-2">
    </div>
    <div class="status-container" v-if="status == 'PREPARING'">
        <img :src="require('../../assets/order_preparing.png')" alt="" height="225" style="opacity: 0">
        <img :src="require('../../assets/status_preparing_body.png')" class="status-preparing-body">
        <img :src="require('../../assets/status_preparing_head.png')" class="status-preparing-head">
        <img :src="require('../../assets/status_preparing_pan.png')" class="status-preparing-pan">
        <img :src="require('../../assets/status_preparing_ingredients_2.png')" class="status-preparing-ingredients-1" style="animation-delay: -0.25s">
        <img :src="require('../../assets/status_preparing_ingredients_1.png')" class="status-preparing-ingredients-1">
    </div>
    <div class="status-container" v-if="status == 'SHIPMENT_PREPARING'">
        <img :src="require('../../assets/shipment_ready.png')" alt="" height="225" style="opacity: 0">
        <img :src="require('../../assets/shipment_ready_boxes.png')" class="status-shipment-boxes">
    </div>
    <div class="status-container" v-if="status == 'DELIVERY'">
        <img :src="require('../../assets/order_delivery.png')" alt="" height="225" style="opacity: 0">
        <img @click="ApplyClass('delivery-bike', 'wheelie', 2000)" id="delivery-bike" :src="require('../../assets/order_delivery_bike.png')" class="status-delivery-bike">
        <img :src="require('../../assets/order_delivery_smoke.png')" class="status-delivery-smoke">
        <img :src="require('../../assets/order_delivery_smoke.png')" class="status-delivery-smoke" style="animation-delay: -0.165s">
        <img :src="require('../../assets/order_delivery_smoke.png')" class="status-delivery-smoke" style="animation-delay: -0.33s">
    </div>
    <div class="status-container" v-if="status == 'SHIPMENT_DELIVERY'">
        <img :src="require('../../assets/shipment_delivery.png')" alt="" height="225" style="opacity: 0">
        <img :src="require('../../assets/truck.png')" class="status-shipment-truck">
        <img :src="require('../../assets/truck_speed.png')" class="status-shipment-speed">
        <img :src="require('../../assets/truck_speed.png')" class="status-shipment-speed" style="animation-delay: -0.17s; bottom: 70%">
        <img :src="require('../../assets/truck_speed.png')" class="status-shipment-speed" style="animation-delay: -0.19s; bottom: 20%">
        <img :src="require('../../assets/truck_speed.png')" class="status-shipment-speed" style="animation-delay: -0.4s; bottom: 30%">
    </div>
    <div class="status-container" v-if="status == 'TAKEAWAY_READY'">
        <img :src="require('../../assets/order_takeaway.png')" alt="" height="225" style="opacity: 0">
        <img :src="require('../../assets/order_restaurant_windows.png')" class="status-takeaway-bg">
        <img :src="require('../../assets/restaurant_silhoutte.png')" class="status-takeaway-silhoutte">
        <img :src="require('../../assets/restaurant_silhoutte.png')" class="status-takeaway-silhoutte" style="animation-direction: reverse; transform: scaleX(-1); animation-delay: -1s, animation-duration: 4s">
        <img :src="require('../../assets/order_restaurant.png')" class="status-takeaway">
        <img :src="require('../../assets/restaurant_sign.png')" class="status-takeaway-sign">
    </div>
    <div class="status-container" v-if="status == 'COMPLETED'">
        <img :src="require('../../assets/order_completed.png')" alt="" height="225" style="opacity: 0">
        <img :src="require('../../assets/order_bag.png')" class="status-completed-bag">
        <img :src="require('../../assets/order_heart.png')" class="status-completed-heart animated pulse infinite">
        <img :src="require(`../../assets/food_${foodItem1}.png`)" :class="`status-completed-food food-item-${foodItem1}`">
        <img :src="require(`../../assets/food_${foodItem2}.png`)" :class="`status-completed-food food-item-${foodItem2}`" style="animation-delay: -3s">
        <img v-for="item in foodItems" :key="item" :src="require(`../../assets/food_${item}.png`)" class="absolute" style="opacity: 0">
    </div>
    <div class="status-container" v-if="status == 'CANCELLED'">
        <img :src="require('../../assets/order_cancelled.png')" alt="" height="225" style="opacity: 0">
        <img :src="require('../../assets/order_bag.png')" class="status-cancelled-bag">
        <img :src="require('../../assets/order_x.png')" class="status-cancelled-x">
    </div>
    <div class="status-container" v-if="status == 'SCHEDULED'">
        <img :src="require('../../assets/order_scheduled.png')" alt="" height="225" style="opacity: 0">
        <img :src="require('../../assets/order_calendar_1.png')" class="status-scheduled-calendar">
        <img :src="require('../../assets/order_calendar_2.png')" class="status-scheduled-day animated fadeIn infinite">
        <img :src="require('../../assets/order_calendar_3.png')" class="status-scheduled-clock">
    </div>
</div>
</template>

<script>
export default {
    props: {
        status: {type: String, default: ''}
    },
    data() {
        return {
            audioBell: null,
            foodItems: ['burger', 'coffe', 'drink', 'fries', 'hotdog', 'icecream', 'pizza', 'sushi', 'taco', 'thai'],
            foodItem1: 'burger',
            foodItem2: 'drink',
            timerFoodItem: null,
        }
    },
    mounted() {
        this.audioBell = new Audio(require('../../assets/ping.mp4'));
        this.foodItem1 = this.foodItems[Math.floor(Math.random() * this.foodItems.length)];
        this.foodItem2 = this.foodItems[Math.floor(Math.random() * this.foodItems.length)];
        this.timerFoodItem = setInterval(() => {
            this.foodItem1 = this.foodItems[Math.floor(Math.random() * this.foodItems.length)];
            this.foodItem2 = this.foodItems[Math.floor(Math.random() * this.foodItems.length)];
        }, 3000);
    },
    methods: {
        PlayBell() 
        {
            this.audioBell.currentTime = 0;
            this.audioBell.play();
            this.$refs.bell.classList.remove('order-bell-shake');
            setTimeout(() => {
                this.$refs.bell.classList.add('order-bell-shake');
            }, 10);
        },
        ApplyClass(id, addClass, time)
        {
            let el = document.getElementById('delivery-bike');
            if(!el.classList.contains(addClass)) {
                el.classList.add(addClass);
                setTimeout(() => {
                    el.classList.remove(addClass);
                }, time);
            }
            
        }
    },
    beforeDestroy() {
        if(this.timerFoodItem) clearInterval(this.timerFoodItem);
    }
}
</script>

<style>
/* DELIVERY */
.status-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.status-delivery-bike {
    position: absolute;
    height: 100%;
    animation: anim-bike 0.15s linear 0s infinite;
}
@keyframes anim-bike {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(0px);
    }
}

.status-delivery-smoke {
    position: absolute;
    height: 50%;
    left: 0%;
    top: 60%;
    animation: anim-smoke 0.5s linear 0s infinite;
}
@keyframes anim-smoke {
    0% {
        opacity: 0;
        transform: scale(1) rotate(0deg);
    }
    20% {
        opacity: 1;
    }
    100% {
        transform: scale(0) rotate(-180deg);
        left: -100px;
    }
}
.wheelie {
    animation: anim-wheelie 2s ease-in-out 0s;
}
@keyframes anim-wheelie {
    0% {
        transform-origin: 20% 90%;
        transform: rotate(0deg) translate(0px);
    }
    40% {
        transform-origin: 20% 90%;
        transform: rotate(-40deg) translate(20px, 20px);
    }
    60% {
        transform-origin: 20% 90%;
        transform: rotate(-40deg) translate(20px, 20px);
    }
    100% {
        transform: rotate(0deg) translate(0px);
        transform-origin: 20% 90%;
    }
}

/* PENDING*/
.status-pending-guy {
    position: absolute;
    height: 90%;
    bottom: 0%;
    animation: anim-guy-waiting 2s linear 0s infinite;
}
@keyframes anim-guy-waiting {
    0% {
        transform-origin: 50% 100%;
        transform: scaleY(1);
    }
    50% {
        transform-origin: 50% 100%;
        transform: scaleY(1.01);
    }
    100% {
        transform-origin: 50% 100%;
        transform: scaleY(1);
    }
}
.order-pending-tray {
    position: absolute;
    height: 45%;
    top: 0%;
    left: 45%;
    animation: anim-globe 1.5s linear 0s infinite;
}
@keyframes anim-globe {
    0% {
        transform: scale(1, 1.2);
    }
    50% {
        transform: scale(1.2, 1);
    }
    100% {
        transform: scale(1, 1.2);
    }
}
.order-pending-globe {
    position: absolute;
    height: 25%;
    top: 8%;
    left: 35%;
    animation: anim-globe 1.5s linear 0s infinite;
}
.order-bell-shake {
    animation: anim-bell-shake 0.4s ease-in-out 0s;
}
@keyframes anim-bell-shake {
    0% {
        transform: scale(1, 1) rotate(0deg);
    }
    20% {
        transform: scale(1.3, 0.7) rotate(-10deg);
    }
    40% {
        transform: scale(0.8, 1.2) rotate(10deg);
    }
    60% {
        transform: scale(1.2, 0.8) rotate(-10deg);
    }
    80% {
        transform: scale(0.8, 1.2) rotate(10deg);
    }
    100% {
        transform: scale(1, 1) rotate(0deg);
    }
}

/* PREPARING */
.status-preparing-body {
    position: absolute;
    height: 65%;
    bottom: 0%;
}
.status-preparing-head {
    position: absolute;
    height: 50%;
    bottom: 50%;
    left: 29.5%;
    animation: anim-preparing-head 2s ease-in-out 0s infinite alternate;
}
@keyframes anim-preparing-head {
    0% {
        transform-origin: 50% 100%;
        transform: rotate(-3deg);
    }
    100% {
        transform-origin: 50% 100%;
        transform: rotate(3deg);
    }
}
.status-preparing-pan {
    position: absolute;
    height: 32%;
    bottom: 28%;
    left: 58%;
    animation: anim-preparing-pan 0.5s ease-in-out 0s infinite alternate;
}
@keyframes anim-preparing-pan {
    0% {
        transform-origin: 0% 90%;
        transform: rotate(-3deg);
    }
    100% {
        transform-origin: 0% 90%;
        transform: rotate(3deg);
    }
}
.status-preparing-ingredients-1 {
    position: absolute;
    height: 25%;
    bottom: 39%;
    left: 87%;
    animation: anim-preparing-ingredients 0.5s ease-in-out 0s infinite alternate;
}
@keyframes anim-preparing-ingredients {
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

/* WAITING */
.status-waiting-bag {
    position: absolute;
    height: 92%;
    bottom: 5%;
    left: 2%;
    animation: anim-bag-shake 1.5s ease-in-out 0s infinite;
}
@keyframes anim-bag-shake {
    0% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(0deg);
    }
    80% {
        transform: rotate(-5deg);
    }
    85% {
        transform: rotate(5deg);
    }
    90% {
        transform: rotate(-5deg);
    }
    95% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.status-waiting-clock {
    position: absolute;
    height: 62%;
    bottom: 3%;
    left: 46%;
}
.status-waiting-clock-handle {
    position: absolute;
    height: 22%;
    bottom: 32%;
    left: 69%;
    animation: anim-clock-handle 10s linear 0s infinite;
}
.status-waiting-clock-handle-2 {
    position: absolute;
    height: 18%;
    bottom: 32%;
    left: 69%;
    animation: anim-clock-handle 60s linear -18s infinite;
}
@keyframes anim-clock-handle {
    0% {
        transform: rotate(0deg);
        transform-origin: 50% 95%;
    }
    100% {
        transform: rotate(360deg);
        transform-origin: 50% 95%;
    }
}

/* WAITING PAYMENT */
.status-payment-wallet {
    position: absolute;
    height: 75%;
    bottom: 9%;
    left: 27%;
    animation: anim-float 3s ease-in-out 0s infinite alternate;
}
.status-payment-bill {
    position: absolute;
    height: 53%;
    bottom: 34%;
    left: 1%;
    animation: anim-float 3s ease-in-out -0.7s infinite alternate;
}
.status-payment-coin-1 {
    position: absolute;
    height: 24%;
    bottom: 7%;
    left: 7%;
    animation: anim-float 3s ease-in-out -1.7s infinite alternate;
}
.status-payment-coin-2 {
    position: absolute;
    height: 24%;
    bottom: 31%;
    left: 80%;
    animation: anim-float 3s ease-in-out -2s infinite alternate;
}
@keyframes anim-float {
    0% {
        transform: translateY(-5px);
    }
    100% {
        transform: translateY(5px);
    }
}

/*SHIPMENT DELIVERY */
.status-shipment-truck {
    position: absolute;
    height: 89%;
    left: -3%;
    animation: anim-bike 0.15s linear 0s infinite;
}
.status-shipment-speed {
    position: absolute;
    height: 9%;
    animation: anim-speed-trail 0.3s linear 0s infinite;
}
@keyframes anim-speed-trail {
    0% {
        opacity: 0;
        transform: translateX(150px);
    }
    15% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateX(-150px);
    }
}

/*SHIPMENT READY */
.status-shipment-boxes {
    position: absolute;
    height: 100%;
    left: 10%;
    animation: anim-trolley 3s ease-in-out 0s infinite;
}
@keyframes anim-trolley {
    0% {
        transform-origin: 50% 98%;
        transform: rotate(0deg);
    }
    50% {
        transform-origin: 50% 98%;
        transform: rotate(-3deg) translateX(10px);
    }
    100% {
        transform-origin: 50% 98%;
        transform: rotate(0deg);
    }
}

/* TAKEAWAY READY */
.status-takeaway, .status-takeaway-bg {
    position: absolute;
    height: 100%;
}
.status-takeaway-sign {
    position: absolute;
    height: 15%;
    left: 71%;
    bottom: 41%;
    animation: anim-sign-swing 1s ease-in-out 0s infinite alternate;
}
@keyframes anim-sign-swing {
    0% {
        transform-origin: 50% 0%;
        transform: rotate(-5deg);
    }
    100% {
        transform-origin: 50% 0%;
        transform: rotate(5deg);
    }
}

.status-takeaway-silhoutte {
    position: absolute;
    height: 44%;
    left: 77%;
    bottom: 9%;
    animation: anim-waitress 10s linear 0s infinite, anim-walk 0.5s ease-in-out 0s infinite;
}
@keyframes anim-waitress {
    0% {
        left: 77%;
        opacity: 0;
    }
    40% {
        left: 77%;
        opacity: 0;
    }
    45% {
        opacity: 0.7;
    }
    95% {
        opacity: 0.7;
    }
    100% {
        left: 9%;
        opacity: 0;
    }
}
@keyframes anim-walk {
    0% {
        bottom: 9%;
    }
    50% {
        bottom: 10%;
    }
    100% {
        bottom: 9%;
    }
}

/* COMPLETED */ 
.status-completed-bag {
    position: absolute;
    height: 100%;
    left: 30%;
    bottom: 0%;
    z-index: 1;
    animation: anim-bag-pop 3s ease-in-out 0s infinite;
}
.status-completed-heart {
    position: absolute;
    height: 31%;
    left: 46%;
    bottom: 31%;
    z-index: 2;
}
@keyframes anim-bag-pop {
    0% {
        transform-origin: 50% 100%;
        transform: scale(1.1, 1.1);
    }
    20% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
    }
    95% {
        transform-origin: 50% 100%;
        transform: scale(1, 1);
    }
    100% {
        transform-origin: 50% 100%;
        transform: scale(1.1, 1.1);
    }
}
.status-completed-food {
    position: absolute;
    height: 40%;
    left: 36%;
    bottom: 26%;
    animation: anim-food-pop 6s ease-in-out 0s infinite;
}
.food-item-coffe, .food-item-drink {
    height: 55%;
}
.food-item-fries, .food-item-icecream, .food-item-pizza {
    height: 60%;
}
.food-item-hotdog {
    height: 35%;
}
.food-item-sushi {
    height: 45%;
}
.food-item-thai {
    height: 70%;
}
@keyframes anim-food-pop {
    0% {
        bottom: 0%;
        left: 36%;
        transform: scale(0) rotate(0deg);
        z-index: 0;
    }
    45% {
        transform: scale(0);
        bottom: 26%;
        left: 36%;
        z-index: 0;
    }
    55% {
        transform: scale(1.1) rotate(30deg);
        bottom: 110%;
        z-index: 0;
    }
    56% {
        z-index: 2;
    }
    75% {
        bottom: 0%;
        left: 6%;
        z-index: 2;
        transform: rotate(0deg);
    }
    95% {
        bottom: 0%;
        left: 6%;
        opacity: 1;
        z-index: 2;
    }
    100% {
        bottom: 0%;
        left: 0%;
        opacity: 0;
        z-index: 2;
    }
}

/* CANCELLED */ 
.status-cancelled-bag {
    position: absolute;
    height: 100%;
}
.status-cancelled-x {
    position: absolute;
    height: 30%;
    left: 35%;
    animation: anim-cancelled-x 1.5s ease-in-out 0s infinite;
}
@keyframes anim-cancelled-x {
    0% {
        transform: scale(3, 3);
        opacity: 0;
    }
    50% {
        transform: scale(1, 1);
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/*SCHEDULED */ 
.status-scheduled-calendar {
    position: absolute;
    height: 100%;
}
.status-scheduled-day {
    position: absolute;
    height: 13.5%;
    left: 24.5%;
    bottom: 35.5%;
}
.status-scheduled-clock {
    position: absolute;
    height: 14.5%;
    left: 66%;
    bottom: 26.5%;
    animation: anim-clock-handle 5s linear 0s infinite;
    transform-origin: 50% 82% !important;
}
</style>