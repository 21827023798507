<template>
<div :class="`absolute trbl0 modal-container`" :style="{height: '100%', width: '100%', 'z-index': 100, 'pointer-events': 'none', overflow: 'hidden'}" @keydown.esc="value && closeable ? Close() : null">

    <div class="fill">
        <transition
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster">
            <div v-if="value" @click="Close()" :class="`absolute fill modal-backdrop`" style="background-color: rgba(0, 0, 0, 0.5); pointer-events: all; z-index: 0">
                <slot name="outside"></slot>
            </div>
        </transition>

        

        <div class="absolute fill flex column justify-center align-center" :style="{'pointer-events': 'none', 'z-index': 1}">

            <transition
            :enter-active-class="`${mobile && !sizeAuto ? 'animated slideInUp' : 'openModal'} faster`"
            :leave-active-class="`${mobile && !sizeAuto ? 'animated slideOutDown' : 'closeModal'} faster`">
            <div v-if="value" :class="`${bodyClass ? bodyClass : (mobile && !sizeAuto ? 'modal unround' : 'modal')} bg`" :style="style" ref="modal">                    
                <div class="modal-header s1 c1 flex column justify-center" v-if="title">
                    <div class="flex align-center">

                        <div class="flex-fill"><vue-clamp :max-lines="1" autoresize>{{title}}</vue-clamp></div>
                        <slot name="header-right"></slot>
                        <i class="icon icon-x icon-hover" style="margin-left: 10px" @click="Close()" title="Cerrar"></i>
                    </div>
                    <slot name="sub-header"></slot>
                </div>
                <div class="modal-body flex column flex-fill" style="background-color: inherit" ref="body">
                    <slot></slot>
                </div>
            </div>
            </transition>
        </div>
        
    </div>
    
</div>
</template>

<script>
import VueClamp from 'vue-clamp';
export default {
    components: {
        VueClamp
    },
    props: {
        value: {
            type: [String, Number, Boolean, Object, Array],
            default: null
        },
        closeable: {
            type: Boolean,
            default: true
        },
        bodyClass: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        sizeAuto: {
            type: Boolean,
            default: false
        },
        desktopWidth: {
            type: String,
            default: null
        },
        desktopHeight: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            fixedPosition: null,
            arrow: {
                right: 'left',
                up: 'down',
                left: 'right',
                down: 'up'
            },
            emit: true,
            previousPopCallback: null,
            id: null
        }
    },
    mounted() {
        this.id = Math.random().toString();
        //document.body.appendChild(this.$el);
    },
    methods: {
        Close(emit) {
            if(this.value && this.closeable)
            {
                this.$emit('OnCloseModal');
                if(emit || !window.history)
                    this.$emit('input', false);
                else
                    window.history.back();
            }
        }
    },
    computed: {
        style() {
            var ret = {height: this.mobile ? '100%' : '80vh', width: this.mobile ? '100%' : '80vw'};
            if(this.desktopWidth && !this.mobile) ret.width = this.desktopWidth;
            if(this.desktopHeight && !this.mobile) ret.height = this.desktopHeight;
            if(this.sizeAuto)
            {
                ret.width = '300px';
                ret.height = 'auto';
            }
            return ret;
        },
        isOpen() {
            return !!this.value;
        }
    },
    watch: {
        isOpen: function(n, o){
            if(n)
            {
                if(window.history)
                {
                    window.history.pushState(this.id, null, '');
                    this.previousPopCallback = window.onpopstate;
                    window.onpopstate = this.Close;
                }
                document.body.appendChild(this.$el);
            }
            else
            {
                if(window.history)
                {
                    if(history.state == this.id)  {
                        window.history.back();
                    }
                    setTimeout(() => {
                        if(this.previousPopCallback) window.onpopstate = this.previousPopCallback;
                        this.previousPopCallback = null;
                    }, 100);
                }
            }
            this.$emit('OnStateChanged', n);
        }
    },
    beforeDestroy() {
        if(this.$el.parentNode)
            this.$el.parentNode.removeChild(this.$el);
    }
}
</script>

<style>
.trbl0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.justify-center {
    justify-content: center;
}
.align-center {
    align-items: center;
}
.align-bottom {
    align-items: flex-end;
}
.modal {
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);
    /*border-radius: 25px;*/
    pointer-events: all; 
    z-index: 21;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.modal-body {
    overflow-y: auto;
    width: 100%;
    height: 100%;
}
.modal-actions {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 10px;
}
.modal-header {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    z-index: 100;
    display: flex;
    padding: 15px;
}

.modal-actions > button {
    margin-left: 10px;
}

@media (max-width: 767px) {
    .modal-actions {
        flex-direction: column;
        align-items: stretch;
        padding: 10px;
        margin-top: 0px;
    }
    .modal-actions button {
        margin: 5px 0px 0px 0px;
    }
    .modal-auto {
        padding: 20px;
    }
    .modal-auto .modal-actions {
        padding: 0px;
    }
}

@media (min-width: 767px) {
    .modal {
        max-width: 800px;
        /*
        position: absolute;
        right: 0;
        top: 0;
        height: 100% !important;
        */
    }
    .modal-actions {
        margin: 0px !important;
    }
}

.openModal {
    animation: anim-open-modal 0.25s ease-out;
}
@keyframes anim-open-modal {
    0% {
        opacity: 0;
        transform: scale(0.6);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.closeModal {
    animation: anim-close-modal 0.1s ease-out;
}
@keyframes anim-close-modal {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.5);
    }
}

</style>
