<template>
<div>

<modal v-model="modalTip" title="Propina" desktopWidth="400px" desktopHeight="500px">
    <div v-if="modalTip" class="fill flex column" style="padding: 20px; overflow-x: hidden">
        <div class="c1 s3" style="margin-bottom: 10px">Selecciona un porcentaje o ingresa un total directamente</div>
        <div class="flex" style="justify-content: space-evenly; margin-bottom: 20px">
            <div class="btn btn-hover" @click="SetTipPercentage(10)">10%</div>
            <div class="btn btn-hover" @click="SetTipPercentage(15)">15%</div>
            <div class="btn btn-hover" @click="SetTipPercentage(20)">20%</div>
            <div class="btn btn-hover" @click="modalTipPercentage={percentage: ''}">Otro</div>
        </div>
        <div class="btn input left relative">
            <div class="label left c2 s3">Total propina</div>
            <input v-model="modalTip.tip" type="number" placeholder="$" class="w-label left s2" title="Propina">
        </div>
        <div class="flex-fill"></div>
        <div v-if="currentTip" style="margin-bottom: 10px" class="btn btn-hover" @click="AddTip(0)" title="Quitar propina">Quitar propina</div>
        <div :class="`btn btn-hover ${modalTip.tip && modalTip.tip > 0 ? 'invert' : 'disabled'}`" @click="AddTip(modalTip.tip)" title="Ingresar propina">Agregar Propina {{modalTip.tip && modalTip.tip > 0 ? Util.Price(modalTip.tip) : ''}}</div>
    </div>
</modal>

<modal v-model="modalTipPercentage" title="Porcentaje" desktopWidth="350px" desktopHeight="300px">
    <div v-if="modalTipPercentage" class="fill flex column" style="padding: 20px; overflow-x: hidden">
        <div class="c1 s3" style="margin-bottom: 10px">Ingresa un porcentaje</div>
        <div class="btn input left relative">
            <div class="label left c2 s3">Porcentaje de propina</div>
            <input v-model="modalTipPercentage.percentage" type="number" placeholder="%" class="w-label left s2" title="Porcentaje">
        </div>
        <div class="flex-fill"></div>
        <div :class="`btn btn-hover ${modalTipPercentage.percentage && modalTipPercentage.percentage >= 10 ? 'invert' : 'disabled'}`" @click="SetTipPercentage(modalTipPercentage.percentage)" title="Ingresar propina">Agregar Propina {{modalTipPercentage.tip && modalTipPercentage.tip >= 10 ? Util.Price(total.order * modalTipPercentage.percentage / 100) : ''}}</div>
    </div>
</modal>

</div>
</template>

<script>
export default {
    props: {
        total: null,
        currentTip: null
    },
    data() {
        return {
            modalTip: null,
            modalTipPercentage: null,
        }
    },
    methods: {
        Open(tip) 
        {
            this.modalTip = {tip: tip};
        },
        AddTip(tip)
        {
            //var min = Math.round(this.total.order * 0.1);
            //if(tip  < min) return this.modalError = `La propina debe ser mayor o igual al 10% del total del pedido (mínimo: ${this.Util.Price(min)})`;
            tip = parseInt(tip);
            this.$emit('OnTip', tip);
            this.modalTip = null;
        },
        SetTipPercentage(p)
        {
            var total = Math.round((p/100)*this.total.order);
            this.$set(this.modalTip, 'tip', total);
            this.modalTipPercentage = null;
        },
    }
}
</script>

<style>

</style>