<template>
<div class="fill" style="overflow: hidden">
    <div class="fill flex main-container" v-if="local">

    <!-- Left Menu -->
    <transition enter-active-class="animated slideInLeft faster" leave-active-class="animated slideOutLeft">
    <div class="left-menu l-menu" v-show="!mobile && !tablet">
        <div class="ta-center l-local-logo" v-if="local.logotype">
            <img :src="Util.img(local.logotype, 'logos')" style="width: 100px; margin: 10px 0px" class="logo">
        </div>
        <div class="ta-center s1 l-local-name">{{local.name}}</div>

        <div v-if="activeOrdersCount > 0" class="btn btn-hover flex column l-active-orders" style="margin: 10px 0px 0px 0px" @click="OpenOrder()">
            <div class="l-active-orders-title">Ver {{activeOrdersCount > 1 ? 'Pedidos' : 'Pedido'}}</div>
            <div class="c2 s3 l-active-orders-description">Tienes {{activeOrdersCount > 1 ? `${activeOrdersCount} pedidos activos` : 'un pedido activo'}}</div>
        </div>

        <div class="separator l-separator l-separator-top"></div>
        <div class="flex-fill flex column l-scroll-container" style="overflow-y: auto; overflow-x: hidden; padding-bottom: 20px">

            <div class="flex column l-menu-sections" style="margin-bottom: 20px">
                <div class="s2 ta-left l-title l-title-menu">Menú</div>
                <div :class="`c2 s2 ta-left txt-hover l-entry l-menu-section-title l-menu-section-${section.title}`" style="margin-top: 10px" v-for="section in filteredMenu" :key="section.title" v-show="section.items.length" @click="$refs.menuItems.GoToSection(section)" :title="`Ir a la sección ${section.title} del menu`">{{section.title}}</div>
                
                <div v-if="itemsWithAnticipation.length" class="l-menu-section-reservation section-menu-filter" style="margin-top: 10px">
                    <div class="c2 s2 flex align-center txt-hover" @click="anticipationFilter = !anticipationFilter">
                        <i :class="`icon icon-square${anticipationFilter ? '-check' : ''}`"></i>
                        <span style="margin-left: 5px" :class="anticipationFilter ? '' : ''">Productos con reserva</span>
                    </div>
                </div>
            </div>

            <div class="flex column hide-virtual-menu l-info">
                <div class="s2 ta-left l-title l-title-info">Información</div>
                <div class="c2 s2 ta-left txt-hover l-entry " style="margin-top: 10px" @click="Util.Open(`https://maps.google.com/?q=${local.latitude},${local.longitude}`)" :title="`Abrir ubicación de ${local.name} en Google Maps`"><i class="icon icon-map"></i> Ubicación</div>
                <div v-if="ecommerceType == 'RESTAURANT'" class="c2 s2 ta-left txt-hover l-entry" style="margin-top: 10px" @click="$refs.modalLocalSchedule.Open()" :title="`Ver horarios disponibles de ${local.name}`"><i class="icon icon-clock"></i> Horarios</div>
                <div v-if="local.phone" class="c2 s2 ta-left txt-hover l-entry" style="margin-top: 10px" @click="OpenContact(local.phone)" :title="`Contactar a ${local.name}`"><i class="icon icon-phone"></i> Contacto</div>
                <div v-if="Util.ig(local.instagram)" class="c2 s2 ta-left txt-hover l-entry" style="margin-top: 10px; text-wrap: nowrap;"  @click="Util.Open('https://instagram.com/'+Util.ig(local.instagram))" :title="`Ver instagram de ${local.name}`"><i class="icon icon-instagram"></i> {{Util.ig(local.instagram)}}</div>
            </div>
        </div>
        <div class="separator l-separator l-separator-bottom"></div>
        <a href="https://influyeapp.cl" target="_blank" class="ta-left s3 txt-hover l-powered-by">Powered by Influye</a>
    </div>
    </transition>

    <!-- Center -->
    <div class="center-content ofy bg" ref="panelCenter" id="menu-container">
        <div>

            <!--banner -->
            <div class="relative">
                <div class="flex column relative c-banner" v-show="(mobile || tablet) || (!mobile && !tablet && banner)" :style="{height: mobile ? '150px' : '200px', 'background-image': banner ? `url('${Util.img(banner, 'banners')}` : '', 'background-size': 'cover', 'background-position': 'center center'}">
                    <div :class="`fill flex justify-center align-center ${!banner ? 'left-s bg' : ''}`">
                        <img v-if="!banner" :src="Util.img(local.logotype, 'logos')" style="width: 100px; margin-right: 20px" class="logo c-banner-image">
                        <div class="flex column c-banner-content">
                            <div v-show="mobile && !banner" class="ta-left c1 s1 c-banner-local-name" style="margin-bottom: 5px">{{local.name}}</div>
                        </div>
                    </div>
                </div>
                
                <swiper v-if="carousel && carousel.length" class="swiper c-carousel" :options="{
                    loop: true,
                    grabCursor: true,
                    autoplay: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                /*
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
                */
                }"
                style="cursor: grab;">
                    <swiper-slide v-for="entry in carousel" :key="entry.image" class="c-carousel-slide">
                        <img class="c-carousel-image" :src="Util.img(entry.image, 'banners')" alt="" style="width: 100%">
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                    <!--
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <div class="swiper-button-next" slot="button-next"></div>
                    -->
                </swiper>

                <div v-show="mobile" :class="`flex c1 btn left-s c-banner-actions`">
                    <i v-if="local.address" @click="Util.Open(`https://maps.google.com/?q=${local.latitude},${local.longitude}`)" class="icon icon-map icon-hover" title="Ubicación"></i>
                    <i v-if="ecommerceType == 'RESTAURANT'" @click="$refs.modalLocalSchedule.Open()" class="icon icon-clock icon-hover" title="Horarios"></i>
                    <i v-if="local.phone" @click="OpenContact(local.phone)" class="icon icon-phone icon-hover" title="Contactar"></i>
                    <i v-if="Util.ig(local.instagram)" @click="Util.Open('https://instagram.com/'+Util.ig(local.instagram))" class="icon icon-instagram icon-hover" title="Instagram"></i>
                </div>

            </div>

            <!-- @start Instagram Images 
            <div id="panelInstagram">
                <div class="ig-container">
                    <a v-for="ig in instagramImages" :key="ig.image" target="_blank" :href="ig.url" title="Ir al Instagram">
                        <img :src="ig.image" width="200">
                    </a>
                </div>
            </div>
             @end Instagram Images -->

            <div v-if="activeOrdersCount > 0" v-show="mobile || tablet" style="overflow: hidden" class="c-active-orders">
                <div class="btn btn-hover invert flex column" :style="{margin:'10px'}" @click="OpenOrder()" title="Ver el estado de mi pedido activo">
                    <div class="c-btn-title">Ver {{activeOrdersCount > 1 ? 'Pedidos' : 'Pedido'}}</div>
                    <div class="c2 s3 c-btn-description">Tienes {{activeOrdersCount > 1 ? `${activeOrdersCount} pedidos activos` : 'un pedido activo'}}</div>
                </div>
            </div>

            <div v-show="isSearchInputVisible || isSearchSectionsVisible" class="btn relative flex fc c-search-bar" id="search-bar" :style="{overflow: 'none', padding: '0px', margin: mobile ? '5px' : '10px 10px 0px'}">
                <i v-show="isSearchInputVisible" class="icon icon-search c2 s2 absolute" style="transform: scale(1); margin: 0; left: 12px; top: 12px; font-size: 20px; pointer-events: none"></i>
                <input ref="searchInput" v-model="searchItem" @focus="searchFocused=true" @blur="searchFocused=false" type="text" class="s2 c1" :style="{'flex-shrink': 0, width: '100%', 'max-width': (isSearchSectionsVisible) && (!searchItem && !searchFocused) ? '20px' : '', padding: '15px', 'padding-left': '40px'}" placeholder="Buscar..." title="Buscar items del menú">
                <i v-show="(searchItem || searchFocused) && isSearchInputVisible" class="icon icon-x absolute btn-hover c2" style="right: 10px; font-size: 25px; top: 10px; transform: scale(1);" @click="searchItem = ''"></i>
                <div ref="searchSections" class="flex align-center flex-fill c-menu-section-container" style="flex-wrap: nowrap; overflow: auto;" v-show="(mobile || tablet)" v-if="!searchItem && !searchFocused">
                    <div :class="` s2 txt-hover-2 c-menu-section-entry c-menu-section-entry-${section.title.replace(/ /g, '')}`" style="flex-shrink: 0" v-for="section in filteredMenu" :key="section.title" v-show="section.items.length" @click="$refs.menuItems.GoToSection(section)" :title="`Ir a la sección ${section.title} del menú`"><div>{{section.title}}</div></div>
                    <div v-if="itemsWithAnticipation.length" class="unshrink flex align-center c-menu-section-entry c-menu-section-entry-reservation s2 txt-hover-2 section-menu-filter">
                        <div class="flex align-center" @click="anticipationFilter = !anticipationFilter">
                            <i :class="`icon icon-square${anticipationFilter ? '-check' : ''}`" style="transform: scale(1); font-size: 15px"></i>
                            <span style="margin-left: 8px" :class="anticipationFilter ? '' : ''">Productos con reserva</span>
                        </div>
                    </div>
                </div>
            </div>

            <menu-items ref="menuItems"
            :menu="filteredMenu" 
            :search="searchItem" 
            :analytics="$refs.analytics" 
            :canOpenItems="isOpen || canSchedule"
            :canAddItems="!isDisabled"
            :itemPriceDisplay="itemPriceDisplay"
            :itemImageSize="itemImageSize"
            :behaviour="menuDisplay"
            @ClearSearch="searchItem=''"
            @OnAdd="AddItem($event.item, $event.key)"
            :overwriteOnOpen="CheckIfItemIsUniquePerOrder"></menu-items>

            <div v-if="mobile" class="ta-center" style="padding: 10px 0px 20px 0px">
                <a href="https://influyeapp.cl" target="_blank">Powered by Influye</a>
            </div>

            <div class="mobile-actions c-closed" v-show="mobile" v-if="!isOpen && !canSchedule" @click="$refs.modalLocalSchedule.Open()" style="z-index: 2; overflow: hidden">
                <div class="btn btn-hover c-btn-closed"  style="margin: 10px" title="Ver Horarios">
                    <div class="c1 s2 c-btn-title">Actualmente estamos cerrados</div>
                    <div class="c2 s3 txt-hover c-btn-description">Ver Horarios</div>
                </div>
            </div>

            <transition enter-active-class="animated zoomIn faster" leave-active-class="animated fadeOut faster">
            <div class="mobile-actions c-cart" v-show="mobile" v-if="!isDisabled && total.items && !showRightMenu" style="z-index: 2; overflow: hidden">
                <div class="btn btn-hover invert flex c-btn-cart" style="margin: 0px 10px" ref="btn-continue" @click="showRightMenu=true;$refs.analytics.Trigger('view_cart')" title="Continuar con mi pedido">
                    <div class="flex column flex-fill">
                        <div class="c-btn-title">Continuar con mi Pedido</div>
                        <div class="c2 s3 c-btn-description" style="margin-top: 2px"><span v-if="parseInt(total.items) == parseFloat(total.items)">{{total.items}} {{total.items > 1 ? 'items' : 'item'}} </span>por un total de {{Util.Price(total.order)}}</div>
                    </div>
                    <!-- <i class="icon icon-bag" style="font-size: 25px"></i> -->
                </div>
            </div>
            </transition>

            <transition enter-active-class="animated zoomIn faster" leave-active-class="animated fadeOut faster">
            <div class="mobile-actions c-disabled" v-show="mobile" v-if="isDisabled && !showRightMenu" style="z-index: 2; overflow: hidden">
                <div class="btn btn-hover disabled invert flex c-btn-disabled" style="margin: 0px 10px">
                    <div class="flex column flex-fill">
                        <div class="c-btn-title">Temporalmente no disponible</div>
                        <div class="c2 s3 c-btn-description" style="margin-top: 2px"></div>
                    </div>
                </div>
            </div>
            </transition>

        </div>
    </div>

    <!-- Right Menu -->
    <transition enter-active-class="animated slideInRight faster" leave-active-class="animated slideOutRight faster">
    <div :class="`right-menu bg hide-virtual-menu ${showRightMenu ? 'show-right-menu' : ''}`" ref="panelRight" v-show="!mobile || showRightMenu">

        <div v-show="mobile" class="top-bar bg" style="position: fixed" @click="showRightMenu=false">
            <div class="flex flex-fill" style="align-items: center">
                <div class="flex-fill s1 r-title">Mi Pedido</div>
                <i class="icon icon-x icon-hover" style="margin-right: 0px;" title="Cerrar"></i>
            </div>
        </div>

        <transition enter-active-class="animated fadeIn faster">
        <div class="flex column flex-fill unshrink r-container" v-if="!isDisabled && (isOpen || canSchedule)">

            <div class="btn btn-hover unshrink flex align-bottom r-personal-info" style="margin-bottom: 10px;" title="Editar mis datos" @click="OpenModalCustomer">
                <!--
                <div class="flex">
                    <i v-if="isOpen.open && isOpen.available && total.items" @click="previousUser = null" class="icon icon-pencil" title="Editar datos"></i>
                </div>
                -->
                <div class="flex-fill">
                    <div v-if="previousUser">
                        <div class="s1 ta-left r-name" style="margin-bottom: 2px">{{previousUser.name}}</div>
                        <div class="s3 c2 ta-left r-email">{{previousUser.email}}</div>
                        <div class="s3 c2 ta-left r-phone">{{previousUser.phone}}</div>
                    </div>
                    <div v-else class="s2 c1 ta-left r-title">Ingresar Datos Personales</div>
                </div>
                <i class="icon icon-chevron-down c2" style="margin-right: 0px;"></i>
                <!-- <div @click="previousUser = null" class="icon icon-pencil absolute" style="right: 25px; top: 36px;"></div> -->
            </div>

            <!-- TODO: add space with css-->
            <div class="flex unshrink r-order-options" style="margin-bottom: 10px">
                <div v-if="orderOptions.delivery" :class="type == 'DELIVERY' ? 'btn invert fill r-delivery' : 'btn btn-hover fill r-delivery'" @click="type='DELIVERY'" title="Hacer un pedido a mi domicilio"><i :class="`icon icon-${ecommerceType == 'SHIPMENT' ? 'truck' : 'motorbike'}`" style="margin: 0px 10px 0px 0px"></i> {{ecommerceType == 'SHIPMENT' ? 'Encargo' : 'Delivery'}}</div>
                <div v-if="orderOptions.delivery && orderOptions.takeaway" style="width: 10px; flex-shrink: 0"></div>
                <div v-if="orderOptions.takeaway" :class="type == 'TAKEAWAY' ? 'btn invert fill r-takeaway' : 'btn btn-hover fill r-takeaway'" @click="type='TAKEAWAY'" title="Hacer un pedido para ir a retirar"><i class="icon icon-paper-bag" style="margin: 0px 10px 0px 0px"></i> Retirar</div>
            </div>
            <div v-if="orderOptions.skip_line && openDays['TAKEAWAY'].openNow && mobile" 
            :class="`btn ${type == 'SKIP_LINE'? 'invert': 'btn-hover'} mb10 r-takeaway ${distance.restriction && (distance.value == null || distance.value < 0 || distance.value > 25)? 'semi-disabled' : ''}`" 
            @click="ConfirmLocation()"
            title="Hacer un pedido saltándose la fila">
                <i class="icon icon-shop" style="margin: 0px 10px 0px 0px"></i> 
                Saltarse la fila <br>
                <span class="c2" v-if="distance.restriction && (distance.value == null || distance.value < 0 || distance.value > 25)"> {{distance.msgError || 'Ubicación requerida'}} </span>
            </div>
            
            <div class="btn btn-hover relative unshrink flex align-bottom r-address" v-if="type == 'DELIVERY'" @click="$refs.modalMap.Open(currentAddress, local)" style="margin-bottom: 10px" title="Editar dirección">
                
                <div class="flex-fill">
                    <div class="s2 ta-left r-title" v-if="!currentAddress">Ingresar Dirección</div>
                    <div class="flex column" v-else>
                        <!-- <div class="s2 ta-left">{{currentAddress.address}}</div> -->
                        <div class="s2 ta-left r-title r-current-address">{{Util.ShortAddress(currentAddress.title)}}</div>
                    </div>
                </div>
                <i class="icon icon-chevron-down c2" style="margin-right: 0px"></i>
                
            </div>

            <div :class="`btn ${canSchedule ? 'btn-hover' : ''} relative unshrink flex align-bottom r-date`" @click="canSchedule ? OpenModalSchedule() : null" v-if="(ecommerceType == 'RESTAURANT' || shipmentType !== 'AUTO') && (ecommerceType == 'RESTAURANT' && type!='SKIP_LINE')" title="Editar la hora de entrega de mi pedido">
                <div class="flex column flex-fill">
                    <!--<div v-if="minDate && minDate.anticipation" class="ta-left s3 c2" style="margin-bottom: 2px; font-size: 12px">Fecha mínima {{ minDate.dateStartParse }}</div>-->
                    <div class="s2 ta-left r-title r-asap" v-if="selectedTime == 'AUTO'">Lo antes posible</div>
                    <div v-else>
                        <div class="s2 ta-left r-title r-schedule" v-if="scheduleDay == 'NONE' || scheduleTime == 'NONE'">Programar pedido
                            <div class="s3 c2 ta-left r-description r-schedule-after" style="margin-top: 2px" v-if="minDate && minDate.anticipation">Después de {{ minDate.dateStartParse }}</div>
                        </div>
                        <div v-else>
                            <div class="s3 c2 ta-left r-title r-schedule" style="margin-bottom: 2px">Programado para</div>
                            <div class="s2 ta-left r-description r-schedule-date">{{Util.Date(scheduleDay, 'dddd D [de] MMMM')}} <span class="s3 c2">{{Util.TimeRange(scheduleTime)}}</span></div>
                            <div class="s2 ta-left r-description r-schedule-date" v-if="!ecommerceType == 'SHIPMENT' || shipmentType == 'MANUAL_DATETIME'">entre {{Util.TimeRange(scheduleTime)}} hrs</div>
                        </div>
                    </div>
                </div>
                <i v-if="canSchedule" class="icon icon-chevron-down c2" style="margin-right: 0px"></i>
            </div>

            <div v-show="total.items > 0" class="flex column flex-fill unshrink r-items">
                <div class="separator r-separator r-separator-top"></div>
                
                <div class="s2 c1 r-title" style="margin-bottom: 10px">Mi Pedido</div>
                <div :class="`btn btn-hover itemUpdate flex column relative r-item r-item-${item.title.replace(/ /g, '')}`" style="margin-bottom: 10px" v-for="(item, key) in myFilteredOrder" :key="key" :ref="`order-${key}`"
                @click="EditItem(item, key)" :title="`Editar ${item.title}`">
                    <div class="flex">
                        <div class="cart-item-image r-item-image" :style="{'background-image': `url('${Util.img(item.image, 'products')}')`}"></div>
                        <div class="flex column r-item-info" style="width: 100%">
                            <div class="ta-left s2 r-item-name" style="line-height: 1; margin-bottom: 2px">{{item.title}}</div>
                            <div :class="`ta-left c2 s3 ${modifier.type} r-item-modifier`" v-for="(modifier, index) in Util.ModifiersList(item)" :key="`modifier-${item.id}-${index}`">{{modifier.value}}</div>
                            <div class="separator r-separator r-separator-item"></div>
                        </div>  
                    </div>
                    <div class="flex">
                        <div class="flex flex-fill r-item-options">
                            <!-- <div class="icon icon-trash" @click="AddItem({key: key, quantity: -item.quantity})" title="Quitar de mi pedido"></div>-->
                            <div v-if="!item.float_quantity" @click.stop="AddItem({key: key, quantity: -1})" :title="`Quitar 1 ${item.title}`"><i class="icon icon-minus-o icon-hover"></i></div>
                            <div :class="`${item.float_quantity ? 'ta-left' : 'ta-center'} s1 r-item-quantity`" style="min-width: 50px">{{Util.Number(item.quantity)}} <span class="c2 s3" v-if="typeof item.float_quantity == 'string'">{{item.float_quantity}}</span></div>
                            <div v-if="!item.float_quantity" @click.stop="AddItem({key: key, quantity: 1})" :title="`Agregar 1 ${item.title}`"><i class="icon icon-plus-o icon-hover"></i></div>
                        </div>
                        <div class="s2 ta-right r-item-total">{{Util.Price(item.total*item.quantity)}}</div>
                    </div>
                    <i class="icon icon-x absolute s3 c2 icon-hover" style="margin: 0px; right: 10px; top: 10px;" :title="`Quitar ${item.title} de mi pedido`" @click.stop="AddItem({key: key, quantity: -item.quantity})"></i>
                </div>
                <div class="separator r-separator r-separator-bottom"></div>
                <div class="flex c1 s2 r-subtotal" style="margin-bottom: 4px">
                    <div class="flex-fill r-title">Subtotal</div>
                    <div class="r-value">{{Util.Price(total.order)}}</div>
                </div>
                <div class="flex c1 s2 r-round" v-show="total.round" style="margin-bottom: 4px">
                    <div class="flex-fill c1 s2 r-title">Ley del Redondeo</div>
                    <div class="r-value">{{Util.Price(total.round)}}</div>
                </div>
                <div class="flex c1 s2 r-delivery-fee" v-show="type == 'DELIVERY'" style="margin-bottom: 4px">
                    <div class="flex-fill r-title">Reparto</div>
                    <div class="r-value">{{Util.Price(total.delivery - total.deliveryDiscount)}}</div>
                </div>
                <!--
                <div v-if="type == 'DELIVERY' && total.deliveryDiscount" class="flex s3 c2 r-delivery-discount" style="font-size: 12px; margin-bottom: 10px">
                    <div class="flex-fill">Se aplica un descuento al costo de reparto de {{Util.Price(total.deliveryDiscount)}}</div>
                </div>
                -->
                <div class="flex c1 s2 align-center r-discount" style="margin-bottom: 4px">
                    <div class="flex-fill r-title">Descuento</div> 
                    <div v-show="coupon && total.couponUsed" class="r-value">- {{Util.Price(total.discount - total.deliveryDiscount)}}</div>
                    <div v-show="coupon && !total.couponUsed" class="c2 r-description">No aplica</div>
                    <div v-show="!coupon" class="btn btn-hover invert secondary tag r-btn-coupon" @click="OpenModalCoupon()" title="Ingresar un código de descuento">Aplicar código</div>
                </div>

                <div v-if="coupon" class="flex column r-coupon" style="margin-bottom: 10px">
                    <div class="flex">
                        <div class="s3 c2 ta-left flex-fill r-description" style="line-height: 1; font-size: 12px">{{coupon.info}}</div>
                        <i class="icon icon-x s3 c2 icon-hover" title="Quitar descuento" @click="coupon=null"></i>
                    </div>
                    <div class="btn btn-hover invert secondary tag r-btn-coupon-info" style="margin-top: 4px" v-if="coupon.itemInfo" @click="$refs.modalCoupon.OpenDetails(coupon, simplifiedData)">Ver productos</div>
                </div>
                <div v-if="tipsEnabled" class="flex c1 s2 align-center r-tip" style="margin-bottom: 4px">
                    <div class="flex-fill r-title">Propina</div> 
                    <div v-show="total.tip" class="bold r-value">{{Util.Price(total.tip)}}</div>
                    <div v-show="!total.tip" class="btn btn-hover invert secondary tag r-btn-tip" @click="OpenModalTip" title="Ingresar una propina">Agregar propina</div>
                </div>
                <div v-if="total.tip && tipsEnabled" class="flex r-tip-applied" style="margin-bottom: 10px">
                    <div class="s3 c2 ta-left flex-fill r-description" style="font-size: 12px">Se agrega una propina de {{Util.Price(total.tip)}} <span v-if="defaultTip">({{defaultTip}}%)</span></div>
                    <i class="icon icon-x s3 c2 icon-hover" title="Quitar propina" @click="currentTip=0;defaultTip=0"></i>
                </div>
                <div class="flex c1 s1 r-total" style="margin: 5px 0px;">
                    <div class="flex-fill r-title">Total</div>
                    <div class="r-value">{{Util.Price(total.total)}}</div>
                </div>

                <div class="btn btn-hover flex column relative r-method" style="margin-top: 20px" @click="OpenModalPayMethod" title="Editar método de pago">
                    <div class="s2 ta-left r-value">{{selectedPaymentMethod == 'NONE' ? 'Método de Pago' : Util.PayMethod(selectedPaymentMethod)}}</div>
                    <div class="s3 c2 ta-left r-cash" style="margin-top: 2px" v-show="selectedPaymentMethod == 'CASH' && cash && cash >= total.total">Pagarás con {{Util.Price(cash)}}</div>
                    <i class="icon icon-chevron-down c2 absolute" style="right: 10px; bottom: 20px"></i>
                </div>
                
                <div class="flex-fill" style="margin-bottom: 30px;"></div>
                <div class="sticky-bottom r-make-order">
                    <div :class="`btn invert btn-hover ${canOrder === true ? '' : (canOrder.redirect ? 'semi-disabled' : 'disabled')} flex column r-btn-order`" @click="canOrder === true ? OpenOrderConfirm() : (canOrder.redirect ? canOrder.redirect() : null)" :title="canOrder === true ? 'Continuar con mi pedido' : 'Completa los datos faltantes'">
                        <div class="s2 r-title">Hacer Pedido</div>
                        <div class="c2 s3 r-description" v-if="canOrder !== true">{{canOrder.reason}}</div>
                    </div>
                </div>
                
            </div>
            
        </div>
        </transition>

        <div class="flex column justify-center align-center flex-fill r-message r-closed" v-if="!isOpen && !canSchedule && !isDisabled" title="Ver horarios">
            <i class="icon icon-clock c2" style="font-size: 80px; opacity: 0.5"></i>
            <div class="c1 s2 r-title">Actualmente estamos cerrados</div>
            <div class="c2 s2 txt-hover r-description" @click="$refs.modalLocalSchedule.Open()">Ver Horarios</div>
        </div>

        <div class="flex column justify-center align-center flex-fill r-message r-unavailable" v-if="isDisabled" title="Ver horarios">
            <i class="icon icon-clock c2 r-title" style="font-size: 80px; opacity: 0.5"></i>
            <div class="c1 s2 r-description">¡Temporalmente no disponible!</div>
        </div>

    </div>
    </transition>

    </div>

    <modal v-model="currentTransaction" :closeable="!loadingAction" :title="local ? local.name : ''" class="modal-current-order">
 
        <div v-show="!mobile && activeOrdersCount > 1" class="btn btn-hover invert absolute btn-other-orders" slot="outside" style="z-index: 1; top: 10px; left: calc(50% - 83px)" @click.stop="OpenOrder()">Ver otros pedidos</div>

        <div slot="header-right" class="mco-contact" @click="OpenContact()" title="Contactar"><span class="s2 c1 txt-hover" v-show="!mobile">Contactar </span><i class="icon icon-phone icon-hover c1" style="display: inline-block"></i></div>

        <div class="flex s2 mco-tabs" slot="sub-header" style="margin-top: 10px" v-show="mobile">
            <div :class="`flex-fill ta-center ${showTransactionDetails ? 'c1' : 'c2'} mco-tab-details`" @click="showTransactionDetails=true" title="Ver los detalles de mi pedido">Detalles</div>
            <div :class="`flex-fill ta-center ${!showTransactionDetails ? 'c1' : 'c2'} mco-tab-status`" @click="showTransactionDetails=false" title="Ver el estado actual de mi pedido">Estado</div>
            <div v-if="activeOrdersCount > 1" :class="`flex-fill ta-center c2 mco-tab-other`" @click="OpenOrder()" title="Ver otros pedidos">Ver otros pedidos</div>
        </div>

        <div class="flex fill modal-current-transaction" v-if="currentTransaction">

            <transition enter-active-class="animated fadeIn faster">
            <div :class="`flex column flex-fill transaction-summary ${mobile ? '' : 'overlay'}`" v-show="!mobile || showTransactionDetails">
                <div class="flex column" style="padding-right: 20px; margin-top: 10px;" v-if="currentTransaction.status !== 'CONFIRMATION'">
                    <div class="c1 s2 bold summary-type" style="text-transform: capitalize">{{currentTransaction.custom_data.shipment_takeaway? 'Encargo retiro' : OrderType[currentTransaction.type]}}</div>
                    <div class="c1 s3 summary-id" v-if="currentTransaction.custom_data.id_ticket"><i class="icon icon-paper-bag"></i> #{{currentTransaction.custom_data.id_ticket}}</div>
                    <div class="c1 s3 summary-customer"><i class="icon icon-user"></i> {{currentTransaction.customer_name}}</div>
                    <div class="c1 s3 summary-local-address" v-if="currentTransaction.type == 'TAKEAWAY'"><i class="icon icon-map"></i> {{currentTransaction.local.address}}</div>
                    <div class="c1 s3 summary-customer-address" v-else><i :class="`icon icon-map`"></i> {{Util.ShortAddress(currentTransaction.address)}}</div>
                    <div class="c1 s3 summary-payment-method"><i class="icon icon-money"></i> {{Util.PayMethod(currentTransaction.payment_method)}}</div>
                    <div class="c1 s3 summary-schedule" v-if="currentTransaction.scheduled_at"><i class="icon icon-clock"></i> {{Util.Date(currentTransaction.scheduled_at, 'dddd D [de] MMMM [a las] HH:mm [hrs]')}}</div>
                    <div class="c1 s3 summary-schedule" v-if="currentTransaction.type == 'SHIPMENT'"><i class="icon icon-clock"></i> {{Util.Date(currentTransaction.messages.shipment_start, 'DD/MM/YYYY')}} <span v-if="currentTransaction.messages.shipment_end">- {{Util.Date(currentTransaction.messages.shipment_end, 'DD/MM/YYYY')}}</span><span v-if="currentTransaction.messages.time"> {{currentTransaction.messages.time}} </span></div>
                    <div class="c1 s3 summary-schedule" v-if="!currentTransaction.scheduled_at && currentTransaction.type == 'TAKEAWAY' && !currentTransaction.custom_data.skip_line && currentTransaction.takeaway_time && currentTransaction.takeaway_time !== 'SCHEDULE' && !currentTransaction.custom_data.shipment_takeaway"><i class="icon icon-clock"></i> {{currentTransaction.takeaway_time == 'AUTO' ? 'Lo antes posible' : `${currentTransaction.takeaway_time} hrs`}}</div>
                    <div class="c1 s3 summary-schedule" v-if="currentTransaction.type == 'TAKEAWAY' && currentTransaction.custom_data.shipment_takeaway && currentTransaction.messages.shipment_start_takeaway"><i class="icon icon-clock"></i> {{Util.Date(currentTransaction.messages.shipment_start_takeaway, 'DD/MM/YYYY')}} <span v-if="currentTransaction.messages.shipment_end_takeaway">- {{Util.Date(currentTransaction.messages.shipment_end_takeaway, 'DD/MM/YYYY')}}</span><span v-if="currentTransaction.messages.time"> {{currentTransaction.messages.time}} </span></div>
                    <div class="separator"></div>
                </div>
                
                <div class="items-list">
                    <div class="order-item unshrink" style="padding: 0px; background-color: transparent" v-for="(item, key) in currentTransaction.orders" :key="key" v-show="!item.type && item.quantity > 0">
                        <div class="relative summary-image" style="margin-right: 15px">
                            <img :src="Util.img(item.image, 'products')" height="50" width="50">
                            <div class="counter s2">{{Util.Number(item.quantity)}}</div>
                        </div>
                        <div class="flex column" style="width: 100%">
                            <div class="flex relative" style="align-items: flex-start">
                                <div class="c1 s3 flex-fill summary-title">{{item.title}} <span class="c2" v-if="typeof item.float_quantity == 'string'">({{item.float_quantity}})</span></div>
                                <div class="flex column" style="min-width: 60px">
                                    <div class="c1 s3 bold ta-right order-item-quantity">{{Util.Price(item.total*item.quantity)}}</div>
                                    <div class="flex absolute order-edit-quantity" style="top: 18px; right: 0px; z-index: 1" v-if="!currentTransaction.id && !loadingAction">
                                        <div class="summary-remove" v-if="!item.float_quantity" @click.stop="AddItem({key: key, quantity: -1})" :title="`Quitar 1 ${item.title}`"><i class="icon icon-minus-o icon-hover"></i></div>
                                        <div class="flex-fill spacing"></div>
                                        <div class="summary-add" v-if="!item.float_quantity" @click.stop="AddItem({key: key, quantity: 1})" :title="`Agregar 1 ${item.title}`" style="margin-left: 5px"><i class="icon icon-plus-o icon-hover"></i></div>
                                        <div v-if="item.float_quantity" class="summary-delete" @click.stop="AddItem({key: key, quantity: -item.quantity})" :title="`Quitar ${item.title}`"><i class="icon icon-minus-o icon-hover"></i></div>
                                    </div>
                                </div>
                                
                            </div>
                            <div :class="`c2 s3 ${modifier.type} summary-modifier`" v-for="(modifier, index) in Util.ModifiersList(item)" :key="`modifier-${item.id}-${index}`">{{modifier.value}}</div>
                            <!-- <div class="c2 s3" v-if="item.comment">"{{item.comment}}"</div> -->
							<!-- <input type="text" class="confirmation-indication" style="margin-left: 0" placeholder="Indicación adicional"> -->
						</div>
                    </div>
                </div>
                <div class="flex column" style="padding-right: 20px; margin-top: 10px;"  v-if="currentTransaction.status == 'CONFIRMATION'">
                    <div class="separator"></div>
                    <div class="flex c1 s3 tc-subtotal" style="margin: 0px">
                        <div class="flex-fill tc-title">Subtotal</div>
                        <div class="tc-value">{{Util.Price(total.order)}}</div>
                    </div>
                    <div class="flex c1 s3 tc-round" style="margin: 0px" v-if="total.round">
                        <div class="flex-fill tc-title">Ley del Redondeo</div>
                        <div class="tc-value">{{Util.Price(total.round)}}</div>
                    </div>
                    <div class="flex c1 s3" style="margin: 0px" v-if="type == 'DELIVERY'">
                        <div class="flex-fill">Reparto</div>
                        <div v-if="total.delivery - total.deliveryDiscount">{{Util.Price(total.delivery - total.deliveryDiscount)}}</div>
                        <div v-else class="c1 bold">GRATIS</div>
                    </div>
                    <div class="flex c1 s3" style="margin: 0px" v-if="total.discount - total.deliveryDiscount">
                        <div class="flex-fill">Descuento</div>
                        <div>-{{Util.Price(total.discount - total.deliveryDiscount)}}</div>
                    </div>
                    <div class="flex c1 s3" style="margin: 0px" v-if="total.tip">
                        <div class="flex-fill">Propina</div>
                        <div>{{Util.Price(total.tip)}}</div>
                    </div>
                    <div class="flex c1 s1" style="margin-top: 5px">
                        <div class="flex-fill">Total</div>
                        <div>{{Util.Price(total.total)}}</div>
                    </div>
                </div>
                <div class="flex column" style="padding-right: 20px; margin-top: 10px;"  v-else>
                    <div class="separator"></div>
                    <div class="flex c1 s3" style="margin: 0px">
                        <div class="flex-fill">Subtotal</div>
                        <div>{{Util.Price(currentTransaction.total.order)}}</div>
                    </div>
                    <div class="flex c1 s3" style="margin: 0px" v-if="currentTransaction.total.round">
                        <div class="flex-fill">Ley del Redondeo</div>
                        <div>{{Util.Price(currentTransaction.total.round)}}</div>
                    </div>
                    <div class="flex c1 s3" style="margin: 0px" v-if="currentTransaction.type == 'DELIVERY'">
                        <div class="flex-fill">Reparto</div>
                        <div v-if="currentTransaction.total.delivery - (currentTransaction.total.deliveryDiscount ? currentTransaction.total.deliveryDiscount : 0)">{{Util.Price(currentTransaction.total.delivery  - (currentTransaction.total.deliveryDiscount ? currentTransaction.total.deliveryDiscount : 0))}}</div>
                        <div v-else class="c1 bold">GRATIS</div>
                    </div>
                    <div class="flex c1 s3" style="margin: 0px" v-if="currentTransaction.total.discount - (currentTransaction.total.deliveryDiscount ? currentTransaction.total.deliveryDiscount : 0)">
                        <div class="flex-fill">Descuento</div>
                        <div>-{{Util.Price(currentTransaction.total.discount - (currentTransaction.total.deliveryDiscount ? currentTransaction.total.deliveryDiscount : 0))}}</div>
                    </div>
                    <div class="flex c1 s3" style="margin: 0px" v-if="currentTransaction.total.tip">
                        <div class="flex-fill">Propina</div>
                        <div>{{Util.Price(currentTransaction.total.tip)}}</div>
                    </div>
                    <div class="flex c1 s1" style="margin-top: 5px">
                        <div class="flex-fill">Total</div>
                        <div>{{Util.Price(currentTransaction.total.total)}}</div>
                    </div>
                </div>
            </div>
            </transition>

            <transition enter-active-class="animated fadeIn faster">
            <div class="fill flex column modal-order" v-show="!mobile || !showTransactionDetails" style="overflow-x: hidden">
				<div v-if="currentTransaction.status == 'CONFIRMATION'" class="flex column flex-fill transaction-confirmation status-confirmation">
                    <div class="transaction-summary-content">
                        <div class="s2 ta-center" style="margin-bottom: 20px">Antes de enviar tu pedido, ¿Esta todo bien?</div>
                        
                        <div class="flex align-center c1 s3 confirmation-customer" style="margin-bottom: 15px">
                            <i class="icon icon-user c2" style="font-size: 25px; margin-right: 15px;"></i> 
                            <div>
                                Pedido a nombre de <span class="bold">{{currentTransaction.customerName}}</span>
                                <span v-if="currentTransaction.customerPhone && currentTransaction.customerEmail">
                                    , te contactaremos a <span class="bold">{{currentTransaction.customerPhone}}</span> 
                                    y te notificaremos a <span class="bold">{{currentTransaction.customerEmail}}</span>
                                    <span v-if="currentTransaction.method == 'ONLINE'"> (y tu recibo de compra)</span>
                                </span>
                            </div>
                        </div>
                        <div class="flex align-center c1 s3 confirmation-address" v-if="ecommerceType == 'RESTAURANT'"  style="margin-bottom: 15px">
                            <i :class="`c2 icon icon-${dictType[currentTransaction.type] == 'TAKEAWAY' ? 'paper-bag' : 'motorbike'}`" style="font-size: 25px; margin-right: 15px;"></i> 
                            <div>
                                <span v-if="dictType[currentTransaction.type] == 'DELIVERY'">Enviaremos tu pedido a <span class="bold">{{Util.ShortAddress(currentTransaction.location.title)}} <span>(#{{currentTransaction.location.number}})</span></span> </span> 
                                <span v-if="dictType[currentTransaction.type] == 'TAKEAWAY'">Pasarás a retirar tu pedido <span v-if="currentTransaction.type == 'TAKEAWAY'" class="bold">a {{local.address}}</span></span> 
                                <span v-if="currentTransaction.time != 'SCHEDULE'">
                                    <span v-if="currentTransaction.time == 'AUTO'"> <span class="bold"> lo antes posible</span></span> 
                                    <span v-if="currentTransaction.time !== 'AUTO'"> aproximadamente a las <span class="bold">{{currentTransaction.time}} hrs</span></span> 
                                </span>
                            </div>
                        </div>
                        <div class="flex align-center c1 s3 confirmation-shipment" v-else style="margin-bottom: 15px">
                            <i :class="`icon icon-${currentTransaction.type == 'TAKEAWAY' ? 'paper-bag' : 'truck'} c2`" style="font-size: 25px; margin-right: 15px;"></i> 
                            <div>
                                <span v-if="currentTransaction.type == 'DELIVERY'">Enviaremos tu pedido a <span class="bold">{{currentTransaction.location.address}}</span> </span>
                                <span v-if="currentTransaction.type == 'TAKEAWAY'">Pasarás a retirar tu pedido a <span class="bold">{{local.address}}</span></span> 
                                <span v-if="schedules && shipmentType == 'AUTO'">
                                    <span v-if="currentTransaction.type == 'DELIVERY'">
                                        <span v-if="schedules.shipment_end"> entre <span class="bold">{{Util.Date(schedules.shipment_start, 'dddd DD [de] MMMM')}}</span> y <span class="bold">{{Util.Date(schedules.shipment_end, 'dddd DD [de] MMMM')}}</span></span>
                                        <span v-else> el <span class="bold">{{Util.Date(schedules.shipment_start, 'dddd DD [de] MMMM')}}</span></span>
                                    </span>
                                    <span v-else>
                                        <span v-if="schedules.shipment_end_takeaway"> entre <span class="bold">{{Util.Date(schedules.shipment_start_takeaway, 'dddd DD [de] MMMM')}}</span> y <span class="bold">{{Util.Date(schedules.shipment_end_takeaway, 'dddd DD [de] MMMM')}}</span></span>
                                        <span v-else> el <span class="bold">{{Util.Date(schedules.shipment_start_takeaway, 'dddd DD [de] MMMM')}}</span></span>
                                    </span>
                                </span>
                                <span v-else>
                                    <span> el <span class="bold">{{Util.Date(currentTransaction.scheduled.day, 'dddd DD [de] MMMM')}}</span><span v-if="shipmentType == 'MANUAL_DATETIME'"> aproximadamente a las <span class="bold">{{currentTransaction.scheduled.time}} hrs</span></span></span>
                                </span>
                            </div>
                        </div>
                        <!-- <input v-if="currentTransaction.type == 'DELIVERY'" :disabled="!!loadingAction" class="confirmation-indication" v-model="addressComment" type="text" placeholder="Puedes escribir una indicación de tu dirección aquí" style="width: 70%"> -->

                        <div class="flex align-center c1 s3 confirmation-schedule" style="margin-bottom: 15px" v-if="currentTransaction.time == 'SCHEDULE' && ecommerceType !== 'SHIPMENT'">
                            <i class="icon icon-clock c2" style="font-size: 25px; margin-right: 15px;"></i>
                            <span>Pedido programado para el <span class="bold">{{Util.Date(currentTransaction.scheduled.day, 'dddd DD [de] MMMM')}}</span> entre las <span class="bold">{{Util.TimeRange(currentTransaction.scheduled.time)}} hrs</span></span>
                        </div>

                        <div v-if="currentTransaction.method == 'ONLINE'" class="flex align-center c1 s3 confirmation-method" style="margin-bottom: 15px">
                            <i class="icon icon-money c2" style="font-size: 25px; margin-right: 15px;"></i> 
                            <div>Pagarás <span class="bold">Online</span> una vez tu pedido sea aceptado</div>
                        </div>
                        <div v-else class="flex align-center c1 s3 confirmation-method" style="margin-bottom: 15px">
                            <i class="icon icon-money c2" style="font-size: 25px; margin-right: 15px;"></i> 
                            <div>Pagarás tu pedido con 
                                <span class="bold" v-if="currentTransaction">{{Util.PayMethod(currentTransaction.method)}}</span> <span class="c2" v-if="currentTransaction.method == 'CASH' && currentTransaction.cash && currentTransaction.cash >= currentTransaction.total.total">({{Util.Price(currentTransaction.cash)}})</span> <span @click="OpenModalEnterCash(cash)" class="btn btn-hover invert secondary tag" v-if="currentTransaction.method == 'CASH' && currentTransaction.cash && currentTransaction.cash >= currentTransaction.total.total">Modificar efectivo</span>
                            </div>
                        </div>
                        <!-- <input v-if="currentTransaction.method == 'CASH'" :disabled="!!loadingAction" class="confirmation-indication" v-model="cash" type="number" placeholder="Puedes especificar con cuanto efectivo pagarás aqui" style="width: 70%">-->

                        <div class="flex align-center c1 s3 confirmation-discount" style="margin-bottom: 15px">
                            <i class="icon icon-discount-o c2" style="font-size: 25px; margin-right: 15px;"></i> 
                            <div v-if="coupon" class="flex column flex-fill confirmation-discount-coupon" @click="coupon=null">
                                <div style="margin-right: 10px">Tienes un descuento de <span class="bold">{{Util.Price(total.discount)}}</span> pesos usando el código <span class="bold">{{coupon.code}}</span> <span class="btn btn-hover invert secondary tag">Quitar código</span></div>
                                <!-- <div class="btn small bold btn-hover" @click="coupon=null">Quitar código</div>-->
                            </div>
                            <div v-else class="flex column flex-fill confirmation-discount-none" @click="OpenModalCoupon()">
                                <div style="margin-right: 10px">No tienes ningún descuento aplicado <span class="btn btn-hover invert secondary tag">Aplicar código</span></div> 
                            </div>
                        </div>

                        <div class="flex align-center c1 s3 confirmation-tip" style="margin-bottom: 15px" v-if="tipsEnabled">
                            <i class="icon icon-tip-o c2" style="font-size: 25px; margin-right: 15px;"></i> 
                            <div v-if="total.tip" @click="currentTip=0;defaultTip=0" class="flex column flex-fill">
                                <div style="margin-right: 10px">Agregas una propina de <span class="bold">{{Util.Price(total.tip)}}</span>  <span class="btn btn-hover invert secondary tag">Quitar propina</span></div>
                                <!--<div class="btn small bold btn-hover" @click="currentTip=0;defaultTip=0">Quitar propina</div>-->
                            </div>
                            <div v-else class="flex column flex-fill" @click="OpenModalTip">
                                <div style="margin-right: 10px">No agregaste propina <span class="btn btn-hover invert secondary tag">Agregar propina</span></div> 
                                <!--<div class="btn small bold btn-hover" @click="modalTip={tip: currentTip}">Agregar propina</div>-->
                            </div>
                        </div>

                        <div class="flex align-center c1 s3 confirmation-total" style="margin-bottom: 15px">
                            <i class="icon icon-shopping-bag c2" style="font-size: 25px; margin-right: 15px;"></i> 
                            <div>Harás una compra <span v-if="total.items == parseInt(total.items)">de <span class="bold">{{total.items}} items</span></span> por un total de <span class="bold">{{Util.Price(total.total)}}</span> pesos</div>
                        </div>

                        <div class="btn input left confirmation-comment">
                            <!-- <div class="label left c2 s3">Comentario</div> -->
                            <input class="left c1 s2" :disabled="!!loadingAction" v-model="orderComment" placeholder="Indicaciones adicionales de tu pedido">
                        </div>
                        
					</div>

                    <transition enter-active-class="animated fadeInUp faster">
                    <div v-if="recommendProducts && recommendProducts.length" class="suggested-items" style="margin-top: 20px">
                        <div class="s2 ta-center" style="margin-bottom: 20px">¿Agregar algo más?</div>
                        <div class="summary-suggested-items">
                            <div :class="`btn fc btn-hover ${loadingAction ? 'disabled' : ''}`" style="padding: 0px;" 
                            v-for="(item, i) in recommendProducts" :key="`item-${i}`" :ref="`item-${item.id}`"
                            @click="$refs.menuItems.OpenModalItem(item)">
                                <div class="flex fill align-center" style="border-radius: inherit; overflow: hidden">
                                    <img :src="Util.img(item.image, 'products')" :height="mobile ? 100 : 75" :width="mobile ? 100 : 75">
                                    
                                    <div :class="`flex-fill s3 c1 ta-left`" style="padding-left: 10px">{{item.title}}</div>
                                    
                                    <div class="flex" style="margin-top: 3px; padding-right: 10px">
                                        <div class="s3 cn old-price" style="margin-right: 5px; text-decoration: line-through" v-if="item.price_reduction">{{Util.Price(item.price_old)}}</div> 
                                        <div class="s3 c1 ta-right bold">{{Util.ItemPrice(item, itemPriceDisplay)}}</div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="s2 ta-center" style="margin-top: 20px">¿Enviar pedido?</div>
                    </div>
                    </transition>

					<div class="flex transaction-actions unshrink" style="margin-top: 20px">
                        <div :class="`btn btn-hover flex-fill ${loadingAction ? 'disabled' : ''}`" @click="loadingAction ? null : currentTransaction=null" title="Serguir armando mi pedido">No aún</div>
						<div :class="`btn btn-hover flex-fill ${loadingAction ? 'disabled' : ''}`" @click="loadingAction ? null : SendOrder()" title="Enviar pedido"><span v-if="!loadingAction">Enviar Pedido por {{Util.Price(total.total)}}</span><i v-if="loadingAction" class="icon icon-spinner spin"></i></div>
					</div>
				</div>

                <!-- ESPERANDO QUE ACEPTEN  -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="currentTransaction.status == 'PENDING'" class="flex column flex-fill transaction-status status-local-accept" style="margin: 20px;">
                    <div class="flex-fill flex column justify-center align-center">
                        <img v-if="customization.image_order_pending" :src="Util.img(customization.image_order_pending, 'banners')" alt="" height="225">
                        <status-interactive v-else status="PENDING"></status-interactive>
                        <!-- <img :src="customization.image_order_pending ? Util.img(customization.image_order_pending, 'banners') : require('../assets/order_pending.png')" alt="" height="225">-->
                        <div v-html="statusMessage"></div>
                    </div>
                    <div class="transaction-actions">
                        <div class="btn btn-hover" @click="modalCancelOrder=true">Cancelar Pedido</div>
                    </div>
                </div>
                </transition>
                
                <!-- ESPERANDO A QUE EL CLIENTE ACEPTE  -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="currentTransaction.status == 'WAITING'" class="flex-fill flex column transaction-status status-customer-accept" style="margin: 20px">
                    <div class="flex-fill flex column justify-center align-center">
                        <img v-if="customization.image_order_confirmation" :src="Util.img(customization.image_order_confirmation, 'banners')" alt="" height="225">
                        <status-interactive v-else status="WAITING"></status-interactive>
                        <!--<img :src="customization.image_order_confirmation ? Util.img(customization.image_order_confirmation, 'banners') : require('../assets/order_time.png')" alt="" height="225">-->
                        <div v-html="statusMessage"></div>
                        <div class="bold" v-if="currentTransaction.custom_data && currentTransaction.custom_data.message">{{ currentTransaction.custom_data.message }}</div>
                    </div>
                    <div class="flex transaction-actions">
                        <!-- <div :class="`btn-order ${loadingAction ? 'disabled' : ''}`" v-show="mobile" @click="showTransactionDetails=true">Ver mi Pedido</div> -->
                        <div :class="`btn btn-hover flex-fill ${loadingAction ? 'disabled' : ''}`" @click="loadingAction ? null : Reject()"><span v-if="loadingAction!='REJECT'" title="No aceptas las condiciones del pedido">No gracias</span><i v-if="loadingAction=='REJECT'" class="icon icon-spinner spin"></i></div>
                        <div :class="`btn btn-hover flex-fill ${loadingAction ? 'disabled' : ''}`" @click="loadingAction ? null : Accept()"><span v-if="loadingAction!='ACCEPT'" title="Aceptas las condiciones del pedido">Acepto</span><i v-if="loadingAction=='ACCEPT'" class="icon icon-spinner spin"></i></div>
                    </div>
                </div>
                </transition>

                <!-- ESPERANDO QUE REALICES PAGO ONLINE -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="currentTransaction.status == 'WAITING_PAYMENT'" class="flex-fill flex column transaction-status status-online-payment" style="margin: 20px">
                    <div class="flex-fill flex column justify-center align-center">
                        <img v-if="customization.image_order_payment" :src="Util.img(customization.image_order_payment, 'banners')" alt="" height="225">
                        <status-interactive v-else status="WAITING_PAYMENT"></status-interactive>
                        <!--<img :src="customization.image_order_payment ? Util.img(customization.image_order_payment, 'banners') : require('../assets/order_payment.png')" alt="" height="225">-->
                        <div v-html="statusMessage"></div>
                    </div>
                    <div class="flex">
                        <div v-if="!currentTransaction.id_flow" :class="`btn btn-hover flex-fill ${loadingAction ? 'disabled' : ''}`" @click="loadingAction ? null : TransbankPayment()">Ir a Pagar</div>
                        <a v-else :href="currentTransaction.payment_url" :class="`btn btn-hover flex-fill`" title="Ir al pago en linea">Ir a pagar</a>
                    </div>
                </div>
                </transition>

                <!-- ESPERANDO QUE REALICES DEPOSITO BANCARIO -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="currentTransaction.status == 'WAITING_BANK_DEPOSIT' && currentTransaction.payment_method == 'BANK_DEPOSIT' && currentTransaction.custom_data" class="flex-fill flex column transaction-status status-bank-deposit" style="margin: 20px">
                    <div class="flex-fill flex column justify-center align-center">
                        <table class="bank-data">
                            <tr class="c1"><td>Nombre</td><td>{{currentTransaction.custom_data.bank_data.name}}</td></tr>
                            <tr class="c1"><td>RUT</td><td>{{currentTransaction.custom_data.bank_data.rut}}</td></tr>
                            <tr class="c1"><td>Banco</td><td>{{currentTransaction.custom_data.bank_data.bank}}</td></tr>
                            <tr class="c1"><td>Tipo Cuenta</td><td>{{currentTransaction.custom_data.bank_data.account}}</td></tr>
                            <tr class="c1"><td>N° Cuenta</td><td>{{currentTransaction.custom_data.bank_data.account_number}}</td></tr>
                            <tr class="c1"><td>Email</td><td>{{currentTransaction.custom_data.bank_data.email}}</td></tr>
                        </table>
                        <div v-html="statusMessage"></div>
                    </div>
                    <div class="flex transaction-actions">
                        <div v-if="!currentTransaction.custom_data.bank_deposit_at" :class="`btn btn-hover flex-fill ${loadingAction ? 'disabled' : ''}`" @click="loadingAction ? null : BankDepositReady()" title="Declarar que ya has realizado la transferencia bancaria">He realizado la transferencia</div>
                        <a :href="`https://${!mobile ? 'web' : 'api'}.whatsapp.com/send?phone=${currentTransaction.custom_data.bank_data.phone}`" target="_blank" :class="`btn btn-hover flex flex-fill column`" title="Abrir Whatsapp"><span>Contactar por Whatsapp</span><span class="small hide-mobile">{{currentTransaction.custom_data.bank_data.phone}}</span></a>
                    </div>
                </div>
                <div v-else-if="currentTransaction.status == 'WAITING_BANK_DEPOSIT' && CheckAdditionalPaymentData" class="flex-fill flex column transaction-status status-bank-deposit" style="margin: 20px">
                    <div class="flex-fill flex column justify-center align-start" style="white-space: break-spaces">
                        <div class="bank-data">
                            {{ CheckAdditionalPaymentData.payment_data }}
                        </div>
                    </div>
                    <div class="flex transaction-actions">
                        <div v-if="!currentTransaction.custom_data.bank_deposit_at" :class="`btn btn-hover flex-fill ${loadingAction ? 'disabled' : ''}`" @click="loadingAction ? null : BankDepositReady()" title="Declarar que ya has realizado el pago">He realizado el pago</div>
                        <a v-if="additionalPaymentData.whatsapp" :href="`https://${!mobile ? 'web' : 'api'}.whatsapp.com/send?phone=${additionalPaymentData.whatsapp}`" target="_blank" :class="`btn btn-hover fill flex flex-fill column`" title="Abrir Whatsapp"><span>Contactar por Whatsapp</span><span class="small hide-mobile">{{additionalPaymentData.whatsapp}}</span></a>
                    </div>
                </div>
                </transition>

                <!-- PREPARANDO -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="currentTransaction.status == 'ACCEPTED' || currentTransaction.status == 'PREPARING'" class="flex-fill flex column transaction-status status-preparing" style="margin: 20px">
                    <div class="flex-fill flex column justify-center align-center" v-if="ecommerceType == 'RESTAURANT'">
                        <img v-if="customization.image_order_preparing" :src="Util.img(customization.image_order_preparing, 'banners')" alt="" height="225">
                        <status-interactive v-else status="PREPARING"></status-interactive>
                        <!--<img :src="customization.image_order_preparing ? Util.img(customization.image_order_preparing, 'banners') : require('../assets/order_preparing.png')" alt="" height="225">-->
                        <div v-html="statusMessage"></div>
                    </div>
                    <div v-else-if="currentTransaction.custom_data.shipment_takeaway" class="flex-fill flex column justify-center align-center" >
                        <img v-if="customization.image_order_shipment_takeaway" :src="Util.img(customization.image_order_shipment_takeaway, 'banners')" alt="" height="225">
                        <status-interactive v-else status="SHIPMENT_PREPARING"></status-interactive>
                        <!--<img :src="customization.image_order_shipment_takeaway ? Util.img(customization.image_order_shipment_takeaway, 'banners') : require('../assets/shipment_ready.png')" alt="" height="225">-->
                        <div v-html="statusMessage"></div>
                    </div>
                    <div class="flex-fill flex column justify-center align-center" v-else>
                        <img v-if="customization.image_order_shipment_ready" :src="Util.img(customization.image_order_shipment_ready, 'banners')" alt="" height="225">
                        <status-interactive v-else status="SHIPMENT_PREPARING"></status-interactive>
                        <!--<img :src="customization.image_order_shipment_ready ? Util.img(customization.image_order_shipment_ready, 'banners') : require('../assets/shipment_ready.png')" alt="" height="225">-->
                        <div v-html="statusMessage"></div>
                    </div>
                    <div class="flex transaction-actions">
                        <div v-if="currentTransaction.custom_data.auto_confirm_bank_deposit" class="btn btn-hover flex-fill" @click="modalBankData=true">Ver Datos Bancarios</div>
                        <div v-if="CheckAdditionalPaymentData && !CheckConfirmPayMethod" class="btn btn-hover flex-fill" @click="modalAdditionalPaymentData=CheckAdditionalPaymentData">Datos {{ CheckAdditionalPaymentData.method }}</div>
                    </div>
                </div>
                </transition>

                <!-- DELIVERY EN TRANSITO -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="currentTransaction.status == 'DELIVERY'" class="flex-fill flex column transaction-status status-delivery" style="margin: 20px">
                    <div class="flex-fill flex column justify-center align-center">
                        <img v-if="ecommerceType == 'RESTAURANT' && customization.image_order_delivery" :src="Util.img(customization.image_order_delivery, 'banners')" alt="" height="225">
                        <status-interactive v-if="ecommerceType == 'RESTAURANT' && !customization.image_order_delivery" status="DELIVERY"></status-interactive>
                        <img v-if="ecommerceType != 'RESTAURANT' && customization.image_order_shipment" :src="Util.img(customization.image_order_shipment, 'banners')" alt="" height="225">
                        <status-interactive v-if="ecommerceType != 'RESTAURANT' && !customization.image_order_shipment" status="SHIPMENT_DELIVERY"></status-interactive>
                        <!--
                        <img v-if="ecommerceType == 'RESTAURANT'" :src="customization.image_order_delivery ? Util.img(customization.image_order_delivery, 'banners') : require('../assets/order_delivery.png')" alt="" height="225">
                        <img v-else :src="customization.image_order_shipment ? Util.img(customization.image_order_shipment, 'banners') : require('../assets/shipment_ready.png')" alt="" height="225">
                        -->
                        <div v-html="statusMessage"></div>
                    </div>
                    <div class="flex transaction-actions">
                        <a v-if="currentTransaction.custom_data.pedidosya_courier" v-show="currentTransaction.custom_data.pedidosya_courier.shareLocationUrl" :href="currentTransaction.custom_data.pedidosya_courier.shareLocationUrl" target="_blank" :class="`btn btn-hover flex-fill flex column`" title="Ve donde vá tu pedido en el mapa"><span>Seguir Pedido</span><span class="small">{{Util.PyaCourierStatus[currentTransaction.custom_data.pedidosya_courier.status]}} - {{Util.Date(currentTransaction.custom_data.pedidosya_courier.lastUpdated, 'HH:mm')}}</span></a>
                        <a v-else :href="`tel:${currentTransaction.local.delivery.phone}`" target="_blank" :class="`btn btn-hover flex-fill flex column`" title="Llamar a repartidor"><span>Contactar Repartidor</span><span class="small hide-mobile">{{currentTransaction.local.delivery.phone}}</span></a>
                    </div>
                </div>
                </transition>

                <!-- RETIRO LISTO -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="currentTransaction.status == 'TAKEAWAY_READY'" class="flex-fill flex column transaction-status status-takeaway-ready" style="margin: 20px">
                    <div class="flex-fill flex column justify-center align-center">
                        <img v-if="customization.image_order_takeaway" :src="Util.img(customization.image_order_takeaway, 'banners')" alt="" height="225">
                        <status-interactive v-else status="TAKEAWAY_READY"></status-interactive>
                        <!-- <img :src="customization.image_order_takeaway ? Util.img(customization.image_order_takeaway, 'banners') : require('../assets/order_takeaway.png')" alt="" height="225">-->
                        <div v-html="statusMessage"></div>
                    </div>
                    <div class="flex transaction-actions" v-if="!currentTransaction.custom_data.skip_line">
                        <div class="flex-fill btn btn-hover" @click="Util.Open(`https://maps.google.com/?q=${local.latitude},${local.longitude}`)" title="Abrir ubicación en Google Maps">Ver Ubicación</div>
                    </div>
                </div>
                </transition>

                <!-- COMPLETADO -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="currentTransaction.status == 'COMPLETED'" class="flex-fill flex column transaction-status status-completed" style="margin: 20px">
                    <div class="flex-fill flex column justify-center align-center">
                        <img v-if="customization.image_order_completed" :src="Util.img(customization.image_order_completed, 'banners')" alt="" height="225">
                        <status-interactive v-else status="COMPLETED"></status-interactive>
                        <!--<img :src="customization.image_order_completed ? Util.img(customization.image_order_completed, 'banners') : require('../assets/order_completed.png')" alt="" height="225">-->
                        <div v-html="statusMessage"></div>
                    </div>
                    <div class="flex transaction-actions">
                        <div class="btn btn-hover flex-fill" @click="Finish(null, currentTransaction.custom_data.survey)">{{currentTransaction.custom_data.survey ? 'Calificar Pedido' : '¡Gracias!'}}</div>
                    </div>
                </div>
                </transition>

                <!-- CANCELADO -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="currentTransaction.status == 'REJECTED'" class="flex-fill flex column transaction-status status-canceled" style="margin: 20px">
                    <div class="flex-fill flex column justify-center align-center">
                        <img v-if="customization.image_order_cancelled" :src="Util.img(customization.image_order_cancelled, 'banners')" alt="" height="225">
                        <status-interactive v-else status="CANCELLED"></status-interactive>
                        <!--<img :src="customization.image_order_cancelled ? Util.img(customization.image_order_cancelled, 'banners') : require('../assets/order_cancelled.png')" alt="" height="225">-->
                        <div v-html="statusMessage"></div>
                    </div>
                    <div class="flex transaction-actions">
                        <div class="btn btn-hover flex-fill flex align-center justify-center" @click="Finish()">Gracias de todas formas</div>
                    </div>
                </div>
                </transition>

                <!-- PROGRAMADO -->
                <transition enter-active-class="animated fadeIn faster">
                <div v-if="currentTransaction.status == 'SCHEDULED'" class="flex-fill flex column transaction-status status-scheduled" style="margin: 20px">
                    <div class="flex-fill flex column justify-center align-center">
                        <img v-if="customization.image_order_scheduled" :src="Util.img(customization.image_order_scheduled, 'banners')" alt="" height="225">
                        <status-interactive v-else status="SCHEDULED"></status-interactive>
                        <!--<img :src="customization.image_order_scheduled ? Util.img(customization.image_order_scheduled, 'banners') : require('../assets/order_scheduled.png')" alt="" height="225">-->
                        <div v-html="statusMessage"></div>
                    </div>
                    <div class="flex transaction-actions" v-if="currentTransaction.custom_data.auto_confirm_bank_deposit">
                        <div class="btn btn-hover flex-fill" @click="modalBankData=true">Ver Datos Bancarios</div>
                    </div>
                    <div class="flex transaction-actions" v-if="CheckAdditionalPaymentData && !CheckConfirmPayMethod">
                        <div  class="btn btn-hover flex-fill" @click="modalAdditionalPaymentData=CheckAdditionalPaymentData">Datos {{ CheckAdditionalPaymentData.method }}</div>
                    </div>
                    
                </div>
                </transition>

            </div>
            </transition>

        </div>
    </modal>

    <modal v-model="modalActiveOrders" :title="local ? local.name : ''" class="modal-active-orders">
        
        <div v-if="modalActiveOrders" style="padding: 20px">
            <div class="btn btn-hover flex column" v-for="(order, code) in activeOrders" :key="code" style="margin-bottom: 10px; text-align: left"
            @click="OpenOrder(code)">
                <div v-if="mobile" class="btn invert secondary tag" style="margin-bottom: 5px">{{Util.OrderStatus[order.status]}}</div>
                <div class="flex">
                    <div class="flex column flex-fill">
                        <div class="c1 s2 bold" style="text-transform: capitalize">{{OrderType[order.type]}}</div>
                        <div class="c1 s3"><i class="icon icon-clock" style="transform: none"></i> Enviado {{Util.Date(order.created_at, 'HH:mm DD/MM/YYYY')}}</div>
                    </div>
                    <div class="flex column" style="text-align: right">
                        <div class="s2 bold">{{Util.Price(order.total.total)}}</div>
                        <div v-if="!mobile" class="btn invert secondary tag" style="margin-top: 5px">{{Util.OrderStatus[order.status]}}</div>
                    </div>
                </div>
            </div>
        </div>

    </modal>

    <modal v-model="modalCustomer" title="Datos Personales" desktopWidth="400px" desktopHeight="500px">
        <div class="fill flex column" style="padding: 20px; overflow-x: hidden" v-if="modalCustomer">
            <div class="btn input" style="margin-bottom: 10px">
                <div class="label left s3 c2">Nombre y Apellido</div>
                <input v-model="modalCustomer.name" type="text" class="s2 w-label left">
            </div>

            <div v-if="type != 'SKIP_LINE'" class="btn input" style="margin-bottom: 10px">
                <div class="label left s3 c2">Teléfono</div>
                <input v-model="modalCustomer.phone" type="text" class="s2 w-label left">
            </div>

            <div v-if="type != 'SKIP_LINE'" class="btn input" style="margin-bottom: 10px">
                <div class="label left s3 c2">Email</div>
                <input v-model="modalCustomer.email" type="text" class="s2 w-label left" placeholder="Te enviaremos notificaciones de tu pedido">
            </div>

            <div class="flex-fill"></div>

            <div :class="`btn btn-hover flex column ${CheckCustomer(modalCustomer, true) ? 'disabled' : 'invert'}`" @click="CheckCustomer(modalCustomer, true) ? null : (modalCustomer.phone && modalCustomer.email? SaveCustomer(modalCustomer) : ConfirmLocation(true))"
            :title="CheckCustomer(modalCustomer, true) ? 'Completa los datos faltantes' : 'Guardar los datos ingresados'">
                <div>Aceptar</div>
                <div v-if="!CheckCustomer(modalCustomer, true) && !modalCustomer.phone && !modalCustomer.email && orderOptions.skip_line && openDays['TAKEAWAY'].openNow && mobile" class="s3 c2">Para "Saltarse la fila" no es necesario <br>Teléfono o Email</div>
                <div v-if="CheckCustomer(modalCustomer, true)" class="s3 c2">{{CheckCustomer(modalCustomer, true).reason}}</div>
            </div>
        </div>
    </modal>

    <modal v-model="modalOrderTime" title="Entrega del Pedido">
        <div class="fill flex column" style="overflow-x: hidden" v-if="modalOrderTime">
            <div class="ta-center cn s3" style="margin-bottom: 10px; margin-top: 20px; padding: 0px 20px" v-if="minDate && minDate.anticipation">La fecha mínima debe ser {{ minDate.dateStartParse }} debido a productos con reserva en el pedido</div>
            <div class="flex flex-fill ofy">
                <div class="ofy flex-fill" style="overflow-x: hidden; padding: 20px; padding-right: 10px" ref="">
                    <div :class="`btn ${option.value == modalOrderTime.selectedTime || (modalOrderTime.selectedTime == 'SCHEDULE' && option.value == modalOrderTime.scheduleDay) ? 'invert' : 'btn-hover'}`" style="margin-bottom: 10px" v-for="option in dayOptions" :key="option.value"
                    @click="SelectDay(option.value)">{{option.label}}</div>
                </div>
                <div class="ofy flex-fill" style="overflow-x: hidden; padding: 20px; padding-left: 10px" v-show="modalOrderTime.selectedTime !== 'AUTO' && (shipmentType == 'MANUAL_DATETIME' || ecommerceType !== 'SHIPMENT')">
                    <div :class="`btn ${modalOrderTime.scheduleTime == option.value ? 'invert' : 'btn-hover'}`" v-for="option in dayTimeOptions" :key="option.value" style="margin-bottom: 10px"
                    @click="modalOrderTime.scheduleTime = option.value">{{option.label}}</div>
                </div>
            </div>
            
            <div style="padding: 20px;">
                <div :class="`btn btn-hover flex column ${modalOrderTime.selectedTime !== 'AUTO' && (modalOrderTime.scheduleDay == 'NONE' || modalOrderTime.scheduleTime == 'NONE') ? 'disabled' : 'invert'}`"
                @click="modalOrderTime.selectedTime == 'AUTO' || (modalOrderTime.scheduleDay != 'NONE' && modalOrderTime.scheduleTime != 'NONE') ? SetOrderTime(modalOrderTime) : null"
                title="Continuar con el horario de entrega seleccionado">
                    <div>Aceptar</div>
                    <div v-show="modalOrderTime.selectedTime == 'SCHEDULE' && modalOrderTime.scheduleDay == 'NONE'" class="s3 c2">Seleccione un día válido</div>
                    <div v-show="modalOrderTime.selectedTime == 'SCHEDULE' && modalOrderTime.scheduleDay != 'NONE' && modalOrderTime.scheduleTime == 'NONE'" class="s3 c2">Seleccione una hora válida</div>
                </div>
            </div>
        </div>
    </modal>

    <modal v-model="modalPayMethod" title="Método de Pago" desktopWidth="400px" desktopHeight="500px">
        <div class="fill flex column" style="overflow-x: hidden; padding: 20px" v-if="modalPayMethod">
            
            <div v-for="method in paymentMethods" :key="method.method" class="flex column" style="margin-bottom: 10px" >
                <button :class="`btn ${method.method == modalPayMethod.method ? 'invert' : 'btn-hover'}`"
                @click="modalPayMethod.method = method.method"
                :title="`Pagar pedido con ${Util.PayMethod(method.method)}`"
                :disabled="!method[dictType[type].toLowerCase()]"
                v-show="ecommerceType == 'RESTAURANT' || method.delivery">
                    <div class="flex column">
                        <div>{{Util.PayMethod(method.method)}}</div>
                        <div v-show="ecommerceType == 'RESTAURANT'" v-if="method.delivery != method.takeaway" class="c2" style="font-weight: normal; font-size: 12px">Sólo para pedidos {{method.delivery ? 'Delivery' : 'para Retiro'}}</div>
                    </div>
                </button>

                <div v-if="method.method == 'CASH'" class="btn input left" style="margin-top: 10px;" v-show="modalPayMethod.method == 'CASH'" title="Ingresa con cuanto efectivo pagarás tu pedido">
                    <div class="label left s3 c2">Efectivo</div>
                    <input v-model="modalPayMethod.cash" type="text" class="w-label left s2" placeholder="¿Con cuanto efectivo pagarás tu pedido?" oninput="this.value=this.value.replace(/[^0-9]/g,'');" @blur="LastDigitZero">
                </div>

            </div>
            
            <div class="flex-fill"></div>

            <div :class="`btn btn-hover flex column ${modalPayMethod.method == 'NONE' ? 'disabled' : 'invert'}`" style="margin-top: 10px;" title="Continuar con el método de pago seleccionado"
            @click="SelectPayMethod(modalPayMethod.method)">
                <div>Aceptar</div>
                <div v-show="modalPayMethod.method == 'NONE'" class="s3 c2">Selecciona un método de pago</div>
            </div>
            
        </div>
    </modal>


    <modal v-model="modalBankData" title="Datos Bancarios" desktopWidth="450px" desktopHeight="550px">
        <div class="fill flex column align-center" style="padding: 20px; overflow: hidden" v-if="currentTransaction && currentTransaction.custom_data && currentTransaction.custom_data.bank_data">
            <table class="bank-data">
                <tr class="c1"><td>Nombre</td><td>{{currentTransaction.custom_data.bank_data.name}}</td></tr>
                <tr class="c1"><td>RUT</td><td>{{currentTransaction.custom_data.bank_data.rut}}</td></tr>
                <tr class="c1"><td>Banco</td><td>{{currentTransaction.custom_data.bank_data.bank}}</td></tr>
                <tr class="c1"><td>Cuenta</td><td>{{currentTransaction.custom_data.bank_data.account}} {{currentTransaction.custom_data.bank_data.account_number}}</td></tr>
                <tr class="c1"><td>Email</td><td>{{currentTransaction.custom_data.bank_data.email}}</td></tr>
            </table>
            <div class="flex-fill"></div>
            <div style="width: 100%">
                <a :href="`https://${!mobile ? 'web' : 'api'}.whatsapp.com/send?phone=${currentTransaction.custom_data.bank_data.phone}`" target="_blank" :class="`btn btn-hover fill flex flex-fill column`" title="Abrir Whatsapp"><span>Contactar por Whatsapp</span><span class="small hide-mobile">{{currentTransaction.custom_data.bank_data.phone}}</span></a>
            </div>
        </div>
    </modal>

    <modal v-model="modalAdditionalPaymentData" title="Información de método de pago" desktopWidth="450px" desktopHeight="550px">
        <div class="fill flex column" style="padding: 20px; overflow: hidden" v-if="additionalPaymentData && additionalPaymentData.payment_data">
            <span style="white-space: break-spaces">{{additionalPaymentData.payment_data}}</span>
            <div class="flex-fill"></div>
            <div style="width: 100%">
                <a v-if="additionalPaymentData.whatsapp" :href="`https://${!mobile ? 'web' : 'api'}.whatsapp.com/send?phone=${additionalPaymentData.whatsapp}`" target="_blank" :class="`btn btn-hover fill flex flex-fill column`" title="Abrir Whatsapp"><span>Contactar por Whatsapp</span><span class="small hide-mobile">{{additionalPaymentData.whatsapp}}</span></a>
            </div>
        </div>
    </modal>

    <modal v-model="modalCancelOrder" title="Cancelar Pedido" desktopWidth="400px" desktopHeight="300px" :closeable="!loadingAction">
        <div class="fill flex column" style="padding: 20px">
            <div>El pedido fue recibido pero aún no ha sido aceptado ¿Desea cancelarlo?</div>
            <div class="flex-fill"></div>
            <div class="btn btn-hover" style="margin-bottom: 10px" @click="modalCancelOrder=null">No Cancelar</div>
            <div :class="`btn invert btn-hover ${loadingAction ? 'disabled' : ''}`" @click="loadingAction ? null : Reject()" title="Cancelar el envio del pedido"><span v-if="!loadingAction">Cancelar Pedido</span><i v-else class="icon icon-spinner spin"></i></div>
        </div>
    </modal>

    <!-- MODAL PARA RE-INGRESAR EFECTIVO A PAGAR -->
    <modal v-model="modalEnterCash" title="Ingresar efectivo" desktopWidth="450px" desktopHeight="350px">
        <div class="fill flex column" style="padding: 20px">
            <div>Debes ingresar un monto en Efectivo igual o mayor a <span class="bold">{{Util.Price(total.total)}}</span> para pagar el pedido. </div>
            <div class="btn input left" style="margin-top: 15px;" title="Ingresa con cuanto efectivo pagarás tu pedido">
                <div class="label left s3 c2">Efectivo</div>
                <input v-if="modalEnterCash" v-model="modalEnterCash.cash" type="text" class="w-label left s2" placeholder="¿Con cuanto efectivo pagarás tu pedido?" oninput="this.value=this.value.replace(/[^0-9]/g,'');" @blur="LastDigitZero">
            </div>
            <div class="flex-fill"></div>
            <div @click="EnterNewCash" v-if="modalEnterCash" :class="`btn btn-hover flex column ${modalEnterCash.cash >= total.total ? 'invert' : 'disabled'}`" style="margin-top: 30px;">
                <div>Aceptar</div>
            </div>
        </div>
    </modal>

    <loading-screen :loading="loading"></loading-screen>
    <modal-schedule ref="modalLocalSchedule" v-if="local" :ecommerceType="ecommerceType" :openDays="openDays" :isDisabled="isDisabled" :formatRange="format_range" :formatHour="format_hour" :timePatch="timePatch"></modal-schedule>
    <modal-map ref="modalMap" @OnAddressChanged="SetAddress($event)"></modal-map>
    <analytics-controller ref="analytics" :dictItems="dictItems" :categories="categories" :total="total" :myOrder="myOrder"></analytics-controller>
    <modal-coupon ref="modalCoupon" @OnCoupon="SetCoupon($event)"></modal-coupon>
    <modal-tip ref="modalTip" @OnTip="SetTip($event)" :total="total" :currentTip="currentTip"></modal-tip>

</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import "swiper/css/swiper.min.css";
import LoadingScreen from './common/LoadingScreen.vue';
import ModalSchedule from './common/ModalSchedule.vue';
import ModalMap from './common/ModalMap.vue';
import AnalyticsController from './common/AnalyticsController.vue';
import ModalCoupon from './common/ModalCoupon.vue';
import ModalTip from './common/ModalTip.vue';
import LeftMenu from './common/LeftMenu.vue';
import MenuItems from './common/MenuItems.vue';
import StatusInteractive from './common/StatusInteractive.vue'

var moment = require('moment');
export default {
    components: {LoadingScreen, Swiper, SwiperSlide, ModalSchedule, ModalMap,
        AnalyticsController, ModalCoupon, ModalTip,
        LeftMenu, MenuItems, StatusInteractive
    },
    data() {
        return {
			//@data loading <Boolean> indicador si estan cargando los datos del ecommerce
            loading: true,
            local: null,
            locals: null,
            menu: null,
            instagramImages: [],
            today: null,
            schedules: null,
            orderOptions: null,
            paymentMethods: null,
            confirm_cash: false,
            modalModifiers: false,
            selectedItem: null,
            myOrder: {},
            redirection: null,
            searchAddress: '',
            timerSearchAddress: null,
            addresses: [],
            showAddress: false,
            currentAddress: null,
            timeOptions: [{value: 'AUTO', label: 'Lo antes posible'}],
            type: null,
            customerName: '',
            customerPhone: '',
            customerEmail: '',
            selectedTime: 'AUTO',
            selectedPaymentMethod: 'NONE',
            currentTransaction: null,
            polling: null,
            minimun: 0,
			loadingAction: false,
			addressComment: '',
			cash: null,
			orderComment: '',
			previousUser: null,
            audioBell: null,
            audioBop: null,
            audioPaperRip: null,
            modalError: null,
            banner: null,
            showRightMenu: false,
            showTransactionDetails: false,
            scheduleDay: 'NONE',
            scheduleTime: 'NONE',
            //dayOptions: [{value: 'NONE', label: 'Seleccione el día'}],
            dayTimeOptions: [],
            canSchedule: false,
            //url action loaded on mounted
            action: null,
            //current order code
            code: null,
            modalQuantity: null,
            couponCode: '',
            coupon: null,
            modalMap: null,
            moduleCoupons: false,
            ecommerceType: null,
            searchItem: '',
            searchFocused: false,
            timestamp: 0,
            timerTimestamp: null,
            modalItem: null,
            modalCustomer: null,
            url: null,
            modalOrderTime: null,
            modalPayMethod: null,
            modalEnterCash: null,
            active_cash: false,
            dictItems: {},
            disabledUntil: null,
            carousel: null,
            topItems: null,
            customization: null,
            modalBankData: null,
            OrderType: {
                TAKEAWAY: 'Pedido para retirar',
                DELIVERY: 'Pedido delivery',
                SHIPMENT: 'Encargo'
            },
            modalCancelOrder: false,
            currentTip: 0,
            modalTip: null,
            modalTipPercentage: null,
            tipsEnabled: true,
            defaultTip: 0,
            gtag: null,
            categories: {},
            modalReplacement: null,
            modalCombo: null,
            timePatch: null,
            fbPixel: null,
            shipmentType: null,
            statusDeliveryPya: false,
            schedulesPya: null,
            scheduledMinTime: 90,
            itemPriceDisplay: 'ITEM_PRICE',
            activeOrders: {},
            ordersCodes: [],
            modalActiveOrders: null,
            orderListModified: null,
            delay: null,
            recommendProductsConfig: false,
            recommendProducts: null,
            arrayRecommendProducts : 0,
            searchMyAddress: '',
            nearbyPremises: null,
            modalScheduleFranchise: null,
            anticipationFilter: false,
            format_hour: null,
            format_range: null,
            anticipationFilter: false,
            defaultSale: null,
            itemImageSize: 'DEFAULT',
            menuDisplay: 'ALL',
            dictType: {
                TAKEAWAY: 'TAKEAWAY',
                DELIVERY: 'DELIVERY',
                SKIP_LINE: 'TAKEAWAY'
            },
            distance: {
                value: null,
                restriction: false,
                msgError: null,
            },
            loadingBottom: false,
            deliveryConditionRules : null,
            isSearchInputVisible: null,
            isSearchSectionsVisible: null,
            idItemsCoupon : [],
            simplifiedData : {},
            modalAdditionalPaymentData : false,
            additionalPaymentData : null
        }
    },
    mounted() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has('address') && !localStorage.address) {
            const addressString = urlParams.get('address');
            const address = JSON.parse(decodeURIComponent(addressString));
            localStorage.address = JSON.stringify(address);
        }

        var url = new URL(window.location.href);
        this.url = window.location.href;
        var paramCode = url.searchParams.get('code');
        if(paramCode)
        {
            localStorage.code = paramCode;
            //window.location.href = window.location.href.split('?')[0];
        }
        this.action = url.searchParams.get('action');
        
        document.addEventListener('click', (event) => {
            if(this.showAddress)
            {
                for(var i = 0; i < event.path.length; i++)
                    if(event.path[i].className && event.path[i].className.indexOf('ADDRESS') > -1) return;
                
                this.showAddress = false;
            }
        });

        //this.today = moment().format('dddd').toLowerCase();

        var momentES = moment();
        momentES.locale('es');
        if(typeof data == 'undefined')
        {
            //load data from query
            axios.get(`data`).then(res => {
                this.InitialSetup(res.data);    
            }).catch(err => {
                this.loading = 'ERROR';
            });
        }
        else
        {
            //load data embeded on page
            this.InitialSetup(data.data);
        }

        //polling interval
        this.timerPolling = setInterval(() => {
            if(this.$socket.connected) return;
            if(!this.ordersCodes.length) return;
            this.UpdateOrders();
        }, 10*1000);

        this.audioBell = new Audio(require('../assets/bells.mp4'));
        this.audioBop = new Audio(require('../assets/bop.mp4'));
        this.audioPaperRip = new Audio(require('../assets/paper_rip.mp4'));   

        setInterval(() => {
            this.timestamp++;
        }, 1000);

    },
    methods: {
        InitialSetup(data)
        {
            this.timestamp = data.timestamp;
            this.today = moment.unix(this.timestamp).format('dddd').toLowerCase();

            this.local = data.local;
            this.menu = data.menu;
            this.instagramImages = data.ig_images;
            this.schedules = data.open_at;
            this.delay = data.delay;
            this.format_hour = data.format_hour;
            this.Util.setFormatHour(this.format_hour);
            this.format_range = data.format_range;
            this.defaultSale = data.default_sale;
            this.orderOptions = data.order_options;
            this.paymentMethods = data.payment_methods;
            this.confirm_cash = data.confirm_cash;
            this.distance.restriction = data.restrict_distance;
            this.minimun = data.delivery_minimun;
            this.recommendProductsConfig = data.recommend_products_active;
            this.banner = data.banner;
            this.ecommerceType = data.ecommerce_type;
            this.shipmentType = data.shipment_type;
            this.canSchedule = this.ecommerceType == 'RESTAURANT' ? data.accepts_scheduled : this.shipmentType !== 'AUTO';
            document.body.classList.add(this.ecommerceType);
            this.moduleCoupons = data.coupons;
            this.disabledUntil = data.disabled_until;
            this.carousel = data.carousel;
            this.topItems = data.popular;
            this.deliveryConditionRules = data.delivery_rules;
            this.customization = data.customization;
            this.tipsEnabled = data.tips;
            this.defaultTip = data.default_tip;
            if(data.categories) this.categories = data.categories;
            this.timePatch = data.time_patch;
            if(!isNaN(data.min_scheduled_time_today)) this.scheduledMinTime = data.min_scheduled_time_today;
            if(data.item_price_display) this.itemPriceDisplay = data.item_price_display;

            if(this.orderOptions.delivery && this.orderOptions.takeaway){
                this.type = this.defaultSale == 'DELIVERY'? 'DELIVERY' : 'TAKEAWAY';
            }else{
                if(this.orderOptions.delivery) this.type = 'DELIVERY';
                else if(this.orderOptions.takeaway) this.type = 'TAKEAWAY';
            }

            this.statusDeliveryPya = data.pya_courier;
            this.menuDisplay = data.menu_display;

            let auxArrayMethods = []
            auxArrayMethods = data.payment_methods
            let indexMap = {};
            data.payment_methods_order.forEach((obj, index) => {
                indexMap[obj] = index;
            });

            let sortedMethods = auxArrayMethods.sort((x, y) => indexMap[x.method] - indexMap[y.method]);

            this.paymentMethods = sortedMethods

            if(localStorage.ordersCodes) this.ordersCodes = JSON.parse(localStorage.ordersCodes);
            if(!Array.isArray(this.ordersCodes)) this.ordersCodes = [];

            if(localStorage.code)
            {
                this.code = localStorage.code;
                if(this.ordersCodes.indexOf(this.code) == -1) this.ordersCodes.push(this.code);
            }

            this.menu.forEach(section => {
                section.items.forEach(item => {
                    if(section.title != 'Productos con anticipación'){
                        const newItem = { ...item, section: section.title };
                        newItem['section_hide_delivery'] = section.hide_delivery;
                        newItem['section_hide_takeaway'] = section.hide_takeaway;
                        this.$set(this.dictItems, item.id, newItem);
                    }
                });
            });

            const currentPath = window.location.pathname;
            const itemTitle = decodeURIComponent(currentPath.substring(1));
            let showItem = false;

            if(itemTitle){
                let foundItem = null;
                for (const section of Util.Copy(this.filteredMenu)) {
                    foundItem = section.items.find(item => item.title === itemTitle.trim());
                    if(foundItem) break;
                }

                if(foundItem && !foundItem.unavailable) showItem = true;
                
                this.$nextTick(() => {
                    if(this.$refs.menuItems && foundItem){
                        //if(foundItem.unavailable) return this.Error('Producto no disponible');
                        if(!foundItem.unavailable) return this.$refs.menuItems.OpenModalItem(foundItem);
                    } 
                });
            }

            if(this.ordersCodes.length)
            {
                this.UpdateOrders(data => {
                    
                    var transactions = Object.values(data);
                    if(this.code && data[this.code])
                    {
                        this.currentTransaction = showItem? null : data[this.code];
                        if(this.action)
                        {
                            if(this.action == 'accept')
                                this.Accept(true);
                            else if(this.action == 'reject')
                                this.Reject(true);
                            else if(this.action == 'transfer')
                                this.BankDepositReady(true);
                        }
                    }
                    else if(transactions.length > 1)
                    {
                        this.modalActiveOrders = true;
                    }
                    else if(transactions.length == 1)
                    {
                        this.currentTransaction = showItem? null : transactions[0];
                    }
                    this.loading = false;
                }, () => {
                    this.loading = false;
                });
            }
            else
            {
                this.$nextTick(() => {
                    this.loading = false;
                });
            }

            if(localStorage.customer)
            {
                var customer = JSON.parse(localStorage.customer);
                this.customerName = customer.name;
                this.customerPhone = customer.phone;
                //this.currentAddress = customer.address;
				this.customerEmail = customer.email;
				this.previousUser = customer;
            }

            if(localStorage.address)
            {
                var address = JSON.parse(localStorage.address);
                if(address && address.street)
                {
                    this.currentAddress = address;
                    
                    let customerData = {};

                    if(this.customerName) customerData.name = this.customerName;
                    if(this.customerPhone) customerData.phone = this.customerPhone;
                    if(this.customerEmail) customerData.email = this.customerEmail;

                    axios.post(`/address/check/${address.lat}/${address.lon}`, customerData).then(res => {
                        if(res.data)
                        {
                            //actualizar costo
                            this.$set(this.currentAddress, 'cost', res.data.cost);
                            this.$set(this.currentAddress, 'discount', res.data.discount);
                            this.$set(this.currentAddress, 'timestamp', res.data.timestamp);
                            if(res.data.deliveryOfferId) this.$set(this.currentAddress, 'deliveryOfferId', res.data.deliveryOfferId);
                            if(res.data.estimateId) this.$set(this.currentAddress, 'estimateId', res.data.estimateId);
                            localStorage.address = JSON.stringify(this.currentAddress);
                        }
                        else
                        {
                            //ya no hace reparto a esta direccion
                            this.currentAddress = null;
                            localStorage.removeItem('address');
                        }
                    });
                    

                }
            }

            if(this.ecommerceType == 'RESTAURANT' || this.ecommerceType == 'SHIPMENT')
            {
                if(!this.openDays[this.type].openNow && this.canSchedule)
                    this.SetOrderTime({selectedTime: 'SCHEDULE', scheduleDay: 'NONE', scheduleTime: 'NONE'});    
                //this.selectedTime = 'SCHEDULE';
            }

            this.Util.ApplyCustomization(data.customization);
            if(data.customization && data.customization.item_image_size) this.itemImageSize = data.customization.item_image_size;

           //Google Analytics
            this.$refs.analytics.SetupGoogleAnalytics(data.google_analytics);

            //var facebookPixelID = '539373001231229';
            const fbclid = new URLSearchParams(window.location.search).get('fbclid');

            if(fbclid){
                this.$refs.analytics.fbclid = fbclid;
                this.$refs.analytics.fbclid_time = Date.now();
            }

            this.$refs.analytics.SetupFacebookPixel(data.facebook_pixel, data.facebook_access_token);

            //check search bar visibility items
            setInterval(() => {
                this.isSearchInputVisible = window.getComputedStyle(this.$refs.searchInput).display !== 'none';
                this.isSearchSectionsVisible = window.getComputedStyle(this.$refs.searchSections).display !== 'none';
            }, 200);
            

        },
        MenuClick(section)
        {
            this.searchItem = '';
            const escapedTarget = 'section'+section.title.replace(/ /g, '').replace(/[-\/\\^$*+?.()|[\]{}%]/g, '\\$&');

            setTimeout(() => {
                ScrollTo(document.querySelector(`#${escapedTarget}`), {
                    elementToScroll: this.$refs.panelCenter,
                    verticalOffset: -80,
                    maxDuration: 300
                });
            }, 10);

            if(section && section.items && section.items.length)
            {
                this.$refs.analytics.Trigger('view_item_list', section);
            }
            
        },
        async AddItem(item, editKey)
        {
            if(this.loadingBottom) return;
            if(editKey && item.key !== editKey && this.myOrder[editKey])
            {
                this.$delete(this.myOrder, editKey);
                this.$refs.analytics.Trigger('remove_from_cart', item);
            }

            let tmpItem = null;
            if(item.item && item.item.id) tmpItem = this.dictItems[item.item.id];
            
            let fullProduct = item.item && item.item.id? this.dictItems[item.item.id] : this.dictItems[item.key.split("-")[0]];
            if(fullProduct.inventory_references) this.loadingBottom = true;
            if(!this.myOrder[item.key])
            {
			    let section = this.menu.find( section => section.title == tmpItem.section);
                var quantity = parseInt(item.quantity * 1000) / 1000;

                if(fullProduct && fullProduct.max_quantity_enabled){
                    if( (quantity > fullProduct.max_quantity) || (this.orderByIdQuantity && quantity + this.orderByIdQuantity[fullProduct.id] > fullProduct.max_quantity))
                    {
                        this.loadingBottom = false;
                        return this.Error(`La cantidad máxima de ${item.item.title} en el pedido es ${fullProduct.max_quantity}`);
                    }
                }

                var itemData = {
                    id: item.item.id,
                    title: item.item.title,
                    image: item.item.image,
                    total: item.total,
                    modifiers: item.modifiers,
                    quantity: quantity,
                    comment: item.comment ? item.comment : null,
                    float_quantity: item.float_quantity,
                    combo: !!item.combo,
                    section: item.section,
                    anticipation: tmpItem.anticipation,
                    anticipation_time: tmpItem.anticipation_time,
                    key: item.key,
                    hide_delivery: tmpItem.hide_delivery? tmpItem.hide_delivery : 0,
                    hide_takeaway: tmpItem.hide_takeaway? tmpItem.hide_takeaway : 0,
                };
                if(item.item.overwrite) itemData.overwrite = true

                if(item.combo){
                    itemData.subitems = item.subitems;
                }else{
                    itemData.section_hide_delivery = section.hide_delivery;
                    itemData.section_hide_takeaway = section.hide_takeaway;
                    itemData.section = tmpItem.section;
                }

                const stock = await this.ValidateStock(fullProduct, Util.Copy(this.myOrder), Util.Copy(itemData));
                if(!stock) return;
                this.Util.ClearLink();

                this.$set(this.myOrder, item.key, itemData);
                this.$refs.analytics.Trigger('add_to_cart', item);
            }
            else
            {
                var quantity = this.myOrder[item.key].quantity + (parseInt(item.quantity * 1000) / 1000);
                if(editKey) quantity = parseInt(item.quantity * 1000) / 1000;
                let oldQuantity = this.myOrder[item.key].quantity;
                const subtract = quantity < oldQuantity? true : false;

                if(fullProduct && fullProduct.max_quantity_enabled){
                    if( (quantity > fullProduct.max_quantity) || (this.orderByIdQuantity && quantity - oldQuantity + this.orderByIdQuantity[fullProduct.id] > fullProduct.max_quantity) )
                    {
                        this.loadingBottom = false;
                        return this.Error(`La cantidad máxima de ${fullProduct.title} en el pedido es ${fullProduct.max_quantity}`);
                    } 
                }

                let stock = false;
                if(!subtract){
                    stock = await this.ValidateStock(fullProduct, Util.Copy(this.myOrder), Util.Copy(item), quantity);
                }
                
                this.loadingBottom = false;

                if(!subtract && !stock) return;
                this.Util.ClearLink();

                this.$set(this.myOrder[item.key], 'quantity', quantity);
                if(this.myOrder[item.key].quantity <= 0)
                {
                    var el = this.$refs[`order-${item.key}`][0];
                    el.classList.add('itemRemove');
                    setTimeout(() => {
                        this.$delete(this.myOrder, item.key);
                        if (this.shipmentType === 'AUTO') {
                            if (this.minDate?.anticipation) {
                                this.validateAutoSchedule(this.minDate.dateStart?.unix());
                            } else if (!this.minDate && !Object.values(this.myOrder).length) {
                                this.validateAutoSchedule();
                            }
                        }
                    }, 500);
                    this.audioPaperRip.play();
                    this.loadingBottom = false;
                }
                else
                {
                    this.$nextTick(() => {
                        var el = this.$refs[`order-${item.key}`][0];
                        if(el)
                        {
                            el.classList.remove('itemUpdate');
                            setTimeout(() => {
                                el.classList.add("itemUpdate");
                            }, 1);
                        }
                    });
                }
                this.$refs.analytics.Trigger('remove_from_cart', item);
            }

            if(this.myOrder[item.key].quantity > 0)
            {
                this.audioBop.play();
                this.$nextTick(() => {
                    var el = this.$refs[`order-${item.key}`][0];
                    if(el) {
                        ScrollTo(el, {
                            elementToScroll: this.$refs.panelRight,
                            verticalOffset: -20
                        });
                    }
                });
            }

            if(tmpItem && tmpItem.anticipation){
                if(this.currentTransaction && this.currentTransaction.status == 'CONFIRMATION' && this.shipmentType == 'AUTO'){
                    const typeService = this.type == 'TAKEAWAY'? '_takeaway' : '';
                    if(this.schedules["shipment_start"+typeService] < this.minDate.dateStart.unix()){
                        this.validateAutoSchedule(this.minDate.dateStart.unix());
                    }
                }

                if(this.scheduleDay != 'NONE' && this.scheduleTime != 'NONE'){
                    const selectedTimeUnix = moment(`${this.scheduleDay} ${this.scheduleTime}`, 'DD/MM/YYYY HH:mm').unix();
                    if(selectedTimeUnix < this.minDate.dateStart.unix()){
                        this.selectedTime = 'SCHEDULE';
                        this.scheduleDay = 'NONE';
                        this.scheduleTime = 'NONE';
                    }
                }else{
                    this.selectedTime = 'SCHEDULE';
                    this.scheduleDay = 'NONE';
                    this.scheduleTime = 'NONE';
                }
            }

            this.$nextTick(() => {
                var el = this.$refs[`btn-continue`];
                if(el)
                {
                    el.classList.remove('itemUpdate');
                    setTimeout(() => {
                        el.classList.add("itemUpdate");
                    }, 1);
                }
            });

            this.$refs.menuItems.modalItem = null;
            this.$refs.menuItems.modalCombo = null;

        },
        EditItem(item, editKey)
        {
            var it = this.Util.Copy(this.dictItems[item.id]);
            it.quantity = item.quantity.toString().replace('.', ',');
            it.comment = item.comment;

            const queryParams = window.location.search;
            this.Util.ClearLink();

            const newUrl = `${window.location.origin}${window.location.pathname}${encodeURIComponent(item.title)}%20${queryParams}`;
            history.pushState(null, '', newUrl);

            function SelectModifiers(sourceItem, selectedModifiers)
            {
                var dictMods = {};
                selectedModifiers.forEach(modifier => {
                    var modKey = `${modifier.group}_${modifier.modifier_original ? modifier.modifier_original : modifier.title}`;
                    if(modifier.modifier_original)
                        dictMods[modKey] = modifier.modifier_replacement;
                    else
                        dictMods[modKey] = dictMods[modKey] ? dictMods[modKey]+1 : 1;
                });

                sourceItem.modifiers.forEach(group => {
                    group.modifiers.forEach(modifier => {
                        var modKey = `${group.title}_${modifier.title}`;
                        
                        if(!group.multi_select && dictMods[modKey])
                        { 
                            if(group.replacement)
                            {
                                var replacement = group.modifiers_replacement.find((option) => {
                                    return option.title == dictMods[modKey];
                                });
                                if(replacement)
                                {
                                    modifier.selected = true;
                                    modifier.replacement = replacement;
                                }
                            }
                            else
                            {
                                modifier.selected = true;
                            }
                        }
                        else if(group.multi_select && dictMods[modKey]) modifier.count = dictMods[modKey];
                    });
                });
            }

            
            it.edit_key = editKey;

            if(it.type == 0)
            {
                SelectModifiers(it, item.modifiers);
                //this.modalItem = it;
                this.$refs.menuItems.modalItem = it;
            }
            else if(it.type == 1)
            {
                for(var i = 0; i < it.products.length; i++)
                {
                    SelectModifiers(it.products[i], item.modifiers[i].modifiers)
                }
                //this.modalCombo = it;
                this.$refs.menuItems.modalCombo = it;
            }
        },
        
        /*
        {
            codes: []
            transaction: {}
            transactions: []
        }
        */
        UpdateOrders(success, error) {
            var updateTime = this.timestamp;
            axios.post(`/active/orders`, {codes: this.ordersCodes}).then(res => {

                if(this.orderListModified && this.orderListModified > updateTime) 
                {
                    if(error) error();
                    return;
                }

                if(Array.isArray(res.data))
                {
                    localStorage.ordersCodes = JSON.stringify([]);
                    this.activeOrders = {};
                    this.ordersCodes = [];
                }
                else
                {
                    var codes = Object.keys(res.data);
                    localStorage.ordersCodes = JSON.stringify(codes);
                    this.activeOrders = res.data;
                    this.ordersCodes = codes;
                }
                if(success) success(this.activeOrders);
                
            }).catch(err => {
                if(error) error(err);
            });
        },
        OpenOrder(code) {

            if(!code && this.activeOrdersCount == 1)
            {
                let keys = Object.keys(this.activeOrders);   
                code = keys[0];
            }

            if(code && this.activeOrders[code])
            {
                if(this.modalActiveOrders)
                {
                    this.modalActiveOrders = null;
                    setTimeout(() => {
                        this.currentTransaction = this.activeOrders[code];
                    }, 200);
                }
                else
                    this.currentTransaction = this.activeOrders[code];
            }
            else if(this.activeOrdersCount > 0)
            {
                if(this.currentTransaction)
                {
                    this.currentTransaction = null;
                    setTimeout(() => {
                        this.modalActiveOrders = true;
                    }, 200);
                }
                else
                    this.modalActiveOrders = true;
            }
            else if(this.code && this.activeOrders[this.code])
                this.currentTransaction = this.activeOrders[this.code];
            /*
            else if(this.activeOrdersCount > 0)
            {
                var key = Object.keys(this.activeOrders)[0];
                this.currentTransaction = this.activeOrders[key];
            }
            */
        },
		OpenOrderConfirm()
		{
            if(this.confirm_cash && (this.cash && this.cash < this.total.total)) return this.Error("Debe ingresar cantidad mayor o igual en Efectivo para pagar.")
			this.currentTransaction = {
                orders: this.myOrder,
                location: this.type == 'DELIVERY' ? this.currentAddress : null,
                method: this.selectedPaymentMethod,
                total: this.total,
                orderComment: '',
                cash: this.selectedPaymentMethod == 'CASH' && this.cash && !isNaN(this.cash) ? this.cash : null,
                type: this.type,
                time: this.selectedTime,
                customerName: this.customerName,
                customerPhone: this.customerPhone,
                customerEmail: this.customerEmail,
				addressComment: this.currentAddress && this.currentAddress.comment ? this.currentAddress.comment : '',
                status: 'CONFIRMATION',
                scheduled: this.selectedTime != 'SCHEDULE' ? null : {
                    day: this.scheduleDay,
                    time: this.scheduleTime
                }
            };

            if(this.currentAddress && this.currentAddress.deliveryOfferId) this.currentTransaction.deliveryOfferId = this.currentAddress.deliveryOfferId;
            if(this.currentAddress && this.currentAddress.estimateId) this.currentTransaction.estimateId = this.currentAddress.estimateId;
            this.$refs.analytics.Trigger('begin_checkout', this.currentTransaction);

            if(this.shipmentType == 'AUTO' && this.minDate && this.minDate.anticipation){
                const typeService = this.type == 'TAKEAWAY'? '_takeaway' : '';
                if(this.schedules["shipment_start"+typeService] < this.minDate.dateStart.unix()){
                    this.validateAutoSchedule(this.minDate.dateStart.unix());
                }
            }

            //cargar recomendados
            if(this.recommendProductsConfig)
                this.OpenModalRecommendProducts();

		},
        SendOrder(bypassCheckDuplicates)
        {

            if((this.confirm_cash || this.selectedPaymentMethod == 'CASH') && (this.cash && this.cash < this.total.total)) {
                return this.Error("Debe ingresar cantidad mayor o igual en Efectivo para pagar.")
            }

            const skipLine = this.type == 'SKIP_LINE'? true : false;
            if(this.currentTransaction.estimateId && (!this.customerName || (!skipLine && !this.customerPhone))) return;
			if(this.currentTransaction && this.currentTransaction.code) return;
			this.loadingAction = true;
			var cash = parseInt(this.cash);
			cash = cash > this.total.total ? cash : null;
            const type = this.dictType[this.type];
            var order = {
                order: this.myOrder,
                location: type == 'DELIVERY' ? this.currentAddress : null,
                method: this.selectedPaymentMethod,
                total: this.total,
                orderComment: this.orderComment,
                cash: cash,
                type: type,
                time: this.selectedTime,
                customerName: this.customerName,
                customerPhone: this.customerPhone,
                customerEmail: this.customerEmail? this.customerEmail : null,
                addressComment: this.addressComment? this.addressComment : null,
                skipLine: skipLine,
                //pedidosYa: this.statusDeliveryPya
            };
            if(this.currentTransaction && this.currentTransaction.deliveryOfferId) order.deliveryOfferId = this.currentTransaction.deliveryOfferId;
            if(this.currentTransaction && this.currentTransaction.estimateId) order.estimateId = this.currentTransaction.estimateId;

            if(order.time == 'SCHEDULE')
            {
                order.scheduled = {
                    day: this.scheduleDay,
                    time: this.scheduleTime
                }
            }

            if(this.shipmentType != 'AUTO' && this.minDate && this.minDate.anticipation){
                if(this.scheduleDay == 'NONE' && this.scheduleTime == 'NONE'){
                    this.loadingAction = false;
                    return this.OpenModalSchedule();
                } 

                const selectedTimeUnix = moment(`${this.scheduleDay} ${this.scheduleTime}`, 'DD/MM/YYYY HH:mm').unix();
                if(selectedTimeUnix < this.minDate.dateStart.unix()){
                    this.loadingAction = false;
                    return this.OpenModalSchedule();
                }
            }

            if(!bypassCheckDuplicates)
            {
                if(!this.CheckDuplicateOrders(this.myOrder)) 
                {
                    this.loadingAction = false;
                    return;
                }
            }
            

		 	axios.post('/order', order).then(res => {
                if(res.data.error){
                    if(!res.data.removedItems) this.Error(res.data.reason ? res.data.reason : res.data.message);
                    //cambió el costo de reparto de pya
                    if(res.data.pya){
                        this.$set(this.currentAddress, 'cost', res.data.pya.cost);
                        this.$set(this.currentAddress, 'discount', res.data.pya.discount);
                    }

                    if(res.data.removedItems){
                        let deletedItems = "";

                        res.data.removedItems.forEach( item => {
                            deletedItems += `<li>${item.title}</li>`;
                            this.$delete(this.myOrder, item.key);
                        });

                        if(deletedItems != ""){
                            this.Error(`<div class='mb10 bold ta-center'>Se quitaron items no disponibles en ${this.OrderType[this.type]}</div><ul>${deletedItems}</ul>`);
                        }
                    }
                    this.loadingAction = false;
                    this.reenterCash = false
                    return;
                }
                

                this.code = res.data.code;
                localStorage.code = this.code;

                this.ordersCodes.push(this.code);
                this.$set(this.activeOrders, this.code, res.data);
                localStorage.ordersCodes = JSON.stringify(this.ordersCodes);
                this.showRightMenu = false;

                this.currentTransaction = res.data;
                this.loadingAction = false;

                //Reset things
                this.coupon = null;
                this.currentTip = 0;
                this.myOrder = {};
                this.selectedPaymentMethod = 'NONE';
                this.cash = null; 

                this.$refs.analytics.Trigger('purchase', res.data);
                this.orderListModified = this.timestamp;

            }).catch(err => {
                this.Error(err);
				this.loadingAction = false;
            });

        },
        Accept(ignoreError)
        {
            this.loadingAction = 'ACCEPT';
            axios.get(`/accept/${this.currentTransaction.code}`).then(res => {
                this.currentTransaction = res.data;
               
                this.loadingAction = false;
            }).catch(err => {
                if(!ignoreError)
                    this.Error(err);
                this.loadingAction = false;
            });
        },
        Reject(ignoreError)
        {
            this.loadingAction = 'REJECT';
            
            axios.get(`/reject/${this.currentTransaction.code}`).then(res => {
                
                this.RemoveOrderCode(this.currentTransaction.code);

                this.code = null;
                this.currentTransaction = null;
                this.loadingAction = false;
                this.modalCancelOrder = false;
                this.orderListModified = this.timestamp;

            }).catch(err => {
                if(!ignoreError) this.Error(err);
                this.loadingAction = false;
            });
        },
        Finish(code, openSurvey)
        {
            this.orderListModified = this.timestamp;
            code = code ? code : this.currentTransaction.code;
            this.RemoveOrderCode(code);
            axios.get(`/finish/${code}`).then(res => {
                
            }).catch(err => {
                
            });

            if(openSurvey)
            {
                this.Util.Open(`${this.Util.backend}survey/${code}`);
            }

            this.currentTransaction = null;
            this.code = null;
        },
        BankDepositReady(ignoreError)
        {
            this.loadingAction = true;
            axios.get(`/deposit/ready/${this.currentTransaction.code}`).then(res => {
                
                this.currentTransaction = res.data;
                this.loadingAction = false;
            }).catch(err => {
                if(!ignoreError) this.Error(err);
                this.loadingAction = false;
            });
        },
        RemoveOrderCode(code)
        {
            var index = this.ordersCodes.indexOf(code);
            if(index >= 0)
            {
                this.ordersCodes.splice(index, 1);
                localStorage.ordersCodes = JSON.stringify(this.ordersCodes);
            }
            this.$delete(this.activeOrders, code);
        },
        DayTimeOptions(dayObj, today)
        {
            //calcular los tiempos de despacho posibles
            dayObj.locale('en');
            var day = dayObj.format('dddd').toLowerCase();
            //var start = moment(dayObj);
            var minTime = null;
            if(today)
            {
                minTime = moment.unix(this.timestamp);

                //scheduled min time
                minTime.add('minutes', this.scheduledMinTime);
                if(minTime.minute() <= 30)
                {
                    minTime.minute(30);
                }
                else
                {
                    minTime.minute(0);
                    minTime.add('hour', 1);
                }
            }

            var ret = [];
            let nameDay = null;
            let hourStart = null;

            if(this.minDate && this.minDate.dateStartParse){
                const [dayAnt, monthAnt, yearAnt, hoursAnt, minutesAnt] = this.minDate.dateStartParse.split(/\/|\s|:/);
                const dateTime = new Date(yearAnt, monthAnt - 1, dayAnt, hoursAnt, minutesAnt);
                const minDateUnix = moment(dateTime);
                const dictDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
                nameDay = dictDays[dateTime.getDay()];
                if(minDateUnix.minute() <= 30){
                    minDateUnix.minute(30);
                }else{
                    minDateUnix.minute(0);
                    minDateUnix.add('hour', 1);
                }
                hourStart = minDateUnix.format('HH:mm');
            }

            this.openDays[this.type][day].forEach(schedule => {
                let scheduleStart = (day == nameDay && hourStart)? hourStart : schedule.start;
                ret = ret.concat(this.DayTimeOptionsInRange(scheduleStart, schedule.finish, minTime));
            });

            var seen = {};
            ret.sort((a, b) => {
                return a.minutes - b.minutes;
            });
            
            ret = ret.filter((el) => {
                return seen[el.minutes] ? false : (seen[el.minutes] = true);
            });

            return ret;
        },
        DayTimeOptionsInRange(start, finish, minTime)
        {
            var strStart = start.split(':');
            start = moment.unix(this.timestamp).hour(parseInt(strStart[0])).minute(parseInt(strStart[1]));

            var strFinish = finish.split(':');
            var finish = moment.unix(this.timestamp).hour(parseInt(strFinish[0])).minute(parseInt(strFinish[1]));

            var ret = [];

            while(start.isBefore(finish))
            {
                var next = moment(start).add(30, 'minutes');
                var mid = moment(start).add(15, 'minutes');

                
                if( (!this.disabledUntil || mid.unix() >= this.disabledUntil) && (!minTime || start.isSameOrAfter(minTime)) )
                {
                    ret.push({
                        value: mid.format('HH:mm'), 
                        label: `Entre ${this.Util.convertTimeAmPm(start.format('HH:mm'))} y ${this.Util.convertTimeAmPm(next.format('HH:mm'))}`,
                        minutes: mid.hours()*60 + mid.minutes() + 1
                    });
                }
                
                start = next;

            }
            return ret;
        },
        OpenModalSchedule()
        {
            this.modalOrderTime = {selectedTime: this.selectedTime, scheduleDay: this.scheduleDay, scheduleTime: this.scheduleTime};
        },
        OpenModalCustomer()
        {
            this.modalCustomer = this.previousUser ? this.Util.Copy(this.previousUser) : {name: '', email: '', phone: ''};
        },
        OpenModalPayMethod()
        {
            this.modalPayMethod = {method: this.selectedPaymentMethod, cash: this.cash};
        },
        OpenModalEnterCash(cash=null){

            this.modalEnterCash = { cash: cash };
        },
        OpenModalTip()
        {
            this.$refs.modalTip.Open(this.currentTip);
        },
        CheckCustomer(customer, checkSkipLine)
        {
            if(!customer.name || customer.name.length < 3) return {reason: 'Ingresa un nombre y apellido válido', redirect: this.OpenModalCustomer};
            var names = customer.name.split(' ');
            if(names.length <= 1 || names[0].length < 1 || names[1].length < 1) return {reason: 'Ingresa un nombre y apellido válido', redirect: this.OpenModalCustomer};
            if((this.type == 'SKIP_LINE' || (checkSkipLine && this.orderOptions.skip_line && this.openDays['TAKEAWAY'].openNow && this.mobile)) && !customer.phone && !customer.email) return false;
			var phone = !customer.phone ? '' : customer.phone.replace(/ /g, '').replace('+', '');
			if(phone.length < 8 || phone.length > 11) return {reason: 'Ingresa un teléfono válido', redirect: this.OpenModalCustomer};
			var email = !customer.email ? '' : customer.email.match(/.+@\w+\.\w+/g);
			if(!email) return {reason: 'Ingresa un email válido', redirect: this.OpenModalCustomer};
            return false;
        },
        SelectPayMethod(method)
        {
            if(method == 'NONE') return;
            if(method == 'CASH' && this.confirm_cash && this.modalPayMethod.cash < this.total.total) return this.Error("Debe ingresar cantidad mayor o igual en Efectivo para pagar.")
            this.selectedPaymentMethod = method;
            this.cash = method == 'CASH' ? this.modalPayMethod.cash : null; 
            this.modalPayMethod = null
            this.$refs.analytics.Trigger('add_payment_info', method);
        },
        EnterNewCash(){
            this.cash = this.modalEnterCash.cash
            this.modalEnterCash = null
        },
        SaveCustomer(customer)
        {
            localStorage.customer = JSON.stringify(customer);
            this.previousUser = customer;
            this.customerName = customer.name;
            this.customerPhone = customer.phone;
            this.customerEmail = customer.email;
            this.modalCustomer = null;
        },
        OpenModalMap()
        {
            //var data = {latitude: this.currentAddress ? this.currentAddress.lat : parseFloat(this.local.latitude), longitude: this.currentAddress ? this.currentAddress.lon : parseFloat(this.local.longitude)};
            //data.location = this.Util.Copy(this.currentAddress);
            var data = this.currentAddress ? this.Util.Copy(this.currentAddress) : {};
            data.latitude = this.currentAddress ? this.currentAddress.lat : parseFloat(this.local.latitude);
            data.longitude = this.currentAddress ? this.currentAddress.lon : parseFloat(this.local.longitude);
            this.modalMap = data;
        },
        SetAddress(address) 
        {
            this.currentAddress = address;
            localStorage.address = JSON.stringify(address);
        },
        SelectDay(option)
        {
            if(option == 'AUTO') 
                this.$set(this.modalOrderTime, 'selectedTime', 'AUTO');
            else 
            {
                this.$set(this.modalOrderTime, 'selectedTime', 'SCHEDULE');
                this.$set(this.modalOrderTime, 'scheduleDay', option);
            }
        },
        SetOrderTime(orderTime)
        {
            this.selectedTime = orderTime.selectedTime;
            this.scheduleDay = this.selectedTime == 'AUTO' ? 'NONE' : orderTime.scheduleDay;
            this.scheduleTime = this.selectedTime == 'AUTO' ? 'NONE' : orderTime.scheduleTime;
            this.modalOrderTime = null;

            if(this.shipmentType != 'AUTO' && this.currentTransaction && this.currentTransaction.status == 'CONFIRMATION'){
                this.$set(this.currentTransaction.scheduled, 'day', this.scheduleDay);
                this.$set(this.currentTransaction.scheduled, 'time', this.scheduleTime);
            }

            this.$refs.analytics.Trigger('add_shipping_info', this.selectedTime);

            this.CalculatePyaCourierDelivery();

        },
        CloseOrderMenu()
        {
            this.showRightMenu = false;
        },
        TransbankPayment()
        {
            this.loadingAction = 'ACCEPT';
            axios.get(`/transbank/${this.currentTransaction.code}`).then(res => {
                if(res.data.token)
                {
                    /*
                    <form v-if="!currentTransaction.id_flow" method="post" :action="currentTransaction.payment_url" class="flex flex-fill">
                    <input type="hidden" name="token_ws" :value="currentTransaction.flow_token" />
                    <input type="submit" value="Ir a pagar" class="flex-fill btn btn-hover" title="Ir al pago en linea" />
                    </form>
                    */
                    var form = document.createElement('form');
                    var token = document.createElement('input');
                    
                    form.method = 'POST';
                    form.action = res.data.url;

                    token.name = 'token_ws';
                    token.value = res.data.token;
                    token.type = 'hidden';
                    form.appendChild(token);

                    document.body.appendChild(form);
                    form.submit();

                }
                this.loadingAction = false;
            }).catch(err => {
                this.Error(err);
                this.loadingAction = false;
            });
        },
        SetTip(tip)
        {
            this.currentTip = tip;
        },
        CalculatePyaCourierDelivery()
        {
            //recalcular costo envio al cambia la fecha
            if(this.statusDeliveryPya && this.currentAddress && this.currentAddress.lat && this.currentAddress.lon && this.type == 'DELIVERY')
            {
                if(this.selectedTime == 'SCHEDULE' && this.scheduleDay == 'NONE' && this.scheduleTime == 'NONE') return;

                var time = '';
                if(this.selectedTime == 'SCHEDULE' && this.scheduleDay !== 'NONE' && this.scheduleTime !== 'NONE')
                    time = `?time=${this.scheduleTime} ${this.scheduleDay}`;

                let data = {
                    latitude: this.currentAddress.lat, 
                    longitude: this.currentAddress.lon
                }

                if(this.customerName) data.name = this.customerName;
                if(this.customerPhone) data.phone = this.customerPhone;
                if(this.customerEmail) data.email = this.customerEmail;

                axios.post(`/address/cost${time}`, data).then(res => {
                    if(res.data.enabled)
                    {
                        this.$set(this.currentAddress, 'cost', res.data.enabled.cost);
                        this.$set(this.currentAddress, 'discount', res.data.enabled.discount);
                    }
                    else
                    {
                        if(res.data.error && res.data.message) this.Error(res.data.message);
                    }
                }).catch(err => {
                    
                });
            }
        },
        restrictSchedule(schedulesAvailable, preparationTime){
            const currentTime = new Date();
            const restrictionTime = new Date(currentTime.getTime() + preparationTime * 60 * 60 * 1000);

            const restrictedSchedule = [];

            for (const schedule of schedulesAvailable) {
                const filteredTimes = !schedule.times
                ? []
                : schedule.times.filter(time => {
                    const dateTimeStr = `${schedule.value} ${time.value}`;
                    const [day, month, year, hours, minutes] = dateTimeStr.split(/\/|\s|:/);
                    const dateTime = new Date(year, month - 1, day, hours, minutes);
                    return dateTime >= restrictionTime;
                });

                if (filteredTimes.length > 0) {
                    restrictedSchedule.push({ ...schedule, times: filteredTimes });
                }
            }

            return restrictedSchedule;
        },
        validateAutoSchedule(minSchedule){
            axios.post(`/validate/schedule`, {minSchedule, type: this.type}).then(({data}) => {
                if(data){
                    const typeService = this.type == 'TAKEAWAY'? '_takeaway' : '';
                    if(data["shipment_start"+typeService]) this.schedules["shipment_start"+typeService] = data["shipment_start"+typeService];
                    if(data["shipment_end"+typeService]) this.schedules["shipment_end"+typeService] = data["shipment_end"+typeService];
                }
            }).catch( err => {
            });
        },
        SetCoupon(coupon) {
            this.coupon = coupon;
            this.$refs.analytics.Trigger('select_promotion', coupon.code);
        },
        OpenModalRecommendProducts(){
            this.recommendProducts = null;
            axios.post(`/order/relations`, { order: this.myOrder }).then(res => {
                let relations = res.data.products;
                let quantity_recommended = res.data.quantity_recommended
                let popular = res.data.top_items;
                let myOrderIDs = Object.keys(this.myOrder).map(item => item.split('-')[0]);;

                this.recommendProducts = [];

                if(this.arrayRecommendProducts == 0){

                    let typeKey = (this.type == 'DELIVERY') ? 'hide_delivery' : 'hide_takeaway';
                    let array_quantity_recommended = [];   

                    if(relations.length >= quantity_recommended){

                        relations.forEach(element => {
                            
                            let dictItem = this.dictItems[element.id];
                            if(dictItem && !dictItem[`section_${typeKey}`] && !dictItem.typeKey && !dictItem.unavailable && !(myOrderIDs.some(x => x == dictItem.id))){
                                array_quantity_recommended.push(dictItem)
                            } 
                        });
                        
                    }else{

                        let mergedArray = relations.concat(popular);
                        let sumArray = [];

                        mergedArray.forEach(element => {
                            let found = sumArray.some(item => item.id === element.id)
                            if(!found) {
                                sumArray.push(element);
                            }
                        });

                        sumArray.forEach(element => {
                            let dictItem = this.dictItems[element.id];
                            if(dictItem && !dictItem[`section_${typeKey}`] && !dictItem.typeKey && !dictItem.unavailable && !(myOrderIDs.some(x => x == dictItem.id))){
                                array_quantity_recommended.push(dictItem)
                            } 
                        });

                    }

                    this.recommendProducts = array_quantity_recommended.slice(0, quantity_recommended);

                /*
                    for (let key in this.myOrder) {
                        if (this.myOrder.hasOwnProperty(key)) {
                            let itemOrder = this.myOrder[key];
                            myOrderIDs[itemOrder.id] = true;
                            if(relations[itemOrder.title]){
                            
                                let arrRelation = Object.entries(relations[itemOrder.title]);
                                console.log(arrRelation);
                                arrRelation.forEach(([title, item]) => {
                                    let dictItem = this.dictItems[item.id];
                                    if(dictItem && !dictItem['section_' + typeKey] && !item[typeKey] && !item.unavailable && dictItem.id != itemOrder.id)
                                        this.$set(this.recommendProducts, title, item);
                                });

                            }
                        }
                    }

                    if(Object.keys(this.recommendProducts).length < 5){
                        for (let key in popular) {
                            let item = popular[key];

                            let dictItem = this.dictItems[item.id];
                            if(dictItem && !dictItem['section_' + typeKey] && !item['section_' + typeKey] && !item.unavailable && !myOrderIDs[dictItem.id])
                                this.$set(this.recommendProducts, key, item);

                            if(Object.keys(this.recommendProducts).length >= 5) break;
                        }
                    }

                    */
                }else{
  
                    relations.forEach(element => {
                        if(!(myOrderIDs.some(x => x == element.id))){
                            this.recommendProducts.push(element)
                        } 
                    });
                }
            }).catch( err => {
              
            });
        },
        CheckDuplicateOrders(order) {
            
            let getOrderKey = function(orderItems)
            {
                let keys = [];
                for(let key in orderItems) 
                {
                    let item = orderItems[key];
                    let itemKey = `${item.quantity}-${item.title}`;
                    item.modifiers.forEach(modifier => {
                        if(item.combo)
                        {
                            modifier.modifiers.forEach(subItemModifier => {
                                itemKey += `-${subItemModifier.item}-${subItemModifier.group}-${subItemModifier.title}`;       
                            });
                        }
                        else
                        {
                            itemKey += `-${modifier.group}-${modifier.title}`;
                        }
                    });
                    keys.push(itemKey);
                }
                return keys.sort().join('-');
            }

            let currentOrderKey = getOrderKey(order);

            let validStatus = ['CONFIRMATION', 'PENDING', 'WAITING', 'WAITING_PAYMENT', 'WAITING_BANK_DEPOSIT', 'ACCEPTED', 'PREPARING', 'TAKEAWAY_READY', 'SCHEDULED']
            for(let orderKey in this.activeOrders)
            {
                let activeOrder = this.activeOrders[orderKey];
                if(validStatus.indexOf(activeOrder.status))
                {
                    let activeOrderKey = getOrderKey(activeOrder.orders);

                    if(currentOrderKey == activeOrderKey)
                    {
                        this.Error([
                            `Tienes un pedido igual que en estado: ${this.Util.OrderStatus[activeOrder.status]}`,
                            {text: 'Ver Pedido Anterior', function: () => {this.OpenOrder(orderKey)}},
                            {text: 'Hacer Nuevo Pedido', function: () => {this.SendOrder(true)}}
                        ]);
                        return false;
                    }

                }
            }

            return true;

        },
        ConfirmLocation(fromCustomer){
            
            const successCallback = (position) => {
                this.distance.value = this.Util.Haversine(
                    position.coords.latitude,
                    position.coords.longitude,
                    parseFloat(this.local.latitude),
                    parseFloat(this.local.longitude),
                    'mt'
                );

                if(this.distance.value < 0 || this.distance.value > 25)
                {
                    this.Error('Estás muy lejos para "Saltarse la fila"');
                    return this.distance.msgError = 'Estás muy lejos del local';
                } 
                this.distance.msgError = null;
                this.type = 'SKIP_LINE';

                if(fromCustomer) {
                    this.SaveCustomer(this.modalCustomer);
                    this.modalSkipLine = null;
                }
            };

            const errorCallback = (error) => {
                this.distance.value = null;
                this.distance.msgError = 'Ubicación requerida';
                if(error.message == 'User denied Geolocation') this.distance.msgError = 'Active permiso de geolocalización';
                if(this.distance.msgError) this.Error(this.distance.msgError + ' para "Saltarse la fila"');
            };

            if (this.distance.restriction && (this.distance.value == null || this.distance.value < 0 || this.distance.value > 25)) {
                navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
                    enableHighAccuracy: true,
                    maximumAge: 0
                });
            }else {
                this.distance.msgError = null;
                this.type = 'SKIP_LINE';

                if(fromCustomer) {
                    this.SaveCustomer(this.modalCustomer);
                    this.modalSkipLine = null;
                }
            }
        },
        async ValidateStock(fullItem, order, item, quantity){
            if (quantity) {
                order[item.key].quantity = quantity;
                item = order[item.key];
            } else {
                order[item.key] = item;
            }

            const hasRequiredInventoryReferences = (references) => {
                return references.some(ref => ref.required);
            };

            let mainInventoryRequired = null;
            if(fullItem.inventory_references.length) mainInventoryRequired = hasRequiredInventoryReferences(fullItem.inventory_references);

            let subInventoryRequired  = null;
            if (item.combo) {
                subInventoryRequired = item.subitems.some(subitem =>
                    subitem.modifiers.some(modifier =>{
                        return modifier.inventory_references && hasRequiredInventoryReferences(modifier.inventory_references)
                    })
                );
            } else {
                if (item.modifiers.length > 0) {
                    subInventoryRequired = item.modifiers.some(modifier =>{
                        return modifier.inventory_references && hasRequiredInventoryReferences(modifier.inventory_references);
                    });
                }
            }

            if ((mainInventoryRequired || subInventoryRequired )) {
                const key = item.key;

                const newOrder = {};
                Object.keys(order).forEach(key => {
                    const item = order[key];
                    newOrder[key] = {
                        id: item.id,
                        title: item.title,
                        inventory_references: item.inventory_references || [],
                        float_quantity: item.float_quantity,
                        modifiers: item.modifiers,
                        quantity: item.quantity
                    };
                });

                try {
                    await axios.post(`/order/stock`, { order: newOrder });
                    this.loadingBottom = false;
                    return true;
                } catch (err) {
                    this.Error(err);
                    this.loadingBottom = false;
                    return false;
                }
            } else {
                this.loadingBottom = false;
                return true;
            }
        },
        LastDigitZero(event) { //TODO: Mover a util?
            let value = event.target.value;
            if (value && value[value.length - 1] !== '0') {
                value = value.slice(0, -1) + '0';
                event.target.value = value;
                if(this.modalPayMethod) this.modalPayMethod.cash = value;
                if(this.modalEnterCash) this.modalEnterCash.cash = value;
            }
        },
        OpenModalCoupon(){   
            this.$refs.modalCoupon.Open()
        },
        CheckIfItemIsUniquePerOrder(item) {
            if(!item.max_quantity_enabled || item.max_quantity !== 1) return null;
            for(let key in this.myOrder)
            {
                if(this.myOrder[key].id === item.id) return () => {
                    this.EditItem(this.myOrder[key], key);
                }
            }
            return null;
        }
    },
    computed: {
        isOpen() {
            if(!this.schedules) return null;
            if(this.ecommerceType == 'SHIPMENT') return true;
            if(this.ecommerceType == 'VIRTUAL_MENU') return true;

            if(this.disabledUntil && this.timestamp <= this.disabledUntil) return false;

            if(this.canSchedule) return true;

            var today = moment.unix(this.timestamp);
            var todayMins = (today.get('minutes')) + (today.get('hours') * 60);
            for(var i = 0; i < this.schedules.length; i++)
            {
                var schedule = this.schedules[i];
                if(schedule.day == this.today && (schedule.delivery || schedule.takeaway))
                {
                    var startMins = this.Util.TimeToMinutes(schedule.start);
                    var finishMins = this.Util.TimeToMinutes(schedule.finish);
                    if(todayMins >= startMins && todayMins <= finishMins) return true;
                }
            }

            return false;
        },
        isDisabled() {
            if(this.disabledUntil && this.timestamp) 
            {
                if(this.disabledUntil == 1) return {disabled: true, until: null, message: null};
                if(this.disabledUntil > this.timestamp) return {disabled: true, until: this.disabledUntil, message: null};
            }
            return null;
        },
        dayOptions() {
            //var ret = [{value: 'NONE', label: 'Seleccione el día'}];
            var ret = [];

            var tmpNow = moment.unix(this.timestamp);

            let now = this.minDate && this.minDate.anticipation? this.minDate.dateStart : tmpNow;
            var today = moment.unix(this.timestamp).startOf('day');
            now.startOf('day');

            if(this.ecommerceType == 'RESTAURANT' || (this.ecommerceType == 'SHIPMENT' && this.shipmentType !== 'AUTO'))
            {
                if(this.ecommerceType == 'RESTAURANT' && this.openDays[this.dictType[this.type]].openNow)
                    if(!this.minDate || !this.minDate.anticipation) ret.push({value: 'AUTO', label: 'Lo antes posible'});

                if(this.ecommerceType == 'SHIPMENT' && this.delay) now.add(this.delay, 'seconds');

                var count = 0;
                var totalCount = 0;
                let totalDays = this.statusDeliveryPya? 5 : 30;
                for(var i = 0; count < totalDays; i++)
                {
                    totalCount++;
                    now.locale('en');
                    var day = now.format('dddd').toLowerCase();
                    if(this.openDays[this.type][day].length)
                    {
                        now.locale('es');
                        var label = now.format('dddd D [de] MMMM'); 
                    
                        if(now.diff(today, 'days') == 0) label = 'Hoy';
                        else if(now.diff(today, 'days') == 1) label = 'Mañana';

                        var times = this.DayTimeOptions(now, this.ecommerceType == 'RESTAURANT' && label == 'Hoy');
                        
                        if(times.length){

                            count++;
    
                            ret.push({
                                value: now.format('DD/MM/YYYY'),
                                label: label,
                                times: times
                            });
                        }
                    }
                    now.add(1, 'day');
                    if(totalCount > 500) break;
                }
            }

            return ret;
        },
        total() {
            var subtotal = 0;
            var items = 0;
            var itemsList = [];
            for(var key in this.myOrder)
            {
                var item = this.myOrder[key];
                subtotal += item.total * item.quantity;
                items += item.quantity;

                if(item.float_quantity)
                    itemsList.push(item);
                else
                {
                    for(var i = 0; i < item.quantity; i++)
                    {
                        itemsList.push(item);
                    }
                }

            }

            subtotal = parseInt(subtotal);
            var deliveryCost = this.type == 'DELIVERY' && this.currentAddress ? this.currentAddress.cost : 0;

            //Selecciona la regla segun total
            if(this.deliveryConditionRules && this.type == 'DELIVERY'){
                const selected = this.deliveryConditionRules.filter(element => subtotal >= element.condition);
                let selectedCost = selected[selected.length - 1] || null
                
                if(selectedCost){
                    deliveryCost = selectedCost.cost;
                }
            }
            
            var discount = 0;
            var couponUsed = null;
            var discounts = [];
            var deliveryDiscount = 0;

            var maxDiscount = subtotal;

            //check si el item esta en el cupon
            var CheckItemInCoupon = (item) => {
                var couponItem = null;
                if(this.coupon.sections && this.coupon.sections[item.section]) couponItem = {include_modifiers: true};
                else
                {
                    couponItem = this.coupon.items.find(itemCoupon => {
                        return itemCoupon.id == item.id && itemCoupon.modifiers.every(modCoupon => {
                            return !!item.modifiers.find(modItem => {
                                return modItem.group == modCoupon.group && modItem.title == modCoupon.title;
                            });
                        });
                    });
                }
                return couponItem;
            }

            //checkear cupon de descuento
            if(this.coupon)
            {

                //TODO: schedule only if coupon is available always
                var day = this.selectedTime == 'SCHEDULE' ? (this.scheduleDay !== 'NONE' ? moment(this.scheduleDay, 'DD/MM/YYYY').format('dddd').toLowerCase() : 'none') : moment().format('dddd').toLowerCase();
                var time = this.Util.TimeToMinutes(moment.unix(this.timestamp).format('HH:mm'));
                var timeStart = this.Util.TimeToMinutes(this.coupon[day+'_start']);
                var timeFinish = this.Util.TimeToMinutes(this.coupon[day+'_finish']);
                
                var method = this.selectedPaymentMethod == 'BANK_DEPOSIT' ? 'Transferencia' : this.selectedPaymentMethod;
                if(subtotal >= this.coupon.minimun &&
                    (this.coupon.method == 'ANY' || this.coupon.method == method) &&
                    (this.coupon.sale == 'ANY' || this.coupon.sale == this.type) &&
                    !!this.coupon[day] && (time >= timeStart && time <= timeFinish)
                )
                {

                    var onTotal = this.coupon.discount.indexOf('ITEM') == -1;
                    var onAllSelectedItems = !onTotal && this.coupon.items_rule.indexOf('_SELECTION') > -1;

                    itemsList.sort((a, b) => {
                        return onAllSelectedItems ? (b.total - a.total) : (b.id == a.id ? b.total - a.total : a.id - b.id);
                    });

                    for (let key in this.coupon.sections) {
                        const [number, category] = key.split('-');
                        if(!category) break;
                        number = number.replace(/[{}]/g, '');
                        if(this.local.id == number) this.simplifiedData[category] =  this.coupon.sections[key]
                    }
                    if(Object.keys(this.simplifiedData).length > 0) this.coupon.sections = this.simplifiedData
                         
                    
                    if(onTotal)
                    {

                        var doesApply = true;
                        if((this.coupon.items && this.coupon.items.length) || this.coupon.sections)
                        {
                            if(this.coupon.items_rule == 'ALL')
                            {
                                for(var i = 0; i < itemsList.length; i++)
                                {
                                    var exists = CheckItemInCoupon(itemsList[i]);
                                    if(!exists)
                                    {
                                        doesApply = false;
                                        break;
                                    }
                                }
                            }
                            else if(this.coupon.items_rule == 'SOME')
                            {
                                doesApply = false;
                                for(var i = 0; i < itemsList.length; i++)
                                {
                                    var exists = CheckItemInCoupon(itemsList[i]);
                                    if(exists)
                                    {
                                        doesApply = true;
                                        break;
                                    }
                                }
                            }
                        }

                        if(doesApply)
                        {
                            switch(this.coupon.discount)
                            {
                                case '$':
                                    discount = this.coupon.value;
                                    break;
                                case '%':
                                    discount = parseInt((subtotal + (this.coupon.include_delivery ? deliveryCost : 0)) * this.coupon.value / 100);
                                    break;
                                case 'DELIVERY':
                                    discount = deliveryCost;
                            }
                            
                            maxDiscount = subtotal + (this.coupon.include_delivery ? deliveryCost : 0);
                            if(discount > maxDiscount) discount = maxDiscount;

                            couponUsed = this.coupon.id;
                            discounts.push({
                                title: `Código ${this.coupon.code}`,
                                discount: discount,
                                id_coupon_code: this.coupon.id_coupon_code
                            });
                        }
                    }
                    else
                    {
                        //descuento sobre items
                        var amount = 0;
                        var itemIndex = 0;
                        var lastItem = null;
                        for(var i = 0; i < itemsList.length; i++)
                        {
                            var doesApply = true;
                            var item = itemsList[i];

                            if(!!this.coupon.not_apply_discount && item.overwrite){
                                doesApply = false
                            }

                            if(!onAllSelectedItems)
                            {
                                itemIndex++;
                                
                                if(lastItem == null || lastItem.id !== item.id)
                                {
                                    lastItem = item;
                                    itemIndex = 1;
                                }
                            }

                            //check si el item esta en el cupon
                            var couponItem = null;
                            if(this.coupon.sections && this.coupon.sections[item.section]) couponItem = {include_modifiers: true};
                            else
                            {
                                couponItem = this.coupon.items.find(itemCoupon => {
                                    return itemCoupon.id == item.id && itemCoupon.modifiers.every(modCoupon => {
                                        return !!item.modifiers.find(modItem => {
                                            return modItem.group == modCoupon.group && modItem.title == modCoupon.title;
                                        });
                                    });
                                });
                            }

                            if(onAllSelectedItems && couponItem) itemIndex++;

                            if(this.coupon.items_rule.indexOf('INDEX') > -1)
                            {
                                var index = parseInt(this.coupon.items_rule.replace('INDEX_', '').replace('_SELECTION', ''));
                                if(itemIndex != index) doesApply = false;
                            }
                            else if(this.coupon.items_rule.indexOf('EVERY') > -1)
                            {
                                var index = parseInt(this.coupon.items_rule.replace('EVERY_', '').replace('_SELECTION', ''));
                                if(itemIndex % index != 0) doesApply = false;
                            }

                            if(couponItem && doesApply)
                            {
                                let ow_modifiers = [] 
                                item.modifiers.forEach(element => {if(element.overwrite) ow_modifiers.push(element)})
                                
                                var includeModifiers = !!couponItem.include_modifiers;
                                var modifiersTotal = 0;
                                item.modifiers.forEach(mod => modifiersTotal += mod.price);
                                var price = includeModifiers ? item.total : (item.total - modifiersTotal);

                                if(this.coupon.not_apply_discount && includeModifiers){
                                    let ow_price = 0
                                    ow_modifiers.forEach(element => {
                                        ow_price += element.price 
                                    });
                                    price = price - ow_price
                                }
                                if(this.coupon.discount == 'ITEM_%')
                                {
                                    amount += parseInt(price * this.coupon.value / 100);
                                }
                                else if(this.coupon.discount == 'ITEM_MINUS')
                                {
                                    amount += this.coupon.value;
                                }
                                else if(this.coupon.discount == 'ITEM_$')
                                {
                                    amount += Math.max(0, price - this.coupon.value);
                                }
                            }
                        }

                        if(amount > 0)
                        {
                            discount = amount;
                            couponUsed = this.coupon.id;
                            discounts.push({
                                title: `Código ${this.coupon.code}`,
                                discount: discount,
                                id_coupon_code: this.coupon.id_coupon_code
                            });
                        }
                    }
                }
            }

            if(discount > maxDiscount) discount = maxDiscount;

            //Descuento de reparto PYA
            if(deliveryCost && (this.currentAddress && this.currentAddress.discount))
            {
                discount += this.currentAddress.discount;
                discounts.push({
                    title: `Descuento Costo de Reparto`,
                    discount: this.currentAddress.discount,
                    id_coupon_code: null,
                    type: 'DELIVERY_DISCOUNT'
                });
                deliveryDiscount = this.currentAddress.discount;
            }

            var tip = 0;
            if(this.tipsEnabled)
            {
                if(this.defaultTip)
                {
                    tip = parseInt(subtotal * this.defaultTip / 100);
                }
                else
                {
                    tip = parseInt(this.currentTip);
                    if(!tip || tip < 0) tip = 0;
                }
            }

            //cerrar modal si se quitan todos los items del pedido en el resumen
            if(this.currentTransaction && !this.currentTransaction.id && subtotal <= 0) this.currentTransaction = null;
            
            let round = 0;
            if(this.selectedPaymentMethod == 'CASH')
            {
                let total = subtotal + deliveryCost - discount + tip;
                if(total > 0)
                {
                    var strTotal = total.toString();
                    var number = parseInt(strTotal[strTotal.length-1]);
                    round = number <= 5 ? -number : 10-number;
                }
            }

            return {
                items: items,
                order: subtotal,
                delivery: deliveryCost,
                total: subtotal + deliveryCost + round - discount + tip,
                discount: discount,
                benefit: 'none',
                id_benefit: null,
                round: round,
                couponUsed: couponUsed,
                discounts: discounts,
                tip: tip,
                deliveryDiscount: deliveryDiscount
            }
        },
		canOrder() {
			if(this.total.items <= 0) return {reason: 'Debes agregar por lo menos 1 ítem'};
            if(this.total.order <= 0) return {reason: 'El costo total del pedido no puede ser $0'};
			
            var checkCustomer = this.CheckCustomer({name: this.customerName, phone: this.customerPhone, email: this.customerEmail});
            if(checkCustomer) return checkCustomer;

			if(this.type == 'DELIVERY' && !this.currentAddress) return {reason: 'Ingresa una dirección válida', redirect: this.OpenModalMap};
            if(this.type == 'DELIVERY' && this.total.order < this.minimun) return {reason: `El mínimo para repartos es $${Util.Number(this.minimun)}`};
            if(this.ecommerceType !== 'SHIPMENT' && this.selectedTime == 'SCHEDULE')
            {
                if(!this.scheduleDay || this.scheduleDay == 'NONE') return {reason: 'Selecciona un día válido', redirect: this.OpenModalSchedule};
                if(!this.scheduleTime || this.scheduleTime == 'NONE') return {reason: 'Selecciona una hora válida', redirect: this.OpenModalSchedule};
            }
            if(this.availablePaymentMethods.indexOf(this.selectedPaymentMethod) < 0) return {reason: 'Selecciona un método de pago válido', redirect: this.OpenModalPayMethod};
            /*
            if(this.currentTip && this.currentTip > 0)
            {
                var min = parseInt(this.total.order * 0.1);
                if(this.currentTip < min) return {reason: 'La propina debe ser mínimo '+this.Util.Price(min), redirect: this.OpenModalTip};
            }
            */
            if(this.ecommerceType == 'SHIPMENT')
            {
                if(this.shipmentType !== 'AUTO' && this.scheduleDay == 'NONE') return {reason: 'Ingresa una día válido', redirect: this.OpenModalSchedule};
                if(this.shipmentType == 'MANUAL_DATETIME' && this.scheduleTime == 'NONE') return {reason: 'Ingresa una hora válida', redirect: this.OpenModalSchedule};
            }

            if(this.ecommerceType == 'RESTAURANT' && this.selectedTime == 'AUTO' && !this.openDays[this.dictType[this.type]].openNow)
                return {reason: `No se puede hacer pedidos ${this.type == 'DELIVERY' ? 'Delivery' : 'para Retirar'} ahora`};

			return true;
		},
        transactionStatus() {
            if(!this.currentTransaction || this.currentTransaction.status == 'CONFIRMATION') return null;
            return this.currentTransaction.status;
        },
        editScheduleDay() {
            if(!this.modalOrderTime) return 'NONE';
            return this.modalOrderTime.scheduleDay ? this.modalOrderTime.scheduleDay : 'NONE';
        },
        statusMessage() {
            if(!this.currentTransaction) return '';
            var status = this.currentTransaction.status;
            let shipmentTakeaway = this.currentTransaction.custom_data.shipment_takeaway;
            if(status == 'ACCEPTED') status = 'PREPARING';
            if((this.currentTransaction.type == 'SHIPMENT') && status == 'PREPARING') status = 'SHIPMENT';
            if(shipmentTakeaway && status == 'PREPARING') status = 'SHIPMENT_TAKEAWAY';
            var mapStatus = {
                PENDING: 'status_order_pending',
                WAITING: 'status_order_confirmation',
                WAITING_PAYMENT: 'status_order_payment',
                WAITING_BANK_DEPOSIT: 'status_order_bank',
                PREPARING: 'status_order_preparing',
                SHIPMENT: 'status_order_shipment',
                SHIPMENT_TAKEAWAY: 'status_order_shipment_takeaway',
                DELIVERY: 'status_order_delivery',
                TAKEAWAY_READY: 'status_order_takeaway',
                COMPLETED: 'status_order_completed',
                REJECTED: 'status_order_cancelled',
                SCHEDULED: 'status_order_scheduled',
            }

            var msg = mapStatus[status] ? this.customization[mapStatus[status]] : '';

            if(this.currentTransaction.custom_data.skip_line && status == 'PREPARING') msg = `El pedido #${this.currentTransaction.custom_data.id_ticket} estará listo pronto`;

            if(this.currentTransaction.custom_data.skip_line && status == 'TAKEAWAY_READY') msg = `¡El pedido #${this.currentTransaction.custom_data.id_ticket} está listo para ser retirado!`;
            
            if(this.currentTransaction.custom_data.pos_discount && ['COMPLETED', 'REJECTED', 'DELIVERY', 'TAKEAWAY_READY'].indexOf(status) == -1)
                msg += `\n${this.customization.pos_discount}`;

            var t = this.currentTransaction;

            let ETA = '';

            if(t.messages && typeof t.messages !== 'string'){
                //Verificar si la hora es shipment-delivery o shipment-takeaway
                const shipmentStart = t.custom_data.shipment_takeaway
                    ? t.messages.shipment_start_takeaway
                    : t.messages.shipment_start;
                const shipmentEnd = t.custom_data.shipment_takeaway
                    ? t.messages.shipment_end_takeaway
                    : t.messages.shipment_end;
                
                //Validar que tiene la hora
                if (shipmentStart) {
                    if (shipmentEnd) {
                        ETA = `entre el ${this.Util.Date(shipmentStart, 'D [de] MMMM')} y ${this.Util.Date(shipmentEnd, 'D [de] MMMM')}`;
                    } else {
                        ETA = `el ${this.Util.Date(shipmentStart, 'D [de] MMMM')}${t.messages.time ? ' a las ' + t.messages.time : ''}`;
                    }
                }
            }

            let tokenTime = '';
            if(t.takeaway_time && t.takeaway_time !== 'SCHEDULE'){
                if(t.takeaway_time == 'AUTO'){
                    tokenTime = 'Lo antes posible';
                }else{
                    tokenTime = `${t.takeaway_time} hrs`;
                }
            }

            var mapToken = {
                '{TOTAL}': t.total.total,
                '{WAIT}': t.messages && typeof t.messages !== 'string' && t.messages.TIME ? `${t.messages.TIME} hrs` : '',
                '{TIME}': !t.custom_data.skip_line? tokenTime : '',
                '{DATE}': t.scheduled_at ? this.Util.Date(t.scheduled_at, 'dddd D [de] MMMM [a las] HH:mm [hrs]') : '',
                '{ETA}': ETA,
                '{NAME}': t.customer_name,
                '{ADDRESS}': t.address,
                '{LOCAL}': t.local.name,
                '{LOCAL_ADDRESS}': t.local.address,
                '{REASON}': t.messages && typeof t.messages == 'string' ? `Motivo: ${this.Util.CancelledReason(t.messages)}` : '',
                '{POS_DISCOUNT}': t.custom_data && t.custom_data.pos_discount ? this.Util.Price(t.custom_data.pos_discount) : '',
                '{METHOD}': this.Util.PayMethod(t.payment_method)
            }
            for(var key in mapToken)
            {
                var re = new RegExp(key);
                msg = msg.replace(re, mapToken[key]);
            }

            var lines = msg.split(/\n/g);

            if(status == 'SHIPMENT' && t.messages.no_shipment_time) lines = [lines[0], 'Te notificaremos la fecha de despacho'];

            msg = '';
            for(var i = 0; i < lines.length; i++)
                msg += `<div class="c1 ${i == 0 ? 's2' : 's3'} ta-center" style="margin-top: 20px">${lines[i]}</div>`;

            return msg;
        },
        openDays() {
            var ret = {
                general: {monday: false, tuesday: false, wednesday: false, thursday: false, friday: false, saturday: false, sunday: false},
                DELIVERY: {monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: [], openNow: false},
                TAKEAWAY: {monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: [], openNow: false},
                SKIP_LINE: {monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [], sunday: [], openNow: false},
                same: true,
            };
            if(!this.schedules) return ret;

            if(this.ecommerceType == 'RESTAURANT')
            {

                var today = moment.unix(this.timestamp);
                var todayMins = (today.get('minutes')) + (today.get('hours') * 60);

                var isSameSchedule = true;    
                this.schedules.forEach(schedule => {
                    ret.general[schedule.day] = true;
                    if(schedule.delivery) ret.DELIVERY[schedule.day].push(schedule);
                    if(schedule.takeaway) ret.TAKEAWAY[schedule.day].push(schedule);
                    if(schedule.delivery != schedule.takeaway) isSameSchedule = false;
                    
                    if(schedule.day == this.today)
                    {
                        var startMins = this.Util.TimeToMinutes(schedule.start);
                        var finishMins = this.Util.TimeToMinutes(schedule.finish);
                        if(todayMins >= startMins && todayMins <= finishMins)
                        {
                            if(schedule.delivery) ret.DELIVERY.openNow = true;
                            if(schedule.takeaway) ret.TAKEAWAY.openNow = true;
                        }
                    }
                });
                ret.same = isSameSchedule;
                return ret;
            }
            else if(this.ecommerceType == 'SHIPMENT' && this.shipmentType !== 'AUTO'){
                let today = moment.unix(this.timestamp);
                let todayMins = (today.get('minutes')) + (today.get('hours') * 60);
                let isSameSchedule = true;
                this.schedules.forEach(schedule => {
                    ret.general[schedule.day] = true;
                    if(schedule.delivery) ret.DELIVERY[schedule.day].push(schedule);
                    if(schedule.takeaway) ret.TAKEAWAY[schedule.day].push(schedule);
                    if(schedule.delivery != schedule.takeaway) isSameSchedule = false;

                    if(schedule.day == this.today){
                        let startMins = this.Util.TimeToMinutes(schedule.start);
                        let finishMins = this.Util.TimeToMinutes(schedule.finish);
                        if(todayMins >= startMins && todayMins <= finishMins){
                            if(schedule.delivery) ret.DELIVERY.openNow = true;
                            if(schedule.takeaway) ret.TAKEAWAY.openNow = true;
                        }
                    }
                });
                ret.same = isSameSchedule;
                return ret;
                /* this.schedules.schedule.forEach(schedule => {
                    ret.general[schedule.day] = true;
                    ret.DELIVERY[schedule.day].push(schedule);
                });
                return ret; */
            }
            else if(this.ecommerceType == 'SHIPMENT' && this.shipmentType == 'AUTO'){
                if(!this.schedules.shipment_start_takeaway && !this.schedules.shipment_start){
                    this.schedules = false; 
                    // this.type = null;
                }else if(!this.schedules.shipment_start_takeaway){
                    this.orderOptions.takeaway = false; 
                    this.type = 'DELIVERY';
                }else if(!this.schedules.shipment_start){
                    this.orderOptions.delivery = false; 
                    this.type = 'TAKEAWAY';
                }
            }
            return ret;
        },
        availablePaymentMethods() {
            if(!this.paymentMethods) return [];
            const validType = this.dictType[this.type];
            var ret = [];
            this.paymentMethods.forEach(method => {
                if((validType == 'DELIVERY' && method.delivery) || (validType == 'TAKEAWAY' &&  method.takeaway))
                    ret.push(method.method);
            });
            return ret;
        },
        activeOrdersCount() {
            return Object.keys(this.activeOrders).length;
        },
        filteredMenu(){
            if(!this.menu) return;
            if(!this.type) return this.menu;

            let ret = Util.Copy(this.menu);

            let sectionTopItems = {
                items: this.topItems,
                title: 'Más Vendidos',
                hideOnSearch: true
            };
            ret.unshift(sectionTopItems);

            if(this.anticipationFilter){
                ret = ret.map(section => ({
                    ...section,
                    items: section.items.filter(item => item.anticipation && item.anticipation_time != null)
                }));
            }

            const typeKey = (this.type == 'DELIVERY') ? 'hide_delivery' : 'hide_takeaway';

            const filteredSections = ret.filter(section => !section[typeKey]);
            filteredSections.forEach(section => {
                section.items = section.items.filter(item => {
                    if (item.type == 0) {
                        return !item[typeKey];
                    } else if (item.type == 1) {
                        let countSubitems = item.products;
                        item.products = item.products.filter(subitem => {
                            let tmpSubitem = this.dictItems[subitem.id];
                            if(!tmpSubitem) return false;
                            return !(tmpSubitem['section_' + typeKey]) && !subitem[typeKey];
                        });
                        return item.products.length == countSubitems.length;
                    }
                });
            });

            return filteredSections;
        },
        myFilteredOrder(){
            if(!this.myOrder) return;
            if(!this.type) return this.myOrder;

            let items = Object.values(this.myOrder);

            let deletedItems = "";

            const typeKey = (this.type == 'DELIVERY') ? 'hide_delivery' : 'hide_takeaway';

            items.forEach( item => {
                if(item.combo){
                    let deleteCombo = false;
                    item.subitems.forEach(subitem => {
                        let tmpSubitem = this.dictItems[subitem.item.id];
                        if (!tmpSubitem){
                            deleteCombo = true;
                            return;
                        }
                        if (tmpSubitem['section_' + typeKey] || (!tmpSubitem['section_' + typeKey] && tmpSubitem[typeKey])) deleteCombo = true;
                    });
                    if(deleteCombo){
                        deletedItems += `<li>${item.title}</li>`;
                        this.$delete(this.myOrder, item.key);
                    }
                }else{
                    if (item['section_' + typeKey] || (!item['section_' + typeKey] && item[typeKey])) {
                        deletedItems += `<li>${item.title}</li>`;
                        this.$delete(this.myOrder, item.key);
                    }
                }
            });

            if(deletedItems != "") this.Error(`<div class='mb10 bold ta-center'>Se quitaron items no disponibles en ${this.OrderType[this.type]}</div><ul>${deletedItems}</ul>`);

            return this.myOrder;
        },
        minDate(){
            if(!this.myOrder) return;
            if(!Object.values(this.myOrder).length) return;

            let maxAnticipationTime = 0;
            let tmpDateStart = null;
            let dateStart = null;

            let anticipation = false;
            let countHaveAnticipation = 0;
            if(Object.values(this.myOrder).length){

                for (let key in this.myOrder) {
                    if (this.myOrder.hasOwnProperty(key)) {
                        const item = this.myOrder[key];

                        if(item.anticipation){
                            anticipation = true;
                            countHaveAnticipation++;
                        }

                        if (item.anticipation_time > maxAnticipationTime) maxAnticipationTime = item.anticipation_time;
                    }
                }
                tmpDateStart = moment.unix(this.timestamp).add(maxAnticipationTime, 'hours');
                dateStart = moment(tmpDateStart);
            }

            return {
                'anticipation': anticipation,
                'dateStart' : tmpDateStart,
                'maxAnticipationTime' : anticipation? maxAnticipationTime : null,
                'dateStartParse' : anticipation && dateStart? dateStart.format('DD/MM/YYYY HH:mm') : null
            };
        },
        //para ver si existen items con reserva y mostrar/ocultar el filtro
        itemsWithAnticipation() {
            let ret = [];
            for(let key in this.dictItems)
            {
                if(this.dictItems[key].anticipation && this.dictItems[key].anticipation_time > 0) 
                    ret.push(this.dictItems[key]);
            }
            return ret;
        },
        orderByIdQuantity(){
            if(!this.myOrder || !Object.keys(this.myOrder).length) return;
			const groupedData = {};

            for(let index in this.myOrder){
                if(this.myOrder.hasOwnProperty(index)){
                    const tmpItem = this.myOrder[index];
                    const id = tmpItem.id;
                    const quantity = tmpItem.quantity;

                    if (!groupedData[id]) {
                        groupedData[id] = 0;
                    }
                    
                    groupedData[id] += quantity;
                }
            }

            return groupedData;
        },
        CheckAdditionalPaymentData(){
            let dataMethod = this.paymentMethods.find(element => element.method == this.currentTransaction.payment_method)
            if(dataMethod) {
                if(!dataMethod.payment_data) return false
                let regex = /whatsapp\s*(\+569\d{8})/i;
                let result = dataMethod.payment_data.match(regex);
                if(result) dataMethod.whatsapp = result[1]               
                this.additionalPaymentData = dataMethod
                return dataMethod
            }
            return false
        },
        CheckConfirmPayMethod(){
            let methodHasConfirm = this.paymentMethods.find(element => element.method == this.currentTransaction.payment_method)
            if(methodHasConfirm && methodHasConfirm.confirm_pay) return true
            return false
        },
        wsConnectionQuery() {
            if(this.loading !== false) return null;
            if(!this.ordersCodes.length) return null;
            return this.ordersCodes.join(',');
        },
    },
    watch: {
        searchAddress: function(n, o) {
            if(this.timerSearchAddress) clearTimeout(this.timerSearchAddress);
            if(n.length < 5)
            {
                this.timerSearchAddress = null;
                return;
            }
            
            this.timerSearchAddress = setTimeout(() => {
                axios.post(`/addresses`, { search: this.searchAddress}).then(res => {
             
                    this.addresses = res.data;
                    this.showAddress = true;
                    this.timerSearchAddress = null;
                }).catch(err => {
                
                });
            }, 3000);
            
        },
        editScheduleDay: function(n, o) {
            if(n && n != 'NONE')
            {
                var day = moment(n, 'DD/MM/YYYY');
                var isToday = n == moment().format('DD/MM/YYYY');
                moment.locale('en');
                this.dayTimeOptions = this.DayTimeOptions(day, isToday);

                if(this.dayTimeOptions.length && this.modalOrderTime.scheduleTime !== 'NONE')
                {
                    var selected = Util.TimeToMinutes(this.modalOrderTime.scheduleTime);
                    var first = Util.TimeToMinutes(this.dayTimeOptions[0].value);
                    var last = Util.TimeToMinutes(this.dayTimeOptions[this.dayTimeOptions.length-1].value);
                    if(selected < first)
                        this.$set(this.modalOrderTime, 'scheduleTime', this.dayTimeOptions[0].value);
                    else if(selected > last)
                        this.$set(this.modalOrderTime, 'scheduleTime', this.dayTimeOptions[this.dayTimeOptions.length-1].value);
                }

                //this.dayTimeOptions.unshift({value: 'NONE', label: 'Seleccione la hora'});
                if(this.modalOrderTime.scheduleTime == 'NONE' && this.dayTimeOptions.length)
                    this.$set(this.modalOrderTime, 'scheduleTime', this.dayTimeOptions[0].value);
            }
        },
        currentTransaction: function(n, o)
        {
            if(!n && o)
            {
                if(o.status == 'REJECTED' || o.status == 'COMPLETED' || o.status == 'ONLINE_PAYMENT_ERROR')
                {
                    this.Finish(o.code);
                }
            }

            
        },
        isOpen: function(n, o) 
        {
            if(n && (!n.open || !n.available))
            {
                if(this.accepts_scheduled) this.SetOrderTime({selectedTime: 'SCHEDULE', scheduleDay: 'NONE', scheduleTime: 'NONE'});
                    //this.selectedTime = 'SCHEDULE';
            }
        },
        showRightMenu: function(n, o) 
        {
            if(n)
            {
                window.history.pushState('menu-open', null, '');
                window.onpopstate = this.CloseOrderMenu;
            }
            else
            {
                if(history.state == 'menu-open')  {
                    window.history.back();
                }
            }
        },
        type: function(n, o)
        {
            if(this.selectedTime == 'AUTO')
            {
                if(!this.dayOptions.length || this.dayOptions[0].value !== 'AUTO')
                {
                    if(this.canSchedule) this.SetOrderTime({selectedTime: 'SCHEDULE', scheduleDay: 'NONE', scheduleTime: 'NONE'});
                    //this.selectedTime = 'SCHEDULE';
                }
                
                if(this.type == 'SKIP_LINE'){
                    this.SetOrderTime({selectedTime: 'AUTO'});
                }
            }
            else if(this.selectedTime == 'SCHEDULE' && this.scheduleDay == 'NONE' && this.scheduleTime == 'NONE')
            {
                if(this.dayOptions.length && this.dayOptions[0].value == 'AUTO')
                {
                    this.SetOrderTime({selectedTime: 'AUTO'});
                    //this.selectedTime = 'AUTO';
                }
            }
            else if(this.scheduleDay !== 'NONE' && this.scheduleTime !== 'NONE')
            {
                var day = this.dayOptions.find((el) => {return el.value == this.scheduleDay});
                var time = day ? (day.times.find((el) => {return el.value == this.scheduleTime})) : null;
                if(!day || !time)
                {
                    this.modalError = `El horario ${this.scheduleDay} ${this.scheduleTime} no está disponible para pedidos ${n == 'DELIVERY' ? 'Delivery' : 'para Retirar'}`;
                    this.scheduleDay = 'NONE';
                    this.scheduleTime = 'NONE';
                }
            }
            /*
            if(this.selectedPaymentMethod !== 'NONE')
            {
                if(this.availablePaymentMethods.indexOf(this.selectedPaymentMethod) == -1)
                    this.selectedPaymentMethod = 'NONE';
            }
            */
           
            if(n == 'DELIVERY') this.CalculatePyaCourierDelivery();

        },
        activeOrders: function(n, o)
        {
            for(var code in n)
            {
                if(!o[code] || n[code].status !== o[code].status)
                    this.audioBell.play();
            }
            if(this.currentTransaction)
            {
                if(n[this.currentTransaction.code])
                    this.currentTransaction = n[this.currentTransaction.code];
                else if(this.currentTransaction.code)
                    this.currentTransaction = null;
            }
            
        },
        wsConnectionQuery: function(n, o)
        {
            if(n)
            {
                this.$socket.client.disconnect();
                this.$socket.client.io.opts.query = {codes: n};
				this.$socket.client.io.uri = this.Util.ws;
				this.$socket.client.connect();
            }
            else
            {
                this.$socket.client.disconnect();
            }
        }
    },
    sockets: {
		connect() {
			this.UpdateOrders();
		},
        UPDATE_ORDERS() {
            this.UpdateOrders();
        }
	},
}
</script>

<style lang="scss">
a {
    text-decoration: none;
}
button {
    font-family: inherit;
    font-size: inherit;
    border: none;

    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
        &:hover {
            transform: none !important;
        }
    }

}
.unshrink {
    flex-shrink: 0;
}

.left-menu {
    width: 250px;
    height: 100%;
    overflow-y: auto;
    flex-shrink: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}
.center-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 330px;
    margin-left: 250px;
}
.right-menu {
    width: 330px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;
    padding: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
}

.menu-section {
    font-weight: 500;
    color: #555;
    font-size: 18px;
    margin-bottom: 15px;
}

/* Side Menu */
.local-title {
    margin-top: 10px;
    line-height: 1;
    font-size: 16px;
    color: black;
    text-align: center;
    font-weight: bold;
    padding-bottom: 15px;
    border-bottom: 1px solid #EEE;
}

.btn-nav {
    font-size: 14px;
    font-weight: 700;
    margin-top: 25px;
    color: black;
}
.btn-nav.sub {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    color: #AAA;
    user-select: none;
    cursor: pointer;
    transition: all 0.1s linear;
}
.btn-nav.sub:hover {
    margin-left: 3px;
    color: #4c77ff;
}
.btn-nav.selected {
    color: #4c77ff;
}

.credits-label {
    font-size: 10px;
    color: #666;
    padding-top: 15px;
    border-top: 1px solid #EEE;
}

/* Menu Item */
.c-item-card {
    display: grid;
}
.menu-items-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.menu-item {
    width: 100%;
    background: white;
    border-radius: 5px;
    display: flex;
    overflow: hidden;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    /*height: 150px;
    cursor: pointer;*/
    transition: all 0.2s linear;
}
/*
.menu-item:not(.unavailable):hover {
    box-shadow: 0px 10px 15px 0px rgba(0,0,0,0.2);
    transform: scale(1.02);
}
*/
.menu-item.unavailable {
    cursor: default;
    opacity: 0.5;
}
.menu-item .info {
    padding: 10px 20px;
    min-height: 140px;
}
.menu-item .title {
    font-size: 1
    
    8px;
    font-weight: 900;
}
span.unavailable {
    color: #AAA;
    font-weight: 400;
    margin-left: 5px;
}
.menu-item .description {
    font-size: 12px;
    color: #888;
    margin-top: 10px;
    text-align: justify;
    line-height: 16px;
}
.menu-item .price {
    font-weight: 900;
    letter-spacing: -1px;
    font-size: 14px;
}
.menu-item .item-modifiers {
    padding: 20px;
    /*border-top: 1px solid rgba(0,0,0,0.025);*/
    background-color: rgba(0,0,0,0.01);
}
.item-modifiers .modifiers-group {
    font-size: 20px;
    margin-bottom: 10px;
    color: black;
    font-weight: bold;
}
.modifiers-group .modifier {
    color: #888;
    font-size: 18px;
    margin-top: 5px;
    margin-left: 5px;
    cursor: pointer;
    user-select: none;
    transition: all 0.1s linear;
    font-weight: normal;
}
.modifiers-group .modifier:hover {
    transform: translateX(3px);
}
.group-description {
    color: #4c77ff;
}
.group-description.attention {
    color: #ff4c79;
}
.modifier i {
    margin-right: 10px;
    transform: scale(1.4);
}
.modifier .price {
    margin-left: 5px;
}

.modifiers-actions {
    position: absolute; 
    display: flex;
    justify-content: flex-end; 
    bottom: 20px; 
    right: 20px;
}
.btn-add-order {
    border: 1px solid #DDD;
    border-radius: 2px;
    font-weight: 900;
    padding: 10px;
    font-size: 12px;
    background-color: white;
    cursor: pointer;
    transition: all 0.1s linear;
    user-select: none;
    margin-top: 5px;
}
.btn-add-order i {
    display: inline-block;
    margin-left: 8px;
    transform: scale(1.5);
}
.btn-add-order:not(.disabled):hover {
    background-color: #DDD;
}
.btn-add-order.disabled {
    opacity: 0.5;
    cursor: default;
}

.quantity {
    border: 1px solid #DDD;
    border-radius: 2px;
    font-weight: 900;
    padding: 5px 0px;
    font-size: 12px;
    background-color: white;
}
.quantity div {
    width: 20px;
    text-align: center;
}
.quantity i.icon-minus:hover {
    transform: scale(1.7);
    color: #ff4c79;
}
.quantity.selectable {
    cursor: pointer;
    transition: transform 0.1s linear;
}
.quantity.selectable:hover {
    border-color: #4c77ff;
    transform: scale(1.2);
}

.overlay {
    background-color: rgba(0,0,0,0.075);
}

/* Order item */

.order-title {
    margin-bottom: 15px;
    color: black;
    font-weight: 400;
    font-size: 18px;
    margin-top: 15px;
}
.order-title:first-child {
    margin-top: 0px;
}

.order-item {
    display: flex;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.order-item img {
    border-radius: 5px;
}

.order-item .title {
    font-weight: 500;
    font-size: 15px;
}
.order-item .total {
    font-weight: 900;
    letter-spacing: -1px;
    font-size: 12px;
}
.order-item .modifier {
    font-size: 10px;
    color: #666;
}

.summary {
    color: black;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 10px;
}
.summary.sub {
    font-size: 14px;
    margin-top: 0px;
    font-weight: 400;
}

.btn-checkout {
    background-color: #111;
    color: white;
    font-weight: 700;
    font-size: 14px;
    padding: 15px;
    border-radius: 3px;
    text-align: center;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
    position: sticky;
    bottom: 5px;
    right: 15px;
    left: 15px;
    user-select: none;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.btn-checkout:not(.disabled):hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.3);
}
.btn-checkout.disabled {
	cursor: default;
	background-color: #999;	
}
.semi-disabled {
	opacity: 0.75 !important;
}

/* Text box*/
.textbox {
    border: 1px solid #DDD;
    border-radius: 2px;
    padding: 8px;
    margin-bottom: 5px;
    position: relative;
}
.textbox label{
    font-size: 10px;
    font-weight: 700;
    display: block;
}

.textbox:hover {
    border: 1px solid #4c77ff;
}
.textbox option {
    color: black;
}
.textbox input::placeholder {
    color: #CCC;
    font-style: italic;
}

/*options*/
.options {
    border: 1px solid #DDD;
    border-radius: 2px;
    margin-bottom: 10px;
}
.options div {
    flex: 1 1 auto;
    text-align: center;
    font-weight: 700;
    padding: 10px;
    border-radius: 2px;
    font-size: 14px;
    user-select: none;
    transition: all 0.1s linear;
}
.options div:not(.selected) {
    cursor: pointer;
}
.options div:not(.selected):hover {
    background-color: #DDD;
}
.options .selected {
    background-color: #111;
    color: white;
}

.radio {
    display: flex;
    font-size: 14px;
}
.radio i {
    margin-right: 5px;
    font-size: 16px;
}

.ig-container {
    width: 100%;
    display: flex;
    overflow-x: auto;
    padding: 10px;
}
.ig-container a {
    display: inline-block;
    margin-right: 10px;
}
.ig-container img {
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.1);
    cursor: pointer;
    transition: all 0.2s linear;
}
.ig-container img:hover {
    transform: rotate(-2deg) scale(1.05);
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.3);
}
@media(max-width: 1240px)
{
    .ig-container::after {
        content: '';
        min-width: 1px;
    }
}
@media(min-width: 1240px) {
    .ig-container {
        flex-wrap: wrap;
        margin-bottom: -20px;
    }
    .ig-container a {
        flex: 0 0 calc(25% - 20px);
        margin: 10px;
    }
    .ig-container a img {
        width: 100%;
    }
}

.map-img {
    height: 300px;
    min-height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.1);
    margin-bottom: 15px;
}
.map-img .msg {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    border-radius: 5px;
    padding: 8px;
    font-size: 12px;
    color: #777;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

/*
.profile {
    overflow-y: auto;
}
*/
.profile .banner {
    background-size: cover;
    background-position: center;
    padding: 30px;
    position: relative;
}
.banner-full {
    background-size: cover;
    background-position: center;
    padding: 30px;
    min-height: 255px;
    width: 100%;
    position: relative;
}
.banner::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(79, 93, 102,0.5);
}
.banner * {
    position: relative;
}

.profile .title {
    color: white;
    text-align: center;
    font-weight: 900;
    font-size: 28px;
    margin-bottom: 50px;
}
.profile .subtitle {
    font-size: 18px;
    font-weight: 500;
    margin: 10px 0px;
    display: flex;
    align-items: flex-end;
}
.profile .description {
    text-align: center;
    margin-bottom: 15px;
    font-size: 14px;
    color: white;
}
.profile .info {
    color: #AAA;
    font-weight: 400;
    font-size: 14px;
}
a.info {
	text-decoration: none;
}
a.info:visited {
	color: #AAA;
}
a.info:hover {
    color: #4c77ff;
}

.actions {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.btn-order {
    background-color: white;
    color: #222;
    font-weight: 500;
    padding: 20px;
    border-radius: 3px;
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.3);
    margin: 0px 10px;
    cursor: pointer;
    user-select: none;
    transition: all 0.1s linear;
    text-align: center;
    border: none;
}
.btn-order:not(.disabled):hover {
    background-color: #222;
    color: white;
}

.btn-order i {
    margin: 0px 10px;
    display: inline-block;
    transform: scale(1.5);
}
.btn-order.disabled{
    cursor: initial;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.01);
    border: 1px solid whitesmoke;
    color: #DDD;
}

/*Horarios */
.schedule {
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0,0.05);
    border-radius: 10px;
    padding: 15px;
    font-size: 14px;
}
.schedule > div {
    display: flex;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #DDD;
}
.schedule > div:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.schedule .day {
    flex: 1 1 auto;
    font-weight: 700;
}
.closed {
    font-weight: 400 !important;
    color: #AAA !important;
}
.today {
    color: #4c77ff !important;
    font-weight: 700 !important;
}
.closed.today {
	color: #ff4c79 !important;
}

.transaction-message {
    font-weight: 700;
    text-align: center;
    color: #4c77ff;
    margin-top: 20px;
}
.small {
    font-weight: normal;
    font-size: 12px;
}

.confirmation-summary {
	margin-top: 30px;
    font-size: 14px;
    flex-shrink: 0;
}
.confirmation-summary span.bold {
	font-weight: 500;
}
.confirmation-summary i {
	color: #4c77ff;
	margin-right: 25px;
	transform: scale(2);
}
.confirmation-indication {
	margin-top: 10px;
	margin-left: 38px;
	padding: 7px;
	border: 1px solid #AAA;
	border-radius: 3px;
}
.confirmation-indication::placeholder {
	color: #AAA;
}

.transaction-summary {
    padding: 20px 0px 20px 20px; 
    width: 40%;
    max-width: 40%; 
    flex-shrink: 0;
}
.transaction-summary .items-list {
    overflow-y: auto; 
    padding-right: 20px; 
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.current-address {
    display: flex;
    align-items: center;
    font-size: 13px; 
    margin-top: 5px;
    color: #999;
}
.current-address .icon-x {
    color: black;
}
.item-comment {
    border: 1px solid #CCC;
    margin-top: 5px;
    padding: 4px;
    border-radius: 2px;
    font-size: 12px;
}
.item-comment::placeholder {
    font-style: italic;
}

.bank-data {
    border: 1px solid #DDD;
    padding: 15px;
    margin: 25px 0px;
    width: 95%;
    border-radius: 5px;
    box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
    background-color: rgba(255, 255, 255, 0.25);
}
td {
    border-bottom: 1px solid #DDD;
    padding: 7px 0px;
}
tr:last-child td {
    border-bottom: none;
}
.bank-data tr td:first-child{
    width: 100px;
    font-weight: 700;
}

.transaction-summary-content {
    flex: 1 1 auto;
}

.summary-suggested-items {
    .btn {
        margin-top: 10px;
    }
}

.spin {
    animation: anim-spin 1s linear 0s infinite;
    color: #DDD;
}
@keyframes anim-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.openDown {
    animation: anim-open-down 1000ms linear;
}
@keyframes anim-open-down {
    0% {
        max-height: 0px;

    }
    100% {
        max-height: 1000px;
    }
}

.closeUp {
    animation: anim-close-up 1000ms linear;
}
@keyframes anim-close-up {
    0% {
        max-height: 1000px;

    }
    100% {
        max-height: 0px;
    }
}

.itemUpdate {
    animation: anim-item-update 200ms cubic-bezier(0.250, 0.250, 0.505, 1.455);
}
@keyframes anim-item-update {
    0% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(1);
    }
}

.itemRemove {
    animation: anim-item-remove 500ms ease-out;
}
@keyframes anim-item-remove {
    0% {
        transform: translateX(0px);
        max-height: 300px;
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    50% {
        transform: translateX(500px);
        max-height: 300px;
    }
    100% {
        transform: translateX(500px);
        max-height: 0px;
    }
}

/*mobile*/
.top-bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    z-index: 1;
    display: flex;
    font-size: 20px;
    padding: 16px;
}
.top-bar i {
    font-size: 24px;
    margin-right: 16px;
}
.top-bar .info {
    font-size: 11px;
    margin-top: -3px;
    color: #AAA;
}

.dark-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 2;
}

.mobile-actions {
    position: sticky;
    left: 0;
    bottom: 0; 
    right: 0;
    padding-bottom: 10px;
    z-index: 1;
}

.transaction-confirmation {
    padding: 20px;
    overflow-y: auto;
}

.coupon {
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}
.coupon .title {
    font-weight: 600;
    margin-bottom: 5px
}
.coupon.active .title {
    color: #4c77ff;
}
.coupon.inactive .title {
    color: #ff4c79;
}
.coupon .description {
    font-size: 12px;
    color: #888;
}

.unround {
    border-radius: 0px !important;
}

.transaction-actions .btn {
    margin-right: 10px;
}
.transaction-actions .btn:last-child {
    margin-right: 0px;
}


.btn.small {
    padding: 7px 10px;
    font-size: 12px;
    width: auto;
}

ul {
    list-style-type: none;
    padding-left: 0px;
    li::before {
        content: "w";
        font-family: 'influye-store';
        font-size: 8px;
        margin-right: 4px;
        opacity: 0.7;
    }
    ul {
        padding-left: 12px;
        font-size: 80%;
        li::before {
            content: "f";
        }
    }
}

.tag {
    font-weight: bold; 
    font-size: 12px; 
    padding: 5px 10px; 
    box-shadow: none !important; 
    display: inline-block; 
    transform: translateY(-2px);
}
.line-through {
    text-decoration: line-through;
}

.sticky-bottom {
    position: sticky;
    bottom: 0px;
}

.old-price {
    font-size: 12px;
}

@media (max-width: 767px) {
    /*
    .main-container {
        padding-top: 56px;
    }
    */
    .center-content {
        margin-left: 0;
        margin-right: 0;
    }
    .left-menu {
        width: 250px;
        z-index: 3;
    }
    .left-menu .btn-nav {
        font-size: 16px;
    }
    .btn-add-order {
        width: 100%;
        text-align: center;
        font-size: 12px;
    }
    .menu-items-container {
        padding: 10px;
    }
    .menu-section {
        padding-left: 10px;
    }
    .menu-item .description {
        margin-top: 5px;
    }
    .menu-item {
        border-radius: 0px;
        position: relative;
    }
    .menu-item .info {
        min-height: 120px;
        padding-left: 0px;
    }
    .menu-item img {
        border-radius: 10px;
        margin: 10px;
    }

    .item-image {
        width: 100px;
	    height: 100px;
        min-width: 100px;
	    min-height: 100px;
    }

    .modifiers-actions {
        position: initial;
    }
    /*
    .menu-item .item-modifiers {
        padding: 10px;
    }
    .modifiers-group {
        font-size: 16px !important;
    }
    .modifiers-group .modifier {
        font-size: 18px;
    }
    .modifier .price {
        font-size: 18px;
    }
    */

    .right-menu {
        width: 100%;
        padding-top: 76px;
    }
    .modal-transaction {
        flex-direction: column;
    }
    .transaction-summary {
        width: 100%;
        max-width: 100%;
        /* margin-top: 50px; */
        /*padding-bottom: 70px;*/
    }
    /*
    .transaction-confirmation {
        margin-top: 70px;
    }
    */
    .transaction-actions {
        flex-direction: column;
        margin-top: 20px;
        /*position: absolute;
        right: 0px;
        left: 0px;
        bottom: 0;
        padding: 20px;
        */
    }
    .transaction-actions > * {
        margin-top: 10px;
    }
    .btn-checkout {
        font-size: 16px;
    }
    .quantity {
        font-size: 20px;
    }
    .quantity div {
        width: 30px;
    }
    .icon-trash {
        font-size: 26px;
        padding-top: 5px;
    }
    .options div {
        font-size: 18px;
    }
    .textbox select, .textbox input {
        font-size: 18px;
    }
    select, option {
        background: white;
    }
    .current-address {
        font-size: 18px;
    }
    .transaction-actions .btn {
        margin-left: 0px;
        margin-right: 0px;
    }
    .hide-mobile {
        display: none;
    }
    span.unavailable {
        position: absolute;
        left: 5px;
        top: 50px;
        transform: rotate(-45deg);
        color: black;
    }
    .menu-item.menu-item.unavailable img {
        opacity: 0.4;
    }

    .schedule {
        margin-bottom: 130px;
    }

    .modal-order img {
        max-height: 25vh;
    }

    .old-price {
        font-size: inherit;
    }

    /*
    .transaction-summary-content {
        margin-bottom: 170px;
    }

    
    .bank-data {
        margin: 10px 0px;
        padding: 5px 15px;
    }
    .transaction-message {
        margin-top: 5px;
    }
    
    .transaction-status {
        
        padding-top: 50px;
    }
    */

    .summary-suggested-items {
        display: flex;
        overflow-x: auto;
        min-height: 90px;
        margin-left: -20px;
        margin-right: -20px;
        padding-bottom: 10px;
        padding-right: 20px;

        .btn {
            width: 80vw;
            flex-shrink: 0;
            margin-left: 20px;
        }

    }
    

}

.c-banner-actions {
    padding: 5px 15px;
    position: absolute;
    bottom: calc(100% - 140px);
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    z-index: 1;
}
.desktop {
    .c-banner-actions {
        bottom: calc(100% - 190px);
    }
}

#search-bar {
    position: sticky;
    top: 10px;
    z-index: 2;
}

.SUBITEM {
    margin-top: 8px;
    font-weight: bold;
}
.GROUP {
    opacity: 0.5;
    font-size: 75%;
    margin-top: 4px;
}

.MODIFIER::before {
    font-family: 'influye-store';
    content: 'w';
    
    transform: scale(0.4);
    display: inline-block;
}
.COMMENT {
    font-style: italic;
}
.COMMENT::before {
    content: '"';
}
.COMMENT::after {
    content: '"';
}

.thumbnail-wrapper .thumbnail .thumbnail-image .object-fit {
    height: 200px !important;
}

.unavailable{
    text-decoration: line-through;
}

.expand-modifier:first-child {
    display: none;
}
.expand-modifier:last-child {
    display: block;
}

.VIRTUAL_MENU {
    .center-content {
        margin-right: 0px;
    }
    .hide-virtual-menu {
        display: none;
    }
}

.bar-icon {
    transform: scale(1); 
    margin: 0; 
    left: 12px; 
    top: 12px; 
    font-size: 20px; 
    pointer-events: none;
}

.input-address {
    flex-shrink: 0;
    width: 100%;
    padding: 15px;
    padding-left: 40px;
}

.banner-franchise{
    grid-area: 2 / 1 / 3 / 4;
}

.navbar{
    display: block;
    background-image: none;
}

.head-franchise-container{
    display: block;
    min-height: 100px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
}

.head-franchise-content{
    display: flex;
    justify-content: space-between; /* Alinea los elementos a los lados */
    align-items: center; /* Centra verticalmente los elementos */
    overflow-wrap: anywhere;
}

.headbar-content{
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 0;
}

.container {
    display: grid;
    // position: relative;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    // height: 100vh;
    gap: 30px;
    align-items: start;
    padding-bottom: 20px;
    // align-items: center;
}

.card {
    padding: 30px;
    // min-height: 600px;
    height: auto;
    width: 100%;
    align-content: space-evenly;
    flex-wrap: wrap;
}

.card-img {
  height: 200px;
  width: 100%;
}

.card-img img {
  height: 100%;
  width: 100%;
}

.card-icons {
  display: flex;
//   flex-direction: column;
  align-items: center;
  gap: 30px;
  justify-content: center;
}

.card-icons i {
    font-size: 20px;
}

.card-schedule {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
}


.button-call {
    display: flex;
    justify-content: center;
    align-items: center;
}

.c-menu-section-entry {
    padding: 10px;
}

</style>